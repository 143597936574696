import { FC, Fragment } from 'react';
import styles from './styles.module.css';
import GridView from './grid-view';
import GalleryView from './gallery-view';
import SpotlightView from './spotlight-view';
import SharingScreen from './sharing-screen';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../index';
import RaiseHandNotification from './raise-hand-notification';
// import room from '../../../../../utility/room';
import _ from 'lodash';

const Meeting: FC = () => {
    const { screen_sharing_user_id, isBreakoutRoom, spotlighted } = useSelector(({ meeting }: RootState) => {
        return {
            isBreakoutRoom: !!meeting.breakout_room_id,
            spotlighted: meeting.spotlighted_participants,
            screen_sharing_user_id: meeting.screen_sharing,
        };
    }, _.isEqual);

    // useEffect(() => {
    //     const interval = setInterval(room.addFakeParticipant, 1000);
    //     return () => clearInterval(interval)
    // }, [])

    if (screen_sharing_user_id) {
        return (
            <div className={`${styles.meeting_room} ${styles.screen_sharing}`}>
                <GalleryView total={4} isScreenSharing />
                <SharingScreen userId={screen_sharing_user_id} />
                <RaiseHandNotification />
            </div>
        )
    } else if (!isBreakoutRoom && spotlighted.length) {
        return (
            <div className={`${styles.meeting_room} ${styles[`room_${spotlighted.length}`]}`}>
                <SpotlightView participants={spotlighted} total={spotlighted.length} />
                <GalleryView total={spotlighted.length} />
                <RaiseHandNotification />
            </div>
        )
    } else {
        return (
            <Fragment>
                <GridView />
                {!isBreakoutRoom && <RaiseHandNotification />}
            </Fragment>
        )
    }
}

export default Meeting;