import { FC, useEffect } from 'react';
import styles from './styles.module.css';
import ControlButton from '../../meeting-rooms/controls/control-button';
import Settings from '../../meeting-rooms/controls/menu-settings';
import MenuButton from '../../meeting-rooms/controls/menu-button';
import usePermission from "../../../../../hooks/use-permission";
import useGetUserDevices from "../../../../../hooks/use-get-user-devices";
import useLocalVideoTrack from "../../../../../hooks/use-local-video-track";

interface Props {
    setError: (data: any) => void
    setTrack: (track?: MediaStreamTrack) => void
}

const VideoControl: FC<Props> = ({ setError, setTrack }) => {
    const [state] = usePermission("camera");
    const [devices, selected, handleChange] = useGetUserDevices("videoinput", state);
    const [track, handleTrack] = useLocalVideoTrack(state, selected);

    useEffect(() => { setTrack(track) }, [setTrack, track]);

    useEffect(() => {
        setError((prev: any) => {
            if (state === "prompt") return { ...prev, camera: "Waiting for camera permission" }
            if (state === "denied") return { ...prev, camera: "Please allow access to your camera" }
            return { ...prev, camera: null }
        });
    }, [setError, state]);

    if (devices.length) {
        return (
            <MenuButton onClick={handleTrack} icon={track ? "video" : "video-mute"}>
                <Settings {...{ title: "Select a Camera", devices, selected, handleChange }} />
            </MenuButton>
        )
    } else {
        return <ControlButton className={styles.disabled_button} disabled={true} icon="video-mute" />
    }
}

export default VideoControl;