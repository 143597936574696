import { FC, memo } from 'react';
import styles from './styles.module.css';
import VideoControl from "./video";
import AudioControl from "./audio";
import { useSpring, animated } from 'react-spring';
import { useSelector, } from 'react-redux';
import { RootState } from '../../../../../../index';
import EndCall from '../../meeting-rooms/controls/end-call';
import SpotlightControl from "../../meeting-rooms/controls/spotlight-control";
import _ from "lodash";

interface Props {
    setError: (data: any) => void
    setVideoTrack: (track?: MediaStreamTrack) => void
    updateUserScore: (rate: number) => void
}

const Controls: FC<Props> = ({ setError, setVideoTrack }) => {
    const { role, is_started } = useSelector(({ meeting }: RootState) => {
        return { role: meeting.role, is_started: meeting.is_started }
    }, _.isEqual);

    const props = useSpring({
        delay: 1000,
        from: { transform: "translateY(150%)" },
        to: [{ transform: "translateY(0%)" }, { transform: 'none' }],
    });

    return (
        <animated.div style={{ ...props, marginLeft: (role === "admin" ? "-140px" : "-107.5px") }} className={styles.controls}>
            <VideoControl setError={setError} setTrack={setVideoTrack} />
            <AudioControl setError={setError} />
            {role === "admin" && <SpotlightControl />}
            <EndCall type={role} isStarted={is_started} />
        </animated.div>
    )
}

export default memo(Controls);