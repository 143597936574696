import { FC, memo, useEffect } from 'react';
import styles from './styles.module.css';
import Participant from '../participant';
import { useTransition } from 'react-spring';
import room from '../../../../../../utility/room';

interface Props {
    total: number
    participants: string[]
}

const SpotlightView: FC<Props> = ({ participants, total }) => {

    useEffect(() => {
        participants.forEach((userId) => room.subscribeVideo(userId, { video: true }))
        return () => {
            participants.forEach((userId) => room.unsubscribeVideo(userId, { video: false }))
        }
    }, [participants])

    const transitions = useTransition(participants, {
        from: { scale: 0 },
        enter: { scale: 1 },
        initial: { scale: 1 },
        keys: (userId) => userId,
    });

    return (
        <div className={`${styles.spotlight_view} ${styles[`spotlight_view_${total}`]}`}>
            {console.log("SpotlightView")}
            {transitions((style, userId) => <Participant key={userId} userId={userId} style={style} />)}
        </div>
    )
}

export default memo(SpotlightView);