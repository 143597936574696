import { FC } from 'react';
import styles from './styles.module.css';
import montage from '../../../../images/montage.jpg';
import SharedStudiosLogo from '../../../../images/shared-studios-logo.svg';

const Header: FC = () => {
    return (
        <div className={styles.header}>
            <img className={styles.bg} alt="" src={montage} />
            <img className={styles.logo} src={SharedStudiosLogo} alt="" />
        </div>
    )
}

export default Header;