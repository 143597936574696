import { FC, memo } from 'react';
import styles from './styles.module.css';
import MarkDown from 'react-markdown';
import Icon from '../../../../icon';
import Modal from '../../../../modal';
import { domain } from '../../../../../config';
import defaultCoverImage from '../../../../../images/default-coverpage.jpg';
import ImageWithFallback from '../../../../image-with-fallback/ImageWithFallback';

interface Props {
    title: string
    description: string
    cover_image: string
    onClose: () => void
}

const EventOverview: FC<Props> = ({ onClose, title, description, cover_image }) => {
    console.log("cover_image: ",cover_image)
    return (
        <Modal className={styles.event_overview}>
            <button className={styles.close_button} onClick={onClose}>
                <Icon className={styles.close_icon} icon="close" />
            </button>
            {/* <img className={styles.image} alt="" src={`${domain}/${cover_image}`} /> */}
            <ImageWithFallback fallback={defaultCoverImage} src={`${domain}/${cover_image}`} title={title} className={styles.image} ></ImageWithFallback>
            <p className={styles.header}>{title}</p>
            <MarkDown className={styles.details} linkTarget="_blank">{description}</MarkDown>
        </Modal>
    )
}

export default memo(EventOverview);