import { FC, memo } from 'react';
import styles from './styles.module.css'

type Props = JSX.IntrinsicElements["input"] & {
    label?: string,
    error?: string
}

const TextInput: FC<Props> = ({ className, label, error, ...rest }) => {
    return (
        <div className={`${className} ${styles.text_input}`}>
            <p className={styles.label}>{label}</p>
            <input className={`${styles.input} ${error && styles.error_input}`} {...rest} />
            {error && <p className={styles.error}>{error}</p>}
        </div>
    );
}

export default memo(TextInput);