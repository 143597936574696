import { useCallback, useState } from 'react';
import { Auth } from 'aws-amplify';
import { RootState } from "../../../../../index";
import { useSelector, useDispatch } from 'react-redux';
import { SET_PASSWORD } from '../../../../redux/reducers/user-reducer/types';
import _ from "lodash";

const useValidation = (onClose: (form: string) => void) => {
    const dispatch = useDispatch();
    const [errors, setErrors] = useState<any>({});
    const [isSubmitting, setSubmitting] = useState(false);
    const user = useSelector(({ user }: RootState) => user, _.isEqual);
    const [values, setValues] = useState({ password: "", confirm_password: "" });

    const handleChange = useCallback(({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
        setValues((prevState) => ({ ...prevState, [name]: value }));
        if (errors[name]) {
            setErrors((prevState: any) => {
                delete prevState[name];
                return prevState;
            });
        } else if (errors.message) {
            setErrors((prevState: any) => {
                delete prevState.message;
                return prevState;
            });
        }
    }, [errors])

    const validate = () => {
        const errors = {} as any
        if (!values.password) errors.password = "New password is required."
        if (!values.confirm_password) errors.confirm_password = "Confirm New password is required."
        if (values.password !== values.confirm_password) errors.confirm_password = "Must match New Password."
        return errors;
    }

    const handleSubmit = () => {
        if (user.email) {
            setErrors({});
            setSubmitting(true);
            const errors = validate();
            if (Object.values(errors).length) {
                setErrors(errors);
                setSubmitting(false);
            } else {
                Auth.signUp({
                    username: user.email,
                    password: values.password,
                    attributes: {
                        email: user.email,
                        given_name: user.first_name,
                        family_name: user.last_name,
                        name: `${user.first_name} ${user.last_name}`
                    }
                }).then((res) => {
                    onClose("verify");
                    console.log(res);
                    dispatch({ type: SET_PASSWORD });
                }).catch((err: any) => {
                    setSubmitting(false);
                    console.log({ ...err, message: err.message });
                    setErrors({ message: err.message || "Sorry an Error Occurred. Please Try Again." });
                });
            }
        }
    }

    return [values, isSubmitting, errors, handleChange, handleSubmit]
}

export default useValidation;