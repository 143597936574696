import { FC } from 'react';
import styles from './styles.module.css';
import { Link } from 'react-router-dom';
import { domain } from '../../../../config';
import SharedStudiosLogo from '../../../../images/shared-studios-logo.svg';

interface Props {
    logo: string
    orgId: string
}

const Header: FC<Props> = ({ logo, orgId }) => {
    return (
        <div className={styles.header}>
            <Link to={`/${orgId}`}>
                <img className={styles.org_logo} alt={orgId} src={`${domain}/${logo}-white`} />
            </Link>
            <div className={styles.separator}></div>
            <img className={styles.logo} src={SharedStudiosLogo} alt="" />
        </div>
    )
}

export default Header;