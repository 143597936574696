import thunk from 'redux-thunk';
import reducer from "../reducers";
import { Dispatch } from 'react';
import logger from 'redux-logger';
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';

const createPromise = () => (next: Dispatch<any>) => (action: any) => {
    if (action.payload instanceof Promise) {
        next({ type: action.type.concat("-PENDING") })
        action.payload.then((payload: any) => {
            next({ type: action.type.concat("-FULFILLED"), payload })
        }).catch((payload: any) => {
            next({ type: action.type.concat("-REJECTED"), payload })
        })
    } else {
        next(action)
    }
}

let middleware;

if (process.env.NODE_ENV === "production") {
    middleware = applyMiddleware(createPromise, thunk);
} else {
    middleware = composeWithDevTools(applyMiddleware(createPromise, thunk, logger));
}
const store = createStore(reducer, middleware);

export default store;