import { FC, Fragment, useEffect, useRef, useState, useCallback } from 'react';
import styles from './styles.module.css';
import Controls from "../controls";
import { useSelector, } from 'react-redux';
import { domain } from '../../../../../config';
import { RootState } from '../../../../../../index';
import _ from "lodash";

const SelfieView: FC = () => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const [isVideoPlaying, setVideoPlaying] = useState(false);
    const [error, setError] = useState({ camera: null, mic: null });
    const [videoTrack, setVideoTrack] = useState<MediaStreamTrack>();
    const profile = useSelector(({ meeting }: RootState) => meeting.profile, _.isEqual);

    useEffect(() => {
        const video = videoRef.current;
        if (video && videoTrack) {
            video.volume = 0;
            video.muted = true;
            video.srcObject = new MediaStream([videoTrack]);
            if (!video.paused) setVideoPlaying(true);
            else video.play().then(() => setVideoPlaying(true));
            return () => setVideoPlaying(false);
        }
    }, [videoTrack, videoRef]);

    const handelVideoTrack = useCallback((track?: MediaStreamTrack) => {
        setVideoTrack(track)
    }, [])

    const handelError = useCallback((data: any) => {
        setError(data)
    }, [])

    return (
        <Fragment>
            <Controls updateUserScore={(score) => {}} setError={handelError} setVideoTrack={handelVideoTrack} />
            <div className={styles.selfie_view} onContextMenu={(e) => e.preventDefault()}>
                {!isVideoPlaying && <img className={styles.avatar} src={`${domain}/${profile}`} alt="" />}
                <video ref={videoRef} className={`${isVideoPlaying ? styles.video : styles.hide}`} controls={false} />
                {(error.camera || error.mic) && <div className={styles.error}>
                    {error.mic && <p>{error.mic}</p>}
                    {error.camera && <p>{error.camera}</p>}
                </div>}
            </div>
        </Fragment>
    )
}

export default SelfieView;