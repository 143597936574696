import { FC, useState } from 'react'
import styles from './styles.module.css'
import Message from './message';
import Icon from '../../../../icon'
const src = "https://tawk.to/chat/60f308f7d6e7610a49abc196/1faqlm1gc";

const TawkTo: FC = () => {
    const [show, toggle] = useState(false);

    return (
        <div className={`${styles.tawk_to} ${show && styles.open}`}>
            <button className={`${styles.button} ${show && styles.open_button}`} onClick={() => toggle(!show)}>
                <Message className={styles.message_icon} />
                <p className={styles.button_text}>Need help?</p>
                {show && <Icon className={styles.arrow_icon} icon="arrow" />}
            </button>
            <div className={styles.iframe_wrapper}>
                <iframe className={styles.iframe} src={src} title="tawk-to" frameBorder="0" />
            </div>
        </div>
    )
}

export default TawkTo;