import { FC, useEffect, useRef } from 'react';
import Message from './message';
import styles from './styles.module.css';
import { Title, Text } from "../../../../modal";
import { RootState } from "../../../../../../index";
import waveHand from "../../../../../images/wave-hand.png";
import { useDispatch, useSelector } from 'react-redux';
import { fetchMessages, resendMessage } from "../../../../../redux/action/message";
import { IMessage } from "../../../../../redux/reducers/messages-reducer/types";
import _ from "lodash";

const Messages: FC = () => {
    const dispatch = useDispatch();
    const lastItem = useRef<HTMLDivElement | null>(null);
    const messageScrollRef = useRef<HTMLDivElement | null>(null);
    const { page, loading, messages } = useSelector(({ messages }: RootState) => {
        return {
            page: messages.page,
            loading: messages.loading,
            messages: messages.messages,
        }
    }, _.isEqual);

    useEffect(() => {
        dispatch(fetchMessages())
    }, [dispatch]);

    useEffect(() => {
        if (lastItem.current && page && !loading) {
            // console.log("lastItem.current:", lastItem.current)
            const observer = new IntersectionObserver((entries) => {
                // console.log("lastItem.current:", entries[0].isIntersecting, page)
                if (entries[0].isIntersecting) {
                    dispatch(fetchMessages(page))
                }
            });
            observer.observe(lastItem.current);
            return () => observer.disconnect();
        }
    }, [lastItem, page, loading, dispatch]);

    const handleResendMessage = (message: IMessage) => {
        dispatch(resendMessage(message))
    }

    if (messages.length) {
        return (
            <div className={styles.messages} ref={messageScrollRef}>
                {messages.map((m, i) => {
                    if (m.status === "rejected" && (messages.length - 2) === i) {
                        return <Message key={m.time_stamp} reference={lastItem}  {...m} resendMessage={() => handleResendMessage(m)} />
                    } else if (m.status === "rejected") {
                        return <Message key={m.time_stamp} {...m} resendMessage={() => handleResendMessage(m)} />
                    } else {
                        return <Message key={m.time_stamp}  {...m} />
                    }
                })}
            </div>
        )
    } else {
        return (
            <div className={styles.no_messages}>
                <img className={styles.wave_hand} src={waveHand} alt="celebrate" />
                <Title className={styles.title}>
                    There are no conversations yet.
                </Title>
                <Text className={styles.text}>
                    Start by saying hello to others!
                </Text>
            </div>
        )
    }

}

export default Messages;