import { FC, useEffect } from 'react';
import styles from './styles.module.css';
import ControlButton from '../../meeting-rooms/controls/control-button';
import Settings from '../../meeting-rooms/controls/menu-settings';
import MenuButton from '../../meeting-rooms/controls/menu-button';
import usePermission from "../../../../../hooks/use-permission";
import useGetUserDevices from "../../../../../hooks/use-get-user-devices";
import useLocalAudioTrack from "../../../../../hooks/use-local-audio-track";

interface Props {
    setError: (data: any) => void
    setAudioTrack?: (track?: MediaStreamTrack) => void
}

const AudioControl: FC<Props> = ({ setError }) => {
    const [state] = usePermission("microphone");
    const [devices, selected, handleChange] = useGetUserDevices("audiooutput", state);
    const [track, handleTrack] = useLocalAudioTrack(state, selected);

    useEffect(() => {
        setError((prev: any) => {
            if (state === "prompt") return { ...prev, mic: "Waiting for microphone permission" }
            if (state === "denied") return { ...prev, mic: "Please allow access to your microphone" }
            return { ...prev, mic: null }
        });
    }, [setError, state]);

    if (devices.length) {
        return (
            <MenuButton onClick={handleTrack} icon={track ? "mic" : "mic-mute"}>
                <Settings {...{ title: "Select a Microphone", devices, selected, handleChange }} />
            </MenuButton>
        )
    } else {
        return <ControlButton className={styles.disabled_button} disabled={true} icon="mic-mute" />
    }
}

export default AudioControl;