import { FC, Fragment, useEffect } from 'react';
import Loading from '../loading';
import Amplify, { Hub } from 'aws-amplify';
import { RootState } from '../../../index';
import { aws_exports } from '../../config';
import { fetchProfile } from '../../redux/action/user';
import { useDispatch, useSelector } from 'react-redux';
import { LOG_OUT } from '../../redux/reducers/user-reducer/types';
import _ from "lodash";

const Profile: FC = ({ children }) => {
    const dispatch = useDispatch();
    const loading = useSelector(({ user }: RootState) => user.loading, _.isEqual);

    useEffect(() => {
        Hub.listen('auth', (data) => {
            console.log('auth event', data.payload);
            switch (data.payload.event) {
                case 'configured': {
                    return dispatch(fetchProfile());
                } case 'signIn': {
                    return dispatch(fetchProfile());
                } case 'signOut': {
                    return dispatch({ type: LOG_OUT });
                }
            }
        });
        Amplify.configure(aws_exports);
    }, [dispatch]);

    if (loading) return <Loading />
    else return <Fragment>{children}</Fragment>
}

export default Profile;