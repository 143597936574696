import { FC, HTMLAttributes } from 'react';
import styles from './styles.module.css';
import moment from 'moment';
import Timer from './timer';

interface Props extends HTMLAttributes<HTMLDivElement> {
    date: number
    title: string
}

const CountdownBanner: FC<Props> = ({ className, date, title }) => {
    return (
        <div className={`${styles.countdown} ${className}`}>
            <p className={styles.date}>{date ? moment(date).local().format("LLLL") : "TBD"}</p>
            <p className={styles.event_title}>{title}</p>
            {date > Date.now() && <Timer date={date} />}
        </div>
    )
}

export default CountdownBanner;