import { FC } from "react";
import styles from "./styles.module.css";
import Header from "./header";
import OrgButton from "./org-button";

const LandingPage: FC = () => {
    return (
        <div className={styles.landing_page}>
            <Header />
            <p className={styles.title}>Explore partner organizations</p>
            <div className={styles.buttons}>
                <OrgButton org_id="juniper" org_name="Juniper Networks" />
                <OrgButton org_id="dohadebates" org_name="Doha Debates" />
                <OrgButton org_id="shared_studios" org_name="Shared_Studios" />
            </div>
        </div>
    );
};

export default LandingPage;
