import { FC, useEffect, useState } from 'react';
import styles from './styles.module.css';
import axios from '../../../../axios';
import { useParams } from "react-router-dom";
import ConnectionCard from './connection-card';

export interface Connection {
    date: number
    title: string
    org_id: string
    event_id: string
    cover_image: string
    short_description: string
}

const UpcomingConnections: FC = () => {
    const { org_id } = useParams<any>();
    const [connections, setState] = useState<Connection[]>([]);

    useEffect(() => {
        axios.get(`organizations/events/${org_id}`).then((data: any) => {
            setState(data.events);
        }).catch((error) => {
            console.error(error)
            // setState({ error: error.message, loading: false })
        });
    }, [org_id]);

    if (connections.length) {
        return (
            <div className={styles.upcoming_connections}>
                {connections.map((i) => <ConnectionCard key={i.event_id} {...i} />)}
            </div>
        )
    } else {
        return null
    }
}

export default UpcomingConnections;