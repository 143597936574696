import { FetchMessagesRejected } from '../messages-reducer/types';
import { RaisedHandRejected, LowerHandRejected, AdminLoweredHandRejected, SpotlightUserRejected } from '../meeting-reducer/types';
import { CreateBreakoutRoomRejected, DeleteBreakoutRoomRejected, UpdateBreakoutRoomRejected, UpdateEventStatusRejected } from '../meeting-reducer/types';

export const REMOVE_ERROR = "REMOVE-ERROR";
export const CREATE_ERROR = "CREATE-ERROR";


export interface IError {
    key: number
    error: any
}

export interface CreateError {
    type: typeof CREATE_ERROR
    payload: string
}

export type IActions = CreateError | FetchMessagesRejected | UpdateEventStatusRejected | CreateBreakoutRoomRejected | UpdateBreakoutRoomRejected | DeleteBreakoutRoomRejected | RaisedHandRejected | LowerHandRejected | AdminLoweredHandRejected | SpotlightUserRejected