import { FC, Fragment, ReactNode, useEffect } from "react";

interface Props {
    children: ReactNode
    themes: any
}

const Cream: FC<Props> = ({ children, themes }) => {

    useEffect(() => {
        const root = document.documentElement;
        for (const property in themes) {
            root.style.setProperty(property, themes[property])
        }
    }, [themes]);

    return <Fragment>{children}</Fragment>;
}

export default Cream;