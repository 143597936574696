import { FC } from 'react';
import styles from './styles.module.css';
import { domain } from '../../../../config';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    coverImage: string
}

const Banner: FC<Props> = ({ children, coverImage }) => {
    return (
        <div className={styles.banner}>
            {children}
            <img className={styles.image} src={`${domain}/${coverImage}`} alt="" />
        </div>
    )
}

export default Banner;