import { FC, useEffect, useState } from 'react';
import styles from './styles.module.css';
import axios from '../../../axios';
import Loading from '../../loading';
import MemberCard from './member-card';
import { domain } from '../../../config';
import { AxiosError } from 'axios';
import { useParams, useHistory } from "react-router-dom";

export interface IMember {
    user_id: string
    profile: string
    last_name: string
    first_name: string
    email: string
    nationality: string
    location?: string
    website_url: string
    bio: string
    statement: string
}

interface State {
    loading: boolean
    error?: string
    members?: IMember[]
    org?: any,
}

const CommunityPage: FC = () => {
    const history = useHistory();
    const { org_id } = useParams<any>();
    const [{ org, members, loading, error }, setState] = useState<State>({ loading: true });

    useEffect(() => {
        axios.get(`organizations/members/${org_id}`).then((data: any) => {
            setState({ ...data, loading: false })
        }).catch((error: AxiosError) => {
            setState({ error: error.message, loading: false })
        });
    }, [org_id, history]);

    if (loading) {
        return <Loading className={styles.loading} />
    } else if (error) {
        return <Loading className={styles.loading} message={error} stopLoading />
    } else {
        return (
            <div className={styles.community_page}>
                {org.community_banner && <img className={styles.banner} alt="" src={`${domain}/${org.community_banner}`} />}
                <p className={styles.title}>{org.org_name} Community</p>
                <div className={styles.members}>
                    {members && members.map((member, i) => <MemberCard key={i} {...member} />)}
                </div>
            </div>
        )
    }
}

export default CommunityPage;