import { FC, memo, DragEvent, useState, useEffect } from "react";
import styles from './styles.module.css';
import Icon from '../../../../../../../icon';
import Button from '../../../../../../../button';
import Participant, { IBreakoutRoomUser } from '../participant';

export interface IBreakoutRoom {
    name: string
    isMain: boolean
    room_id?: string
    participants: IBreakoutRoomUser[]
}

interface Props {
    indx: number
    room: IBreakoutRoom
    collapseAll: boolean
    currentRoomId?: string
    joinRoom: (newRoomId?: string) => void
    onRoomChange: (userId: string, from: number, to?: number) => void
}


const BreakoutRoom: FC<Props> = ({ room, indx, currentRoomId, collapseAll, joinRoom, onRoomChange }) => {
    const [show, toggle] = useState(true);
    const [active, setActive] = useState(false);

    useEffect(() => toggle(!collapseAll), [collapseAll]);

    const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setActive(true);
    }

    const handleDrop = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setActive(false);
        const { user_id, indx: from } = JSON.parse(e.dataTransfer.getData('text/plain'));
        if (from !== indx) onRoomChange(user_id, from, indx);
    }

    return (
        <div className={`${styles.breakout_room} ${!show && styles.collapsed}`}>
            <p className={styles.title}>{room.name}</p>
            <button className={styles.open_close_button} onClick={() => toggle(!show)}>
                <Icon className={`${styles.icon} ${!show && styles.collapsed_icon}`} icon="arrow" />
            </button>
            {(room.isMain && currentRoomId !== room.room_id) && <Button className={styles.join_button} onClick={() => joinRoom(room.room_id)}>Join</Button>}
            {(room.room_id && currentRoomId !== room.room_id) && <Button className={styles.join_button} onClick={() => joinRoom(room.room_id)}>Join</Button>}
            <div className={`${styles.assigned_participants} ${active && styles.drop_active}`} onDrop={handleDrop} onDragOver={handleDragOver} onDragLeave={() => setActive(false)}>
                {room.participants.map((participant, i) => {
                    if (room.isMain) return <Participant key={i} {...participant} indx={indx} />
                    else return <Participant key={i} {...participant} handleRemove={onRoomChange} indx={indx} />
                })}
            </div>
        </div>
    )
}

export default memo(BreakoutRoom);