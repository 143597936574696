import { FC, useState, useEffect } from 'react';
import styles from './styles.module.css';
import ControlButton from '../control-button';
import Icons from '../../../../../icon';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    icon: string
    loading?: boolean
    onClick?: () => void
}

const ButtonWithMenu: FC<Props> = ({ className, onClick, children, icon, loading }) => {
    const [showMenu, toggleMenu] = useState<boolean>(false);

    useEffect(() => {
        if (showMenu) {
            const hideMenu = () => toggleMenu(false);
            document.addEventListener("click", hideMenu)
            return () => {
                document.removeEventListener("click", hideMenu)
            }
        }
    }, [showMenu, toggleMenu])

    if (onClick) {
        return (
            <div className={`${className} ${styles.menu_button}`}>
                <ControlButton className={`${showMenu && styles.menu_active}`} onClick={onClick} icon={icon} loading={loading} />
                <button className={`${styles.arrow} ${showMenu && styles.active}`} onClick={() => toggleMenu(!showMenu)}>
                    <Icons className={styles.icon} icon="arrow-mini" />
                </button>
                {showMenu && <div className={styles.menu}>
                    <div className={styles.container}>{children}</div>
                </div>}
            </div>
        )
    } else {
        return (
            <div className={`${className} ${styles.menu_button}`}>
                <ControlButton className={`${showMenu && styles.menu_active}`} onClick={() => toggleMenu(!showMenu)} icon={icon} />
                {showMenu && <div className={styles.menu}>
                    <div className={styles.container}>{children}</div>
                </div>}
            </div>
        )
    }
}

export default ButtonWithMenu;