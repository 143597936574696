import { FC, useEffect, useState } from 'react';
import styles from './styles.module.css';
import Panel from './panel';
import Header from './header';
import Cream from '../../cream';
import axios from '../../../axios';
import Loading from '../../loading';
import { Helmet } from "react-helmet";
import { domain } from '../../../config';
import useQuery from '../../../hooks/use-query';
import { useParams, useHistory } from 'react-router-dom';

interface State {
    data?: any
    error?: string
    loading: boolean
}

const AuthenticationPage: FC = () => {
    const history = useHistory();
    const { org_id } = useParams<any>();
    const { type, redirect } = useQuery();
    const [{ data, loading, error }, setState] = useState<State>({ loading: true });

    useEffect(() => {
        axios.get(`organizations/metadata/${org_id}`).then((data: any) => {
            setState({ data, loading: false });
        }).catch((error) => {
            setState({ error: error.message, loading: false });
        })
    }, [history, redirect, org_id]);

    if (loading) {
        return <Loading />
    } else if (error) {
        return <Loading message={error} stopLoading />
    } else {
        return (
            <Cream themes={data.theme}>
                <Helmet>
                    <link rel="icon" href={data.favicon} />
                    <title>{data.tab_title} | Shared_Studios</title>
                </Helmet>
                <div className={styles.authentication}>
                    {data.authentication_bg && <img className={styles.background} alt="" src={`${domain}/${data.authentication_bg}`} />}
                    <Header logo={data.logo} orgId={data.org_id} />
                    <Panel type={type} />
                </div>
            </Cream>
        )
    }
}

export default AuthenticationPage;