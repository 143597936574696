import { useEffect, useState } from 'react';

interface State {
    selected?: string
    devices: MediaDeviceInfo[]
}

type state = PermissionStatus["state"]
type kind = "videoinput" | "audioinput" | "audiooutput"
type IUserDevices = [MediaDeviceInfo[], string | undefined, (deviceId: string) => void]

const useGetUserDevices = (kind: kind, state: state): IUserDevices => {
    const [{ devices, selected }, setState] = useState<State>({ devices: [] });

    useEffect(() => {
        if (state === "granted") {
            const getDevices = () => {
                navigator.mediaDevices.enumerateDevices().then((devices) => {
                    // console.log("devices:", devices)
                    devices = devices.filter((device) => (device.deviceId && device.kind === kind))
                    if (devices.length) {
                        let selected = localStorage.getItem(kind)
                        if (!selected) {
                            selected = devices[0].deviceId;
                            localStorage.setItem(kind, selected);
                        }
                        setState({ selected, devices });
                    }
                });
            }
            getDevices();
            navigator.mediaDevices.addEventListener("devicechange", getDevices);
            return () => {
                setState({ devices: [] });
                navigator.mediaDevices.removeEventListener("devicechange", getDevices);
            }
        }
    }, [kind, state]);

    const handleChange = (deviceId: string) => {
        localStorage.setItem(kind, deviceId);
        setState((state) => ({ ...state, selected: deviceId }));
    }

    return [devices, selected, handleChange]
}

export default useGetUserDevices;