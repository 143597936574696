import { FC } from 'react';
import styles from './styles.module.css';
import TextInput from '../../../../text-input';
import Button from '../../../../button';
import useValidation from './useValidation';

const ResetPasswordForm: FC = () => {
    const [values, isSubmitting, errors, handleChange, handleSubmit] = useValidation();

    return (
        <div className={styles.form}>
            <p className={styles.title}>Change Password</p>
            {errors.message && <div className={styles.error_message}>{errors.message}</div>}
            <TextInput
                label="Code"
                type="number"
                name="code"
                value={values.code}
                error={errors.code}
                onChange={handleChange}
            />
            <TextInput
                label="Email"
                type="email"
                name="email"
                value={values.email}
                error={errors.email}
                onChange={handleChange}
            />
            <TextInput
                label="Password"
                type="password"
                name="password"
                value={values.password}
                error={errors.password}
                onChange={handleChange}
            />
            <Button onClick={handleSubmit} loading={isSubmitting} disabled={errors.email}>Change Password</Button>
        </div>
    )
}

export default ResetPasswordForm;