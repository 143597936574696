import { FC, useState } from 'react';
import styles from './styles.module.css';
import Button from '../../../../../button';
import { RootState } from '../../../../../../../index';
import Modal, { Title, Text } from '../../../../../modal';
import { useDispatch, useSelector, } from 'react-redux';
import { endMeeting } from '../../../../../../redux/action/meeting';
import _ from "lodash";

interface Props {
    close: () => void
}

const EndCallModal: FC<Props> = ({ close }) => {
    const dispatch = useDispatch();
    const [checked, setState] = useState(false);
    const loading = useSelector(({ meeting }: RootState) => meeting.updating_event, _.isEqual);

    return (
        <Modal>
            <Title className={styles.title}>Sure you want to end the event?</Title>
            <Text className={styles.text}>Everyone will be disconnected and this event will be marked a previous event.</Text>
            <br />
            <p style={{ textAlign: "left" }}>
                <input type="checkbox" checked={checked} onChange={() => setState(!checked)} />  Mark as previous event
            </p>
            <div className={styles.modal_buttons}>
                <Button onClick={close} disabled={loading}>Cancel</Button>
                <Button onClick={() => dispatch(endMeeting(checked))} className={styles.end} disabled={loading} loading={loading}>End Event</Button>
            </div>
        </Modal>
    )
}

export default EndCallModal;