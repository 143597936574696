import { FC } from 'react';
import styles from './styles.module.css'
import Spinner from '../loading-animation/spinner';

interface Props {
    message?: string
    className?: string
    stopLoading?: boolean
    children?: JSX.Element
    spinner?: React.FunctionComponent<any>
}

const Loading: FC<Props> = ({ children, className, message, stopLoading, spinner: Other }) => {
    return (
        <div className={`${styles.loading} ${className}`}>
            {!stopLoading ? Other ? <Other /> : <Spinner className={styles.icon} /> : null}
            {message && <p className={styles.message}>{message}</p>}
            {children}
        </div>
    )
}

export default Loading;