import { FC, useState } from 'react';
import styles from './styles.module.css';
import { Auth } from 'aws-amplify';
import Icon from '../../../../icon';
import Button from '../../../../button';
import useValidation from './useValidation';
import TextInput from '../../../../text-input';
import ForgotPasswordForm from '../forgot-password-form';
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";

const SignInForm: FC = () => {
    const [forgotPassword, setForgotPassword] = useState(false);
    const [values, isSubmitting, errors, handleChange, handleSubmit] = useValidation();

    const handleGoogle = () => {
        Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })
    }

    if (forgotPassword) {
        return <ForgotPasswordForm setForgotPassword={setForgotPassword} />
    } else {
        return (
            <div className={styles.form}>
                <p className={styles.title}>Sign in</p>
                <Button className={styles.google} onClick={handleGoogle} >
                    <Icon className={styles.google_icon} icon="google" /> Sign in with Google
                </Button>
                <p className={styles.or}>or</p>
                {errors.message && <div className={styles.error_message}>{errors.message}</div>}
                <TextInput
                    label="Email"
                    type="email"
                    name="email"
                    value={values.email}
                    error={errors.email}
                    onChange={handleChange}
                />
                <TextInput
                    label="Password"
                    type="password"
                    name="password"
                    value={values.password}
                    error={errors.password}
                    onChange={handleChange}
                />
                <button className={styles.forgot_password_button} onClick={() => setForgotPassword(true)}>Forgot password?</button>
                <Button onClick={handleSubmit} loading={isSubmitting}
                    disabled={errors.email || errors.password || isSubmitting}>
                    Sign In
                </Button>
            </div>
        )
    }
}

export default SignInForm;