export default function GoogleSignUp({ className }: any) {
    return (
        <svg className={className} viewBox="0 0 33 32" fill="none">
            <path d="M16.849 14.5293V17.5953H21.6438C20.9638 19.79 18.9183 21.3842 16.5002 21.3842C13.5266 21.3842 11.1159 18.9736 11.1159 15.9999C11.1158 13.0264 13.5263 10.6157 16.4999 10.6157C17.8896 10.6157 19.1522 11.1468 20.1074 12.0111L22.3573 9.60244C20.8139 8.18875 18.7579 7.3255 16.4999 7.3255C11.7092 7.3255 7.82544 11.2091 7.82544 16C7.82544 20.7909 11.7092 24.6745 16.5 24.6745C21.2908 24.6745 25.1745 20.7908 25.1745 16C25.1745 15.4981 25.1282 15.0076 25.0457 14.5293H16.849Z" fill="#4285F4" />
            <path d="M16.5 10.6158C17.8897 10.6158 19.1523 11.1469 20.1075 12.0112L22.3573 9.60256C20.814 8.18875 18.758 7.3255 16.5 7.3255C13.1777 7.3255 10.2931 9.194 8.83594 11.9366L11.4832 14.0567C12.2633 12.0448 14.2126 10.6158 16.5 10.6158Z" fill="#EA4335" />
            <path d="M11.1161 16C11.1161 15.3139 11.2493 14.6602 11.4834 14.0567L8.83612 11.9367C8.19206 13.149 7.82568 14.5315 7.82568 16.0001C7.82568 17.4754 8.19512 18.8641 8.84475 20.0804L11.4763 17.9239C11.2471 17.3256 11.1161 16.6788 11.1161 16Z" fill="#FBBC05" />
            <path d="M19.4934 20.4717C18.637 21.0462 17.6089 21.3842 16.5002 21.3842C14.2052 21.3842 12.251 19.946 11.476 17.9238L8.84448 20.0803C10.3046 22.8137 13.1845 24.6744 16.4999 24.6744C18.6531 24.6744 20.6214 23.8882 22.1379 22.5895L19.4934 20.4717Z" fill="#34A853" />
            <path d="M25.1746 16C25.1746 15.4981 25.1283 15.0076 25.0459 14.5293H16.8491V17.5953H21.6439C21.2757 18.7838 20.5049 19.7931 19.4936 20.4717L22.1381 22.5896C23.9959 20.9986 25.1746 18.6377 25.1746 16Z" fill="#4285F4" />
        </svg>
    )
}