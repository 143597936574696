import { FC, useEffect, useState } from 'react';
import styles from './styles.module.css';
import Hero from './hero';
import AltHero from './alt-hero';
import axios from '../../../axios';
import Loading from '../../loading';
import { useParams } from "react-router-dom";
import VideoHighlight from './video-highlight';
import { Authenticate } from '../redirect-page';
import UpcomingConnections from './upcoming-connections';
import PreviousConnections from './previous-connections';

interface State {
    org?: any
    error?: string
    loading: boolean
}

const DiscoveryPage: FC = () => {
    const { org_id } = useParams<any>();
    const [{ org, loading, error }, setState] = useState<State>({ loading: true });

    useEffect(() => {
        axios.get(`organizations/${org_id}`).then((org: any) => {
            setState({ org, loading: false })
        }).catch((error) => {
            setState({ error: error.message, loading: false })
        });
    }, [org_id]);

    if (loading) {
        return <Loading className={styles.loading} />
    } else if (error) {
        return <Loading className={styles.loading} message={error} stopLoading />
    } else if (org.required_authentication) {
        return <Authenticate />
    } else {
        return (
            <div className={styles.discovery_page}>
                {org.hero.type === "juniper" ? <Hero {...org.hero} /> : <AltHero {...org.hero} />}
                <UpcomingConnections />
                <VideoHighlight {...org.video_highlight} />
                <PreviousConnections />
            </div>
        )
    }
}

export default DiscoveryPage;