import { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import useQuery from '../../../../../hooks/use-query';

const useValidation = () => {
    const history = useHistory();
    const { email } = useQuery();
    const [errors, setErrors] = useState<any>({});
    const [isSubmitting, setSubmitting] = useState(false);
    const [values, setValues] = useState({ code: "" });

    const handleChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
        setValues((prevState) => ({ ...prevState, [name]: value }));
        if (errors[name]) {
            setErrors((prevState: any) => {
                delete prevState[name];
                return prevState;
            });
        }
    }

    const validate = () => {
        const errors = {} as any
        if (!values.code) errors.code = "Please enter the verification code."
        return errors;
    }

    const handleSubmit = () => {
        setErrors({});
        setSubmitting(true);
        const errors = validate();
        if (Object.values(errors).length) {
            setErrors(errors);
            setSubmitting(false);
        } else {
            console.log(email)
            Auth.confirmSignUp(email, values.code).then(() => {
                history.push({ search: 'type=signin' });
            }).catch((err: any) => {
                setSubmitting(false);
                console.log(JSON.stringify(err));
                setErrors({ message: err.message || "Sorry an Error Occurred. Please Try Again." });
            });
        }
    }

    const handelResendCode = () => {
        Auth.resendSignUp(email).catch((err: any) => {
            console.log(JSON.stringify(err));
            setErrors({ message: err.message || "Sorry an Error Occurred. Please Try Again." });
        });
    }

    return [values, isSubmitting, errors, handleChange, handleSubmit, handelResendCode]
}

export default useValidation;