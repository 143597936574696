import EventEmitter from 'events';
import jwt from 'jsonwebtoken';

interface EventToken extends EventEmitter {
    invalidateToken(): void
    isValidToken(): string | null
    on(event: "token-update", listener: () => void): this
    setToken(token: string, eventId: string): Promise<string>
    removeListener(event: "token-update", listener: () => void): this
}

class EventToken extends EventEmitter implements EventToken {

    private getToken(): string | null {
        const eventId = window.location.pathname.split("/")[2];
        return localStorage.getItem(`event-${eventId}`);
    }

    private removeToken(): void {
        const eventId = window.location.pathname.split("/")[2];
        localStorage.removeItem(`event-${eventId}`);
        this.emit("token-update");
    }

    public invalidateToken(): void {
        this.removeToken();
    }

    public isValidToken(): string | null {
        const token = this.getToken();
        if (!token) {
            console.log("token not found");
            return null;
        }
        const decode = jwt.decode(token, { json: true });
        if (!decode) {
            console.log("invalid token");
            return null;
        }
        if (!decode.exp) {
            console.log("token is invalid");
            return null;
        }
        if (Date.now() >= decode.exp * 1000) {
            console.log("token has expired");
            this.removeToken();
            return null;
        }
        return token;
    }

    public setToken(token: string, eventId: string): Promise<string> {
        return new Promise((resolve) => {
            localStorage.setItem(`event-${eventId}`, token);
            this.emit("token-update");
            resolve(token);
        });
    }
}

export default new EventToken();