import { FC, useState } from 'react';
import styles from './styles.module.css';
import Button from '../../button';
import { Auth } from 'aws-amplify';
import axios from "../../../axios";
import TextInput from '../../text-input';
import { RootState } from "../../../../index";
import { useHistory } from 'react-router-dom';
import SetPasswordForm from './set-password-form';
import ChangePasswordForm from './change-password-form';
import VerifyAccountForm from './verify-account-form';
import { useSelector, useDispatch } from 'react-redux';
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import { REMOVED_ACCOUNT } from '../../../redux/reducers/user-reducer/types';
import _ from "lodash";

const AccountPage: FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [form, setForm] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const { email, verified, password, google } = useSelector(({ user }: RootState) => {
        return {
            email: user.email,
            google: user.google,
            password: user.password,
            verified: user.password_verified
        }
    }, _.isEqual);

    const handleConnectGoogle = () => {
        localStorage.setItem('redirect', history.location.pathname);
        Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })
    }

    const handleRemoveGoogle = () => {
        axios.post('/users/unlink-social-account').then(() => {
            dispatch({ type: REMOVED_ACCOUNT });
        }).catch((error: any) => {
            setError(error.message);
        })
    }

    return (
        <div className={styles.account_page}>
            <p className={styles.title}>Account Settings</p>
            {error && <div className={styles.error_message}>{error}</div>}
            <TextInput label="Email" type="text" name="email" value={email} disabled={true} />
            <p>Password</p>
            {!password && <Button onClick={() => setForm("set")}>Set a password</Button>}
            {(password && !verified) && <Button onClick={() => setForm("verify")}>Verify account</Button>}
            {(password && verified) && <Button onClick={() => setForm("change")}>Change password</Button>}
            <p>Google account</p>
            {!google && <Button onClick={handleConnectGoogle}>Connect google account</Button>}
            {google && <Button onClick={handleRemoveGoogle} disabled={!password}>Remove google account</Button>}
            {form === "change" && <ChangePasswordForm onClose={() => setForm(null)} />}
            {form === "verify" && <VerifyAccountForm onClose={() => setForm(null)} />}
            {form === "set" && <SetPasswordForm onClose={(form) => setForm(form || null)} />}
        </div>
    )
}

export default AccountPage;