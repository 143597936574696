import { meeting } from '../../axios'
import { Dispatch } from 'redux';
import { DispatchFetchMessages, DispatchSendMessage, IMessage } from '../reducers/messages-reducer/types';
import { FETCH_MESSAGES, RESEND_MESSAGE_PENDING, SEND_MESSAGE_FULFILLED, SEND_MESSAGE_REJECTED, SEND_MESSAGE_PENDING } from '../reducers/messages-reducer/types';

export const fetchMessages = (page?: string) => {
    return (dispatch: Dispatch<DispatchFetchMessages>) => {
        dispatch({ type: FETCH_MESSAGES, payload: meeting.get(`${page ? `messages?page=${page}` : 'messages'}`) });
    }
}

export const resendMessage = (message: IMessage) => {
    return (dispatch: Dispatch<DispatchSendMessage>) => {
        const data = { message: message.message, time_stamp: message.time_stamp }
        dispatch({ type: RESEND_MESSAGE_PENDING, payload: message })
        meeting.put('messages', data).then((data: any) => {
            dispatch({ type: SEND_MESSAGE_FULFILLED, payload: data })
        }).catch(() => {
            dispatch({ type: SEND_MESSAGE_REJECTED, payload: message.time_stamp })
        })
    }
}

export const sendMessage = (message: IMessage) => {
    return (dispatch: Dispatch<DispatchSendMessage>) => {
        const data = { message: message.message, time_stamp: message.time_stamp }
        dispatch({ type: SEND_MESSAGE_PENDING, payload: message })
        meeting.put('messages', data).then((data: any) => {
            dispatch({ type: SEND_MESSAGE_FULFILLED, payload: data })
        }).catch(() => {
            dispatch({ type: SEND_MESSAGE_REJECTED, payload: message.time_stamp })
        })
    }
}