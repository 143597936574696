import { useEffect, useState, Fragment } from 'react';
import Modal, { Text, Title } from '../modal';
import styles from './styles.module.css';
import Ripple from '../loading-animation/ripple';

const NetworkStatus = ({ children }: any) => {
    const [online, setStates] = useState(navigator.onLine)

    useEffect(() => {
        const updateNetworkStatue = () => {
            setStates(navigator.onLine)
        }
        window.addEventListener('online', updateNetworkStatue);
        window.addEventListener('offline', updateNetworkStatue);
        return () => {
            window.removeEventListener('online', updateNetworkStatue);
            window.removeEventListener('offline', updateNetworkStatue);
        }
    }, []);

    return (
        <Fragment>
            {!online && <Modal>
                <Title className={styles.title}>Having trouble connecting!</Title>
                <Text className={styles.text}>Please check your internet connection.</Text>
                <Ripple />
            </Modal>}
            {children}
        </Fragment>
    )
}

export default NetworkStatus;
