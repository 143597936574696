import { FC, memo } from "react";
import styles from "./styles.module.css";
import Avatar from "./avatar";
import Button from "../../../button";
import { domain } from "../../../../config";
import { RootState } from "../../../../../index";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import SharedStudiosLogo from "../../../../images/shared-studios-logo.svg";
import _ from "lodash";
import { useRedirect } from "../../../pages/redirect-page";

interface Props {
    logo: string;
    orgId: string;
}

const Header: FC<Props> = ({ logo, orgId }) => {
    const redirect = useRedirect();
    const { logged_in } = useSelector(
        ({ user }: RootState) => ({ logged_in: user.logged_in }),
        _.isEqual
    );
    const enableSharedStudiosLogo: boolean = orgId !== "shared_studios";

    return (
        <div className={styles.header}>
            <Link to={`/${orgId}`}>
                <img
                    className={styles.logo}
                    alt={orgId}
                    src={`${domain}/${logo}`}
                />
            </Link>
            <div
                className={enableSharedStudiosLogo ? styles.separator : ""}
            ></div>
            <img
                className={styles.logo}
                src={enableSharedStudiosLogo ? SharedStudiosLogo : ""}
                alt=""
            />
            {logged_in ? (
                <Avatar />
            ) : (
                <Button
                    className={styles.header_button}
                    onClick={() => redirect.signIn()}
                >
                    Sign In
                </Button>
            )}
        </div>
    );
};

export default memo(Header);
