import { combineReducers } from "redux";

import userReducer from './user-reducer';
import errorReducer from './error-reducer';
import meetingReducer from './meeting-reducer';
import messagesReducer from './messages-reducer';
import RaisedHandsReducer from './raised-hands-reducer';

const rootReducer = combineReducers({
    user: userReducer,
    error: errorReducer,
    meeting: meetingReducer,
    messages: messagesReducer,
    raised_hands: RaisedHandsReducer,
});

export default rootReducer;