import { FC, memo } from 'react';
import styles from './styles.module.css';
import Modal from '../../../../modal';
import Icon from '../../../../icon';

interface Props {
    learnMore: string
    onClose: () => void
}

const LearnMore: FC<Props> = ({ learnMore, onClose }) => {
    return (
        <Modal className={styles.learn_more}>
            <p className={styles.header}>Learn More</p>
            <p className={styles.details}>{learnMore}</p>
            <button className={styles.close_button} onClick={onClose}>
                <Icon className={styles.close_icon} icon="close" />
            </button>
        </Modal>
    )
}

export default memo(LearnMore);