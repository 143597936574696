import { FC, ReactNode } from "react"
import styles from './styles.module.css';
import Icon from '../../../../../icon';

interface Props {
    title: string
    className?: string
    children: ReactNode
    onClose: () => void
}

const SidebarLayout: FC<Props> = ({ className, children, title, onClose }) => {
    return (
        <div className={`${styles.sidebar_layout} ${className}`}>
            <button className={styles.close_button} onClick={onClose}>
                <Icon className={styles.close_icon} icon="close" />
            </button>
            <p className={styles.title}>{title}</p>
            <div className={styles.contents}>
                {children}
            </div>
        </div>
    )
}

export default SidebarLayout;