import axios, { AxiosError, AxiosResponse } from 'axios';
import { Auth } from 'aws-amplify';
import { endpoint } from '../config';
import EventToken from '../utility/event-token';

const response = (response: AxiosResponse<any>) => {
    return response.data
}

const error = (error: AxiosError<any>) => {
    console.log("error:", error)
    if (error.response?.status === 404) {
        return Promise.reject({ message: "404 Not Found!" });
    } if (error?.response?.data) {
        return Promise.reject(error.response.data);
    } else if (error.message) {
        return Promise.reject(error);
    } else {
        return Promise.reject({ message: "Oops! Something went wrong. Please Try Again." });
    }
}

const instance = axios.create({ baseURL: endpoint });

instance.interceptors.response.use(response, error);

instance.interceptors.request.use((config) => {
    return new Promise((resolve) => {
        Auth.currentSession().then((value) => {
            const idToken = value.getIdToken().getJwtToken();
            config.headers.Authorization = idToken;
            resolve(config);
        }).catch(() => {
            resolve(config)
        });
    })
});


export const meeting = axios.create({ baseURL: endpoint });

meeting.interceptors.response.use(response, error);

meeting.interceptors.request.use((config) => {
    return new Promise((resolve, reject) => {
        const token = EventToken.isValidToken();
        if (token) {
            config.headers.Authorization = token;
            resolve(config);
        } else {
            reject({ message: "session expired" });
        }
    })
});

export default instance;
