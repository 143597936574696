import { FC, useState, useEffect } from 'react';
import ControlButton from './control-button';
import room, { Participant } from '../../../../../utility/room';

const ShareScreen: FC = () => {
    const [isScreenSharing, setState] = useState(false);

    useEffect(() => {
        const handleUpdate = (participant: Participant) => {
            if (participant.local) {
                setState(participant.screen);
                console.log("participant-updated-screen", participant);
            }
        }
        room.on("participant-updated", handleUpdate);
        return () => {
            room.off("participant-updated", handleUpdate)
        }
    }, [])

    const handleScreenSharing = () => {
        if (!isScreenSharing) room.startScreenShare({ audio: true });
        else room.stopScreenShare();
    }

    return <ControlButton active={isScreenSharing} onClick={handleScreenSharing} icon="screen-share" loading={false} />
}

export default ShareScreen;