import { FC, useCallback, useEffect, useState } from 'react';
import styles from './styles.module.css';
import Cream from '../../cream';
import Header from './header';
import Footer from './footer';
import { AxiosError } from 'axios';
import axios from '../../../axios';
import Loading from '../../loading';
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

interface State {
    loading: boolean
    error?: string,
    metadata?: any,
}

interface Props extends React.HTMLProps<HTMLDivElement> {
    render: (props: any) => React.FunctionComponent<any>
}

const MainLayout: FC<Props> = ({ children, render }) => {
    const { org_id } = useParams<any>();
    const [{ metadata, loading, error }, setState] = useState<State>({ loading: true });

    useEffect(() => {
        axios.get(`organizations/metadata/${org_id}`).then((data: any) => {
            setState({ metadata: data, loading: false })
        }).catch((error: AxiosError) => {
            setState({ error: error.message, loading: false })
        });
    }, [org_id]);

    const updateMetadata = useCallback((data: any) => {
        setState((prev) => ({ ...prev, metadata: { ...prev.metadata, ...data } }))
    }, [])

    if (loading) {
        return <Loading />
    } else if (error) {
        return <Loading className={styles.loading} message={error} stopLoading />
    } else {
        return (
            <Cream themes={metadata.theme}>
                <Helmet>
                    <link rel="icon" href={metadata.favicon} />
                    <title>{metadata.tab_title} | Shared_Studios</title>
                </Helmet>
                <Header logo={metadata.logo} orgId={metadata.org_id} />
                {render ? render({ updateMetadata }) : children}
                <Footer logo={metadata.logo} orgId={metadata.org_id} socialMedias={metadata.social_medias} />
            </Cream>
        )
    }
}

export default MainLayout;