import { FC, memo } from 'react';
import styles from './styles.module.css';
import Icon from '../../../../../../icon';
import { domain } from '../../../../../../../config';
import { Participant as IParticipant } from '../../../../../../../utility/room';

interface Props extends IParticipant {
    spotlight: (userId: string) => void
    lowerHand: (userId: string) => void
}

const Participant: FC<Props> = ({ spotlight, lowerHand, user_id, first_name, last_name, raised_hand, profile, spotlighted }) => {
    return (
        <div key={user_id} className={styles.participant}>
            <img className={styles.profile} src={`${domain}/${profile}`} alt="profile" />
            <p className={styles.name}>{first_name} {last_name}</p>
            <button className={`${styles.raise_hand} ${raised_hand && styles.active_raise_hand}`} onClick={() => lowerHand(user_id)} disabled={!raised_hand}>
                <Icon className={styles.icon} icon="raise-hand" />
            </button>
            <button className={`${styles.spotlight} ${spotlighted && styles.active_spotlight}`} onClick={() => spotlight(user_id)}>
                <Icon className={styles.icon} icon="spotlight" />
            </button>
        </div>
    )
}

export default memo(Participant);