import { FC, useState } from 'react';
import styles from './styles.module.css';
import { Auth } from 'aws-amplify';
import Icon from '../../../../icon';
import Button from '../../../../button';
import useValidation from './useValidation';
import TextInput from '../../../../text-input';
import ForgotPasswordForm from '../forgot-password-form';
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";

const SignUpForm: FC = () => {
    const [forgotPassword, setForgotPassword] = useState(false);
    const [values, isSubmitting, errors, handleChange, handleSubmit] = useValidation();

    const handleGoogle = () => {
        Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })
    }

    if (forgotPassword) {
        return <ForgotPasswordForm setForgotPassword={setForgotPassword} />
    } else {
        return (
            <div className={styles.form}>
                <p className={styles.title}>Sign up</p>
                <Button className={styles.google} onClick={handleGoogle} >
                    <Icon className={styles.google_icon} icon="google" /> Sign up with Google
                </Button>
                <p className={styles.or}>or</p>
                {errors.message && <div className={styles.error_message}>{errors.message}</div>}
                <TextInput
                    label="First Name"
                    type="text"
                    name="given_name"
                    value={values.given_name}
                    error={errors.given_name}
                    onChange={handleChange}
                />
                <TextInput
                    label="Last Name"
                    type="text"
                    name="family_name"
                    value={values.family_name}
                    error={errors.family_name}
                    onChange={handleChange}
                />
                <TextInput
                    label="Email"
                    type="email"
                    name="email"
                    value={values.email}
                    error={errors.email}
                    onChange={handleChange}
                />
                <TextInput
                    label="Password"
                    type="password"
                    name="password"
                    value={values.password}
                    error={errors.password}
                    onChange={handleChange}
                />
                <button className={styles.forgot_password_button} onClick={() => setForgotPassword(true)}>Forgot password?</button>
                <div className={styles.tc_privacy}>
                    <input className={`${styles.tc_checkbox} ${errors.tc && styles.not_checked}`} type="checkbox" name="tc" onChange={handleChange} checked={values.tc} />
                    <p className={styles.tc_text}>By checking this box I acknowledge that I have read and accepted the <a href="https://www.sharedstudios.com/vcsp-tos" target="_blank" rel="noreferrer">Terms & Conditions</a> and the  <a href="https://www.sharedstudios.com/vcsp-privacy-policy" target="_blank" rel="noreferrer">Privacy Policy.</a> </p>
                    {errors.tc && <p className={styles.tc_error}>{errors.tc}</p>}
                </div>
                <Button onClick={handleSubmit} loading={isSubmitting}
                    disabled={errors.first_name || errors.last_name || errors.email || errors.password || errors.tc}>
                    Create Account
                </Button>
            </div>
        )
    }
}

export default SignUpForm;