import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CREATE_ERROR } from '../redux/reducers/error-reducer/types';

type Permission = PermissionStatus["state"]
type AudioTrack = [MediaStreamTrack | undefined, () => void]

const useLocalAudioTrack = (state: Permission, selected?: string): AudioTrack => {
    const dispatch = useDispatch();
    const [track, setTrack] = useState<MediaStreamTrack>();
    const [isMuted, setMute] = useState<boolean>(Boolean(localStorage.getItem("audio-muted")));

    useEffect(() => {
        if (!isMuted && state !== "denied") {
            const constrains = { deviceId: {}, echoCancellation: true }
            if (selected) constrains.deviceId = { exact: selected }
            navigator.mediaDevices.getUserMedia({ audio: constrains }).then((mediaStream) => {
                const track = mediaStream.getTracks()[0]
                console.log("useLocalAudioTrack | createTrack", track);
                if (state === "granted") {
                    setTrack((prevTrack) => {
                        if (prevTrack) prevTrack.stop();
                        return track;
                    });
                } else track.stop();
            }).catch((error) => {
                console.error("useLocalAudioTrack | createTrack", error);
                dispatch({ type: CREATE_ERROR, payload: `Microphone ${error.message}` });
                setMute(() => {
                    localStorage.setItem("audio-muted", String(true));
                    return true;
                });
            });
            return () => {
                setTrack((prevTrack) => {
                    if (prevTrack) prevTrack.stop();
                    return undefined;
                });
            }
        }
    }, [isMuted, dispatch, state, selected]);

    const handleTrack = () => {
        setMute((isMuted) => {
            if (isMuted) localStorage.removeItem("audio-muted");
            else localStorage.setItem("audio-muted", String(true));
            return !isMuted;
        });
    }

    return [track, handleTrack]
}

export default useLocalAudioTrack;