import { FC, useEffect, useState } from 'react';
import { Auth, Hub } from 'aws-amplify';
import EventToken from './utility/event-token';
import Loading from './components/loading';
import EventPage from './components/pages/event-page';
import MainLayout from './components/layout/main-layout';
import LandingPage from './components/pages/landing-page';
import AccountPage from './components/pages/account-page';
import ProfilePage from './components/pages/profile-page';
import MeetingPage from './components/pages/meeting-page';
import RedirectPage from './components/pages/redirect-page';
import CommunityPage from './components/pages/community-page';
import DiscoveryPage from './components/pages/discovery-page';
import MeetingLayout from './components/layout/meeting-layout';
import AuthenticationPage from './components/pages/authentication-page';
import { Route, Switch, RouteProps, Redirect } from 'react-router-dom';
import { Authenticate } from './components/pages/redirect-page';

interface AppRouteProps extends RouteProps {
  layout: React.FunctionComponent<any>
  component: React.FunctionComponent<any>
}

const AppRoute: FC<AppRouteProps> = ({ component: Component, layout: Layout, ...rest }) => {
  return (
    <Route {...rest} render={(props) => (
      <Layout {...props}>
        <Component {...props} />
      </Layout>
    )} />
  )
}

interface AuthRouteProps extends RouteProps {
  component: React.FunctionComponent<any>
}

const AuthRoute: FC<AuthRouteProps> = ({ component: Component, ...rest }) => {
  const [state, setState] = useState({ authenticated: false, loading: true });

  useEffect(() => {
    Auth.currentSession().then(() => {
      setState({ authenticated: true, loading: false })
    }).catch(() => {
      setState({ authenticated: false, loading: false })
    });
  }, []);

  if (state.loading) {
    return <Loading />
  } else if (state.authenticated) {
    return <Redirect to="/redirect" />
  } else {
    return (
      <Route {...rest} render={(props) => (
        <Component {...props} />
      )} />
    )
  }
}

const ProtectedRoute: FC<AppRouteProps> = ({ component: Component, layout: Layout, ...rest }) => {
  const [state, setState] = useState({ authenticated: false, loading: true });

  useEffect(() => {
    const handleSighOut = (data: any) => {
      if (data.payload.event === "signOut") {
        setState({ authenticated: false, loading: false })
      }
    }

    Auth.currentSession().then(() => {
      setState({ authenticated: true, loading: false })
    }).catch(() => {
      setState({ authenticated: false, loading: false })
    });

    Hub.listen('auth', handleSighOut);
    return () => {
      Hub.remove('auth', handleSighOut);
    }
  }, []);

  if (state.loading) {
    return <Loading />
  } else if (!state.authenticated) {
    return <Route {...rest} render={(props) => <Authenticate {...props} />} />
  } else {
    return (
      <Route {...rest} render={(props) => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )} />
    )
  }
}

interface EventRouteProps extends RouteProps {
  layout: React.FunctionComponent<any>
  component: React.FunctionComponent<any>
  layout2: React.FunctionComponent<any>
  component2: React.FunctionComponent<any>
}

const EventOrMeetingRoute: FC<EventRouteProps> = ({ component: Component, layout: Layout, component2: Component2, layout2: Layout2, ...rest }) => {
  const [authenticated, setState] = useState(!!EventToken.isValidToken());

  useEffect(() => {
    const handleTokenUpdate = () => {
      setState(!!EventToken.isValidToken())
    }
    EventToken.on('token-update', handleTokenUpdate);
    return () => {
      EventToken.removeListener('token-update', handleTokenUpdate);
    }
  }, []);

  if (!authenticated) {
    return (
      <Route {...rest} render={(props) => (
        <Layout {...props} render={(lProps: any) => (
          <Component {...props} {...lProps} />
        )} />
      )} />
    )
  } else {
    return (
      <Route {...rest} render={(props) => (
        <Layout2 {...props} render={(lProps: any) => (
          <Component2 {...props} {...lProps} />
        )} />
      )} />
    )
  }
}

const Routes = () => {
  return (
    <Switch>
      <Route path="/" exact component={LandingPage} />
      <Route exact path="/redirect" component={RedirectPage} />
      <AppRoute exact path="/:org_id" layout={MainLayout} component={DiscoveryPage} />
      <AuthRoute exact path="/:org_id/authentication" component={AuthenticationPage} />
      <ProtectedRoute exact path="/:org_id/profile" layout={MainLayout} component={ProfilePage} />
      <ProtectedRoute exact path="/:org_id/account" layout={MainLayout} component={AccountPage} />
      <ProtectedRoute exact path="/:org_id/community" layout={MainLayout} component={CommunityPage} />
      <EventOrMeetingRoute exact path="/:org_id/:event_id" layout={MainLayout} component={EventPage} layout2={MeetingLayout} component2={MeetingPage} />
      <EventOrMeetingRoute exact path="/:org_id/:event_id/:meeting_id" layout={MainLayout} component={EventPage} layout2={MeetingLayout} component2={MeetingPage} />
      <Route component={() => <div>404 page not found</div>} />
    </Switch>
  )
}

export default Routes;