import "./index.css";
import "./theme.css";
import React from "react";
import ReactDOM from "react-dom";
import store from "./redux/store";
import { Provider } from "react-redux";
import Profile from "./components/profile";
import NetWorkStatus from "./components/network-status";
// import ErrorBoundary from "./components/error-boundary";
import ErrorNotifications from './components/error-notifications';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes';

const App = () => {
  return (
    <React.StrictMode>
      {/*  <ErrorBoundary> */}
      <Provider store={store}>
        <ErrorNotifications>
          <NetWorkStatus>
            <Profile>
              <BrowserRouter>
                <Routes />
              </BrowserRouter>
            </Profile>
          </NetWorkStatus>
        </ErrorNotifications>
      </Provider>
      {/* </ErrorBoundary> */}
    </React.StrictMode>
  )
}

ReactDOM.render(<App />, document.getElementById("root"));
