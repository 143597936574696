import { FC } from 'react';
import styles from './styles.module.css';
import Button from '../../../button';
import TextInput from '../../../text-input';
import useValidation from './useValidation';
import { RootState } from "../../../../../index";
import TextareaInput from '../../../textarea-input';
import { useSelector } from 'react-redux';
import _ from "lodash";

const ProfileForm: FC = () => {
    const user = useSelector(({ user }: RootState) => user, _.isEqual);
    const [values, isSubmitting, errors, handleOnBlur, handleChange, handleSubmit, isDisabled] = useValidation(user);

    return (
        <div className={styles.form}>
            <p className={styles.title}>Edit Profile</p>
            {errors.message && <div className={styles.error_message}>{errors.message}</div>}
            <TextInput
                label="First Name"
                type="text"
                name="first_name"
                value={values.first_name}
                error={errors.first_name}
                onChange={handleChange}
                onBlur={handleOnBlur}
            />
            <TextInput
                label="Last Name"
                type="text"
                name="last_name"
                value={values.last_name}
                error={errors.last_name}
                onChange={handleChange}
                onBlur={handleOnBlur}
            />
            <TextInput
                label="Nationality"
                type="text"
                name="nationality"
                value={values.nationality}
                error={errors.nationality}
                onChange={handleChange}
                onBlur={handleOnBlur}
            />
            <TextInput
                label="Location"
                type="text"
                name="location"
                value={values.location}
                error={errors.location}
                onChange={handleChange}
                onBlur={handleOnBlur}
            />
            <TextInput
                label="Website URL (optional)"
                type="url"
                name="website_url"
                value={values.website_url}
                error={errors.website_url}
                onChange={handleChange}
                onBlur={handleOnBlur}
            />
            <TextareaInput
                label="Bio"
                subLabel="Brief description for your profile"
                name="bio"
                maxLength={1000}
                value={values.bio}
                error={errors.bio}
                onChange={handleChange}
                onBlur={handleOnBlur}
            />
            <TextareaInput
                label="Statement"
                subLabel="I want to live on a planet where..."
                name="statement"
                maxLength={1000}
                value={values.statement}
                error={errors.statement}
                onChange={handleChange}
                onBlur={handleOnBlur}
            />
            <Button onClick={handleSubmit} loading={isSubmitting} disabled={isDisabled()}>
                {isDisabled()}
                Save Profile
            </Button>
        </div>
    )
}

export default ProfileForm;