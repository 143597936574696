import { FC, useState } from 'react';
import styles from './styles.module.css';
import Icon from '../../../icon';
import LearnMore from './learn-more';
import EventOverView from './event-overview';
import InvitedGuests from './invited-guests';
import { domain } from '../../../../config';
import { Event } from '../../../../redux/reducers/meeting-reducer/types';
import SharedStudiosLogo from '../../../../images/shared-studios-logo.svg';

interface Props {
    logo: string
    event?: Event
}

const Header: FC<Props> = ({ logo, event }) => {
    return (
        <div className={styles.header}>
            <img className={styles.org_logo} src={`${domain}/${logo}-white`} alt="" />
            <div className={styles.separator} />
            <img className={styles.logo} src={SharedStudiosLogo} alt="" />
            {event && <InfoPanel {...event} />}
        </div>
    )
}

const InfoPanel: FC<Props["event"]> = ({ cover_image, title, guests, description, learn_more }) => {
    const [currentMenu, setMenu] = useState(0);
    return (
        <div className={styles.info_panel}>
            <div className={styles.menu}>
                <button className={`${styles.button} ${(currentMenu === 1) && styles.underling}`} onClick={() => setMenu(1)}>event overview</button>
                {guests.length > 0 && <button className={`${styles.button} ${(currentMenu === 2) && styles.underling}`} onClick={() => setMenu(2)}>invited guests</button>}
                {learn_more && <button className={`${styles.button} ${(currentMenu === 3) && styles.underling}`} onClick={() => setMenu(3)}>Learn more <Icon className={styles.info_icon} icon="info" /></button>}
            </div>
            {(currentMenu === 1) && <EventOverView title={title} description={description} cover_image={cover_image} onClose={() => setMenu(0)} />}
            {(currentMenu === 2) && <InvitedGuests guests={guests} onClose={() => setMenu(0)} />}
            {(currentMenu === 3) && <LearnMore learnMore={learn_more} onClose={() => setMenu(0)} />}
        </div>
    )
}

export default Header;