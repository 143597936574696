import { useCallback, useState } from 'react';
import { Auth } from 'aws-amplify';
import { RootState } from "../../../../../index";
import { useSelector, useDispatch } from 'react-redux';
import { VERIFIED_ACCOUNT } from '../../../../redux/reducers/user-reducer/types';
import _ from "lodash";

const useValidation = (onClose: () => void) => {
    const dispatch = useDispatch();
    const [errors, setErrors] = useState<any>({});
    const [values, setValues] = useState({ code: "" });
    const [isSubmitting, setSubmitting] = useState(false);
    const user = useSelector(({ user }: RootState) => user, _.isEqual);


    const handleChange = useCallback(({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
        setValues((prevState) => ({ ...prevState, [name]: value }));
        if (errors[name]) {
            setErrors((prevState: any) => {
                delete prevState[name];
                return prevState;
            });
        } else if (errors.message) {
            setErrors((prevState: any) => {
                delete prevState.message;
                return prevState;
            });
        }
    }, [errors])

    const validate = () => {
        const errors = {} as any
        if (!values.code) errors.code = "New password is required."
        return errors;
    }

    const handleSubmit = () => {
        if (user.email) {
            setErrors({});
            setSubmitting(true);
            const errors = validate();
            if (Object.values(errors).length) {
                setErrors(errors);
                setSubmitting(false);
            } else {
                Auth.confirmSignUp(user.email, values.code).then((res) => {
                    onClose();
                    console.log(res);
                    dispatch({ type: VERIFIED_ACCOUNT });
                }).catch((err: any) => {
                    setSubmitting(false);
                    console.log({ ...err, message: err.message });
                    setErrors({ message: err.message || "Sorry an Error Occurred. Please Try Again." });
                });
            }
        }
    }

    const handelResendCode = () => {
        if (user.email) {
            Auth.resendSignUp(user.email).catch((err: any) => {
                console.log(JSON.stringify(err));
                setErrors({ message: err.message || "Sorry an Error Occurred. Please Try Again." });
            });
        }
    }

    return [values, isSubmitting, errors, handleChange, handleSubmit, handelResendCode]
}

export default useValidation;