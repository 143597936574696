import { FC } from 'react';
import styles from './styles.module.css'

interface Props {
    className?: string
}

const BouncingDots: FC<Props> = ({ className }) => {
    return (
        <div className={`${styles.spinner} ${className}`}>
            <div className={styles.dot1} />
            <div className={styles.dot2} />
            <div className={styles.dot3} />
        </div>
    );
}

export default BouncingDots;