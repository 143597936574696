import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CREATE_ERROR } from '../redux/reducers/error-reducer/types';

type state = "granted" | "prompt" | "denied"

const usePermission = (name: "camera" | "microphone") => {
    const dispatch = useDispatch();
    const [state, setState] = useState<state>("denied");

    useEffect(() => {
        //@ts-ignore
        navigator.permissions.query({ name }).then((result) => {
            setState(result.state);
            // console.log(`usePermissions | ${name} ${result.state}`);
            result.addEventListener("change", () => {
                setState(result.state);
                if (result.state === "denied") {
                    dispatch({ type: CREATE_ERROR, payload: `${name} permission changed to denied.` });
                }
            })
        });
    }, [name, dispatch]);

    return [state];
}

export default usePermission;