import { FC } from 'react';
import { Auth } from 'aws-amplify';
import { Redirect, useParams, useHistory } from 'react-router-dom';

export const Authenticate: FC<any> = () => {
    const history = useHistory();
    const { org_id } = useParams<any>();
    localStorage.setItem('redirect', history.location.pathname);
    return <Redirect to={`/${org_id}/authentication`} push={true} />
}

export const useRedirect = () => {
    const history = useHistory();
    const { org_id } = useParams<any>();

    return {
        signIn: (query?: string, state?: unknown) => {
            localStorage.setItem('redirect', history.location.pathname);
            if (!query) history.push(`/${org_id}/authentication`, state);
            else history.push(`/${org_id}/authentication${query}`, state);
        },
        signOut: () => {
            localStorage.setItem("redirect", history.location.pathname);
            Auth.signOut();
        }
    }
}

const RedirectPage: FC = () => <Redirect to={localStorage.getItem('redirect') || '/'} push={true} />

export default RedirectPage;