import { FC } from 'react';
import styles from './styles.module.css';
import EndCall from './end-call';
import VideoControl from "./video";
import AudioControl from "./audio";
import RaiseHand from './raise-hand';
import ShareScreen from './share-screen';
import BreakoutRoom from './breakout-room';
import SpotlightControl from "./spotlight-control";
import { useSpring, animated } from 'react-spring';
import { RootState } from '../../../../../../index';
import { useSelector, } from 'react-redux';
import _ from "lodash";

const Controls: FC = () => {
    const { role, is_started, is_breakout_room } = useSelector(({ meeting }: RootState) => {
        return {
            role: meeting.role,
            is_started: meeting.is_started,
            is_breakout_room: !!meeting.breakout_room_id
        }
    }, _.isEqual);

    const props = useSpring({
        delay: 1000,
        from: { bottom: "-100px" },
        to: { bottom: "10px" },
    });

    return (
        <animated.div className={styles.controls} style={{ ...props, marginLeft: (role === "admin" ? (is_breakout_room ? "-140px" : "-237.5px") : (is_breakout_room ? "-107.5px" : "-140px")) }} >
            <VideoControl />
            <AudioControl />
            {(is_breakout_room || role === "admin") && <ShareScreen />}
            <RaiseHand />
            {(!is_breakout_room && role === "admin") && <SpotlightControl />}
            {role === "admin" && <BreakoutRoom />}
            <EndCall type={role} isStarted={is_started} />
        </animated.div>
    )
}

export default Controls;