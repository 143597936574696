import { FC, useState } from 'react';
import styles from './styles.module.css';
import moment from 'moment';
import { Connection } from '../index'
import { Link } from "react-router-dom";
import { domain } from '../../../../../config';
import ImageWithFallback from '../../../../image-with-fallback/ImageWithFallback';
import defaultCoverImage from '../../../../../images/default-coverpage.jpg';
import Button from '../../../../../components/button'
import NewMeetingModal from './new-meeting-modal';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../..';
import _ from "lodash";

const ConnectionCard: FC<Connection> = ({ cover_image, date, title, short_description, org_id, event_id }) => {
    
    const [meetingModal, meetingToggleModal] = useState(0);
    const [meetingUrl, setMeetingUrl] = useState<string>("");
    const bufferStr = 'abcdefghijklmnopqrstuvwxyz'
    const { createMeetingAccess } = useSelector(({ user }: RootState) => ({ createMeetingAccess: user.createMeetingAccess }), _.isEqual);

    const showModalAndCreateMeetingUrl = (val: number) => {
        meetingToggleModal(val);
        const randomStr = [...Array(9)].map(_ => bufferStr[~~(Math.random()*bufferStr.length)]).join('')
        const finalMeetingStr = randomStr.substring(0,3) + "-" + randomStr.substring(3,6) + "-" + randomStr.substring(6,9)
        setMeetingUrl(domain + "/" + org_id + "/" + event_id + "/" + finalMeetingStr);
    }

    return (
        <div className={styles.upcoming_connection_card}>
            {/* <img className={styles.image} alt={title} aria-label={title} src={`${domain}/${cover_image}`} /> */}
            <ImageWithFallback fallback={defaultCoverImage} src={`${domain}/${cover_image}`} title={title} className={styles.image} ></ImageWithFallback>
            <div className={styles.card_info}>
                <p className={styles.date}>{date ? moment(date).format("MMMM Do YYYY") : "TBD"}</p>
                <p className={styles.title}>{title}</p>
                <p className={styles.short_description}>{short_description}</p>
                <Link className={`${styles.learn_more_button} ${styles.learn_more_button_custom}`} to={`${org_id}/${event_id}`}>Learn more</Link>
                {(createMeetingAccess) && <Button onClick={() => showModalAndCreateMeetingUrl(1)} className={styles.create_meeting_button}>Create a Meeting</Button>}
            </div>
            {(meetingModal === 1) && <NewMeetingModal meetingLink={meetingUrl} close={() => meetingToggleModal(0)} />}
        </div>
    )
}

export default ConnectionCard;