import { FC, useState } from "react";
import styles from './styles.module.css';
import Icon from '../../../icon';
import { IMember } from '../index';
import { domain } from "../../../../config";


const MemberCard: FC<IMember> = ({ first_name, last_name, email, location, website_url, statement, bio, profile }) => {
    const [show, toggle] = useState(false);

    return (
        <div className={styles.member_card}>
            <img className={styles.profile_image} alt="" src={`${domain}/${profile}`} />
            <p className={styles.name}>{first_name} {last_name}</p>
            {/* {location && <p className={styles.info}><Icon className={styles.icon} icon="location" />{location}</p>} */}
            {email && <p className={styles.info}><Icon className={styles.icon} icon="email" />{email}</p>}
            {website_url && <p className={styles.info}><Icon className={styles.icon} icon="website" />{website_url}</p>}
            {statement && <p className={styles.statement}>“{statement}”</p>}
            {bio && <p className={styles.bio}>{show ? bio : bio.substr(0, 400)}{(bio.length > 400 && !show) && '...'}</p>}
            {bio?.length > 400 && <button className={styles.button} onClick={() => toggle(!show)}>{!show ? 'See More' : 'See Less'}</button>}
        </div>
    )
}

export default MemberCard