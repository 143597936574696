import { FC, memo } from 'react';
import styles from './styles.module.css';
import BouncingDots from "../loading-animation/bouncing-dots";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    loading?: boolean
}

const Button: FC<Props> = ({ className, children, loading, style, disabled, ...rest }) => {
    return (
        <button className={`${styles.button} ${className}`} {...rest} style={style} disabled={disabled || loading}>
            {loading ? <BouncingDots /> : children}
        </button >
    );
}

export default memo(Button);