import { FC } from 'react';
import styles from './styles.module.css';
import SelfieView from "./selfie-view";
import Countdown from '../../../countdown';
import { RootState } from '../../../../..';
import { useSelector } from 'react-redux';
import _ from "lodash";

const WaitingRoom: FC = () => {
    const event = useSelector(({ meeting }: RootState) => {
        return { date: meeting.date, title: meeting.title }
    }, _.isEqual);

    return (
        <div className={styles.waiting_room}>
            {(event.date && event.title) && <Countdown className={styles.countdown} date={event.date} title={event.title} />}
            <SelfieView />
        </div>
    )

}

export default WaitingRoom;