import { IUser, IUserAction } from './types';
import { FETCH_PROFILE_FULFILLED, FETCH_PROFILE_REJECTED, FETCH_PROFILE_PENDING } from './types';
import { LOG_OUT, PROFILE_UPDATED, REMOVED_ACCOUNT, SET_PASSWORD, VERIFIED_ACCOUNT } from './types';

const InitialState = { loading: true, logged_in: false }

const user = (state: IUser = InitialState, action: IUserAction): IUser => {
    switch (action.type) {
        case LOG_OUT: {
            return Object.assign({}, { loading: false, logged_in: false });
        } case FETCH_PROFILE_PENDING: {
            return Object.assign({}, state, { loading: true, logged_in: false });
        } case FETCH_PROFILE_FULFILLED: {
            const profile = action.payload
            return Object.assign({}, profile, { loading: false, logged_in: true });
        } case FETCH_PROFILE_REJECTED: {
            return Object.assign({}, state, { loading: false, logged_in: false });
        } case PROFILE_UPDATED: {
            return Object.assign({}, state, { profile: `${state.profile}?last=${Date.now()}` });
        } case REMOVED_ACCOUNT: {
            return Object.assign({}, state, { google: false });
        } case SET_PASSWORD: {
            return Object.assign({}, state, { password: true });
        } case VERIFIED_ACCOUNT: {
            return Object.assign({}, state, { password_verified: true });
        } default: {
            return state
        }
    }
}

export default user;