import { FC } from 'react';
import styles from './styles.module.css';
import Button from '../../../../../button';
import Modal, { Title, Text } from '../../../../../modal';
import { useSelector, } from 'react-redux';
import { RootState } from '../../../../../../..';
import room from '../../../../../../utility/room';
import EventToken from '../../../../../../utility/event-token';
import _ from "lodash";

interface Props {
    close: () => void
}

const EndCallModal: FC<Props> = ({ close }) => {
    const isStarted = useSelector(({ meeting }: RootState) => meeting.is_started, _.isEqual);

    const handleEndCall = () => {
        if (!isStarted) {
            EventToken.invalidateToken();
        } else if (isStarted) {
            close();
            room.leave();
        }
    }

    return (
        <Modal>
            <Title className={styles.title}>Sure you want to leave?</Title>
            <Text className={styles.text}>Only you will be disconnected from the event.</Text>
            <div className={styles.modal_buttons}>
                <Button onClick={close}>Cancel</Button>
                <Button className={styles.end} onClick={handleEndCall}>
                    Leave the Event
                </Button>
            </div>
        </Modal>
    )
}

export default EndCallModal;