import { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';

const useValidation = () => {
    const history = useHistory();
    const [errors, setErrors] = useState<any>({});
    const [isSubmitting, setSubmitting] = useState(false);
    const [values, setValues] = useState({ email: "", password: "" });


    const handleChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
        setValues((prevState) => ({ ...prevState, [name]: value }));
        if (errors[name]) {
            setErrors((prevState: any) => {
                delete prevState[name];
                return prevState;
            });
        }
    }

    const validate = () => {
        const errors = {} as any
        if (!values.email) errors.email = "Email is required."
        if (!values.password) errors.password = "Password is required."
        return errors;
    }

    const handleSubmit = () => {
        setErrors({});
        setSubmitting(true);
        const errors = validate();
        if (Object.values(errors).length) {
            setErrors(errors);
            setSubmitting(false);
        } else {
            Auth.signIn(values.email, values.password).then(() => {
                history.push("/redirect");
            }).catch((err) => {
                console.log(err);
                if (err.code === "UserNotConfirmedException") {
                    return Auth.resendSignUp(values.email).then(() => {
                        history.push({ search: `type=verify&email=${values.email}` })
                    });
                } else if (err.code === "NotAuthorizedException") {
                    throw new Error("Incorrect username or password.");
                } else {
                    throw new Error(err);
                }
            }).catch((err) => {
                setSubmitting(false);
                setErrors({ message: err.message || "Sorry an Error Occurred. Please Try Again." });
            });
        }
    }

    return [values, isSubmitting, errors, handleChange, handleSubmit]
}

export default useValidation;