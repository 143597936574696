import { FC } from 'react';
import styles from './styles.module.css';
import TextInput from '../../../text-input';
import Button from '../../../button';
import Modal from '../../../modal';
import Icon from '../../../icon';
import useValidation from './useValidation';

interface Props {
    onClose: () => void
}

const ChangePasswordFrom: FC<Props> = ({ onClose }) => {
    const [values, isSubmitting, errors, handleChange, handleSubmit] = useValidation(onClose);

    return (
        <Modal className={styles.modal}>
            <div className={styles.form}>
                <button className={styles.close_button} onClick={onClose}>
                    <Icon className={styles.close_icon} icon="close" />
                </button>
                <p className={styles.title}>Change Password</p>
                {errors.message && <div className={styles.error_message}>{errors.message}</div>}
                <TextInput
                    label="Current Password"
                    type="password"
                    name="current_password"
                    value={values.current_password}
                    error={errors.current_password}
                    onChange={handleChange}
                />
                <TextInput
                    label="New Password"
                    type="password"
                    name="new_password"
                    value={values.new_password}
                    error={errors.new_password}
                    onChange={handleChange}
                />
                <TextInput
                    label="Confirm New Password"
                    type="password"
                    name="confirm_password"
                    value={values.confirm_password}
                    error={errors.confirm_password}
                    onChange={handleChange}
                />
                <Button
                    onClick={handleSubmit}
                    loading={isSubmitting}
                    disabled={errors.current_password || errors.new_password || errors.confirm_password}
                >
                    Change
                </Button>
            </div>
        </Modal>
    )
}

export default ChangePasswordFrom;