import { FC } from 'react';
import styles from './styles.module.css';

interface Props {
    label: string
    checked: boolean
    onChange: () => void
}

const Item: FC<Props> = ({ onChange, label, checked }) => {
    return (
        <div className={styles.item} onClick={onChange} >
            <input type="radio" checked={checked} readOnly />
            <label>{label}</label>
        </div>
    )
}

export default Item;