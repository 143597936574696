import { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';

const useValidation = () => {
    const history = useHistory();
    const [errors, setErrors] = useState<any>({});
    const [isSubmitting, setSubmitting] = useState(false);
    const [values, setValues] = useState({ given_name: "", family_name: "", email: "", password: "", tc: true });

    const handleChange = ({ target: { name, value, checked } }: React.ChangeEvent<HTMLInputElement>) => {
        setValues((prevState) => ({ ...prevState, [name]: (name === "tc") ? checked : value }));
        if (errors[name]) {
            setErrors((prevState: any) => {
                delete prevState[name];
                return prevState;
            });
        }
    }

    const validate = () => {
        const errors = {} as any
        if (!values.given_name) errors.given_name = "First name is required."
        if (!values.family_name) errors.family_name = "Last name is required."
        if (!values.email) errors.email = "Email is required."
        if (!values.password) errors.password = "Password is required."
        if (!values.tc) errors.tc = "You must accept the terms and conditions before registering"
        return errors;
    }

    const handleSubmit = () => {
        setErrors({});
        setSubmitting(true);
        const errors = validate();
        if (Object.values(errors).length) {
            setErrors(errors);
            setSubmitting(false);
        } else {
            Auth.signUp({
                username: values.email,
                password: values.password,
                attributes: {
                    email: values.email,
                    given_name: values.given_name,
                    family_name: values.family_name,
                    name: `${values.given_name} ${values.family_name}`
                }
            }).then((data) => {
                console.log(data)
                history.push({ search: `type=verify&email=${values.email}` })
            }).catch((err: any) => {
                setSubmitting(false);
                console.log({ name: err.name, code: err.code, message: err.message });
                setErrors({ message: err.message || "Sorry an Error Occurred. Please Try Again." });
            });
        }
    }

    return [values, isSubmitting, errors, handleChange, handleSubmit]
}

export default useValidation;

// { user: CognitoUser, userConfirmed: false, userSub: '4396ee25-c72f-4190-8b12-0af2426e9448', codeDeliveryDetails: { … } }
// codeDeliveryDetails:
// AttributeName: "email"
// DeliveryMedium: "EMAIL"
// Destination: "s***@s***.com"
// [[Prototype]]: Object
// user: CognitoUser
// Session: null
// authenticationFlowType: "USER_SRP_AUTH"
// client: Client { endpoint: 'https://cognito-idp.us-east-1.amazonaws.com/', fetchOptions: { … } }
// keyPrefix: "CognitoIdentityServiceProvider.4nbhv7dnq8juhtel2ronnkdhke"
// pool: CognitoUserPool { userPoolId: 'us-east-1_Mb6xnkW6E', clientId: '4nbhv7dnq8juhtel2ronnkdhke', client: Client, advancedSecurityDataCollectionFlag: true, storage: Storage, … }
// signInUserSession: null
// storage: Storage { audio - muted: 'true', randid: 'iger9fipe42o8obr06b0ghrcah4', audiooutput: 'default', videoinput: 'aa0b316db59c14ab37d8bfea1d7dee3f1da2aa56919cc39e9b7db2f3fd99415a', video - muted: 'true', … }
// uploadProgressKey: "__uploadInProgress"
// userDataKey: "CognitoIdentityServiceProvider.4nbhv7dnq8juhtel2ronnkdhke.siam@sharedstudios.com.userData"
// username: "siam@sharedstudios.com"
// [[Prototype]]: Object
// userConfirmed: false
// userSub: "4396ee25-c72f-4190-8b12-0af2426e9448"
// [[Prototype]]: Object