import { useState } from 'react';
import styles from './styles.module.css';
import room from '../../../../../../utility/room';
import { Rating } from 'react-simple-star-rating';
import Modal, { Title } from '../../../../../modal';
import { MdOutlineSentimentSatisfied } from 'react-icons/md'
import EventToken from '../../../../../../utility/event-token';

const UserRatingModal = () => {

    const handleEndCall = (rate: number) => {
        setRatingValue(rate);
        EventToken.invalidateToken();
        room.scoreMeeting(rate / 10);
    }

    const [ratingValue, setRatingValue] = useState<number>(0);

    const customIcons = [
        { icon: <MdOutlineSentimentSatisfied size={45} /> },
        { icon: <MdOutlineSentimentSatisfied size={45} /> },
        { icon: <MdOutlineSentimentSatisfied size={45} /> },
        { icon: <MdOutlineSentimentSatisfied size={45} /> },
        { icon: <MdOutlineSentimentSatisfied size={45} /> }
    ]

    return (
        <Modal>
            <Title className={styles.title}>How was the call quality?</Title>
            <div className={styles.rating_container}>
                <Rating
                    size={45}
                    allowHalfIcon={true}
                    fillColor={'#f3c716'}
                    ratingValue={ratingValue}
                    onClick={handleEndCall}
                    customIcons={customIcons}
                />
            </div>
        </Modal>
    )
}

export default UserRatingModal;