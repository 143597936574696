import { FC, useEffect, useState } from 'react';
import styles from './styles.module.css';
import Banner from './banner';
import axios from '../../../axios';
import Loading from '../../loading';
import AboutEvent from './about-event';
import Countdown from '../../countdown';
import { useParams } from "react-router-dom";
import { Authenticate } from '../redirect-page';
import EventToken from '../../../utility/event-token';
import JoinNow from './join-now';

interface Props extends React.HTMLProps<HTMLDivElement> {
    updateMetadata: (data: any) => void
}

interface State {
    event?: any
    error?: string
    loading: boolean
}

const EventInfoPage: FC<Props> = ({ updateMetadata }) => {
    const { org_id, event_id } = useParams<any>();
    const [{ event, loading, error }, setState] = useState<State>({ loading: true });

    useEffect(() => {
        axios.get(`events/${org_id}/${event_id}`).then((event: any) => {
            setState({ event, loading: false });
            updateMetadata({ tab_title: event.title });
            !event.join_status && EventToken.invalidateToken();
        }).catch((error) => setState({ error: error.message, loading: false }));
    }, [org_id, event_id, updateMetadata]);

    if (loading) {
        return <Loading className={styles.loading} />
    } else if (error) {
        return <Loading className={styles.loading} message={error} stopLoading />
    } else if (event.required_authentication) {
        return <Authenticate />
    } else {
        return (
            <div className={styles.event_info_page}>
                <Banner coverImage={event.cover_image}>
                    <Countdown date={event.date} title={event.title} />
                </Banner>
                <div className={styles.body}>
                    <AboutEvent about={event.description} guests={event.guests} />
                    {event.join_status && <JoinNow joinStatus={event.join_status} />}
                </div>
            </div>
        )
    }
}

export default EventInfoPage;


// export interface Loading {
//     loading: boolean
//     error: string
// }

// export interface JoinStats {
//     login: boolean
//     join_now: boolean
//     zoom_link?: string
//     waiting_list: boolean
//     reason: null | string
// }

// export interface Event extends Loading, JoinStats {
//     date: number
//     title: string
//     guests: Guest[]
//     event_id: string
//     partial?: boolean
//     learn_more: string
//     cover_image: string
//     description: string
//     is_previous_event: boolean
// }

// export interface IEvent {
//     loading: boolean
//     error?: string
//     event?: Event
// }