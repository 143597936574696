import { FC, useState } from 'react';
import styles from './styles.module.css';
import Icon from "../../../../icon";
import { RootState } from "../../../../../../index";
import { sendMessage } from "../../../../../redux/action/message";
import { useDispatch, useSelector, } from "react-redux";
import _ from "lodash";

const Input: FC = () => {
    const dispatch = useDispatch();
    const [text, setText] = useState("");
    const { profile } = useSelector(({ user }: RootState) => {
        return { profile: user.profile || "" }
    }, _.isEqual);

    const handleClick = () => {
        if (text.trim().length > 0) {
            const message = text.trim();
            const time_stamp = Date.now();
            dispatch(sendMessage({ name: "you", message, time_stamp, profile, is_user: true }));
            setText("");
        }
    }

    const textSubmit = (e: any) => {
        if (e.keyCode === 13 && !e.shiftKey) {
            e.preventDefault();
            handleClick();
        }
    }

    const handleChange = (e: any) => {
        if (e.target.value.length <= 700) {
            setText(e.target.value)
        }
    }

    return (
        <div className={styles.input} tabIndex={0}>
            <div className={styles.wrapper}>
                <textarea
                    className={styles.text_input}
                    value={text}
                    autoComplete="off"
                    onKeyDown={textSubmit}
                    onChange={handleChange}
                    placeholder="Type your message..."
                />
                <span>{text}</span>
            </div>
            <button className={styles.button} type="submit" onClick={handleClick}>
                <Icon className={styles.icon} icon="send" />
            </button>
        </div>
    )
}

export default Input;