import { AxiosError } from 'axios';

export const FETCH_PROFILE_FULFILLED = "FETCH-PROFILE-FULFILLED";
export const FETCH_PROFILE_REJECTED = "FETCH-PROFILE-REJECTED";
export const FETCH_PROFILE_PENDING = "FETCH-PROFILE-PENDING";
export const FETCH_PROFILE = "FETCH-PROFILE";

export const SET_PASSWORD = "SET-PASSWORD";
export const REMOVED_ACCOUNT = "REMOVED-ACCOUNT";
export const VERIFIED_ACCOUNT = "VERIFIED-ACCOUNT";
export const PROFILE_UPDATED = "PROFILE-UPDATED";

export const LOG_OUT = "LOG-OUT";

export interface UserProfile {
    user_id?: string
    profile?: string
    last_name?: string
    first_name?: string
    email?: string
    nationality?: string
    location?: string
    website_url?: string
    bio?: string
    statement?: string
    password?: boolean
    google?: boolean
    password_verified?: boolean
}

export interface IUser extends UserProfile {
    loading: boolean
    logged_in: boolean
    error?: string,
    createMeetingAccess?: boolean
}

export interface DispatchFetchProfileAction {
    type: typeof FETCH_PROFILE
    payload: Promise<void>
}

export interface FetchProfilePending {
    type: typeof FETCH_PROFILE_PENDING
}

export interface FetchProfileRejected {
    type: typeof FETCH_PROFILE_REJECTED
    payload: AxiosError | Error
}

export interface FetchProfileFulfilled {
    type: typeof FETCH_PROFILE_FULFILLED
    payload: { data: UserProfile }
}

export interface LogOut {
    type: typeof LOG_OUT
}

export interface UserSetPassword {
    type: typeof SET_PASSWORD
}

export interface UserRemovedAccount {
    type: typeof REMOVED_ACCOUNT
}

export interface UserVerifiedAccount {
    type: typeof VERIFIED_ACCOUNT
}

export interface UserProfilePictureUpdated {
    type: typeof PROFILE_UPDATED
}

export type IUserAction = FetchProfilePending | FetchProfileRejected | FetchProfileFulfilled | LogOut | UserVerifiedAccount | UserRemovedAccount | UserSetPassword | UserProfilePictureUpdated