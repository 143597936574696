import { useCallback, useState } from 'react';
import axios from '../../../../axios';
import { IUser } from '../../../../redux/reducers/user-reducer/types';

const useValidation = (user: IUser) => {
    const [errors, setErrors] = useState<any>({});
    const [isSubmitting, setSubmitting] = useState(false);
    const [values, setValues] = useState({
        bio: user.bio || "",
        last_name: user.last_name,
        first_name: user.first_name,
        location: user.location || "",
        statement: user.statement || "",
        nationality: user.nationality || "",
        website_url: user.website_url || "",
        createMeetingAccess: user.createMeetingAccess || false
    });

    const handleChange = useCallback(({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
        setValues((prevState) => ({ ...prevState, [name]: value }));
        if (name in errors) {
            delete errors[name]
            setErrors(errors);
        }
    }, [errors])

    const handleOnBlur = useCallback(({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
        if (!value && name in user) {
            // @ts-ignore
            setValues((prevState) => ({ ...prevState, [name]: user[name] }));
        }
    }, [user])

    const getUpdatedValues = () => {
        const updatedValue = {};
        Object.entries(values).forEach(([key, value]) => {
            // @ts-ignore
            if (value && user[key] !== value) updatedValue[key] = value
        });
        return updatedValue;
    }

    const validate = () => {
        const errors = {} as any
        if (!values.bio) errors.bio = "Bio is required.";
        if (!values.location) errors.location = "Location is required.";
        if (!values.statement) errors.statement = "Statement is required.";
        if (!values.last_name) errors.last_name = "Last name is required.";
        if (!values.first_name) errors.first_name = "First name is required.";
        if (!values.nationality) errors.nationality = "Nationality is required.";
        return errors;
    }

    const hasUpdates = () => {
        // @ts-ignore
        return !!Object.entries(values).find(([key, value]) => value && user[key] !== value);
    }

    const isDisabled = () => {
        return !hasUpdates() || Object.values(errors).length > 0;
    }

    const handleSubmit = () => {
        setSubmitting(true);
        const errors = validate();
        if (hasUpdates() && Object.values(errors).length > 0) {
            setErrors(errors);
            setSubmitting(false);
        } else {
            const data = getUpdatedValues();
            const config = { headers: { "Content-Type": "application/json" } }
            axios.put('users/profile', data, config).then(() => {
                setValues(values);
                setSubmitting(false);
            }).catch((error) => {
                setSubmitting(false);
                setErrors(error);
            });
        }
    }

    return [values, isSubmitting, errors, handleOnBlur, handleChange, handleSubmit, isDisabled]
}

export default useValidation;