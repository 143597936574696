import { useState, useEffect, useCallback } from 'react';
import styles from './styles.module.css';
import Icon from '../../../icon';
import axios from '../../../../axios';
import { useParams } from "react-router-dom";
import ConnectionCard from './connection-card';

const PreviousEvents = () => {
    const { org_id } = useParams();
    const [{ page, events, loading, page_num }, setState] = useState({ events: [], page: 0 });


    const handleRight = useCallback(() => {
        if (page_num || !events.length) {
            setState((prev) => ({ ...prev, loading: true }));
            let path = `organizations/events/${org_id}?limit=4&previous`
            path = page_num ? `${path}&page=${page_num}` : path
            axios.get(path).then((data) => {
                setState((prev) => {
                    const events = [...prev.events, ...data.events];
                    // const itemsLeft = (events.length - (prev.page + 4));
                    return {
                        events,
                        page: 0,
                        loading: false,
                        page_num: data.page,
                        // page: (itemsLeft > 4) ? (prev.page + 4) : ((prev.page + 4) - (4 - itemsLeft)),
                    }
                });
            }).catch((error) => {
                setState((prev) => ({ ...prev, error: error.message, loading: false }))
            });
        } else {
            setState((prev) => {
                const itemsLeft = (prev.events.length - (prev.page + 4));
                return {
                    ...prev,
                    page: (itemsLeft > 4) ? (prev.page + 4) : ((prev.page + 4) - (4 - itemsLeft)),
                }
            });
        }
    }, [org_id, page_num, events.length])

    const handleLeft = () => {
        setState((prev) => ({ ...prev, page: (prev.page - 4 <= 0) ? 0 : (prev.page - 4) }));
    }

    useEffect(() => {
        if (!events.length) handleRight()
    }, [events.length, handleRight]);

    if (events.length) {
        return (
            <div className={styles.previous_connections}>
                <p className={styles.previous_connection_title}>Explore previous conversations</p>
                {(page_num || events.length > 4) && <button className={styles.prev} onClick={handleLeft} disabled={page === 0}>
                    <Icon className={styles.prev_icon} icon="arrow" />
                </button>}
                <div className={styles.connections_list}>
                    {events.slice(page, page + 4).map((event) => <ConnectionCard key={event.event_id} {...event} />)}
                </div>
                {(page_num || events.length > 4) && <button className={styles.next} onClick={handleRight} disabled={(!page_num && (events.length - (page + 4) === 0))}>
                    <Icon className={styles[loading ? "loading" : "next_icon"]} icon={loading ? "loading" : "arrow"} />
                </button>}
            </div>
        )
    } else {
        return null
    }
}

export default PreviousEvents;