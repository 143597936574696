import { FC, useEffect } from 'react';
import styles from './styles.module.css';
import GoBack from './go-back';
import Loading from './../../loading';
import SSE from './server-sent-events';
import WaitingRoom from './waiting-room';
import MeetingRoom from './meeting-rooms';
import BrowserCheck from './browser-check';
import ParticipantChat from './participant-chat';
import { RootState } from './../../../../index';
import EventToken from '../../../utility/event-token';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMeetingRoom } from './../../../redux/action/meeting';
import FlowerSpinner from './../../loading-animation/flower-spinner';
import _ from "lodash";

interface Props extends React.HTMLProps<HTMLDivElement> {
    updateMetadata: (data: any) => void
}

const MeetingPage: FC<Props> = ({ updateMetadata }) => {
    const dispatch = useDispatch();
    const { loading, error, is_started, is_previous_event } = useSelector(({ meeting }: RootState) => {
        return {
            error: meeting.error,
            loading: meeting.loading,
            is_user: !!meeting.user_id,
            is_started: meeting.is_started,
            breakout_room_id: meeting.breakout_room_id,
            is_previous_event: meeting.is_previous_event,
        }
    }, _.isEqual);

    useEffect(() => { dispatch(fetchMeetingRoom(updateMetadata)) }, [dispatch, updateMetadata]);
    useEffect(() => { is_previous_event && EventToken.invalidateToken() }, [dispatch, is_previous_event]);

    if (loading) {
        return <Loading className={styles.loading} message="Getting meeting info..." spinner={FlowerSpinner} />
    } else if (error) {
        return (
            <Loading className={styles.loading} message={error} stopLoading >
                <GoBack />
            </Loading>
        )
    } else {
        return (
            <BrowserCheck>
                <SSE>
                    {is_started ? <MeetingRoom /> : <WaitingRoom />}
                    <ParticipantChat />
                </SSE>
            </BrowserCheck>
        )
    }

}

export default MeetingPage;