import { FC, useState } from 'react';
import styles from './styles.module.css';
import Button from '../../../button';
import axios from '../../../../axios';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import { useRedirect } from '../../redirect-page';
import EventToken from '../../../../utility/event-token';
import { CREATE_ERROR } from '../../../../redux/reducers/error-reducer/types';

interface JoinStatus {
    login: boolean
    join_now: boolean
    zoom_link?: string
    waiting_list: boolean
    reason: null | string
}
interface Props {
    joinStatus: JoinStatus
}

interface State extends JoinStatus {
    loading: boolean
}


const JoinNow: FC<Props> = ({ joinStatus }) => {
    const dispatch = useDispatch();
    const redirect = useRedirect()
    const { org_id, event_id } = useParams<any>();
    const [{ loading, ...join_status }, setState] = useState<State>({ ...joinStatus, loading: false });

    const handleJoinEvent = () => {
        setState((prev) => ({ ...prev, loading: true }));
        axios.post(`events/join-event/${org_id}/${event_id}`).then((data: any) => {
            EventToken.setToken(data.token, event_id);
        }).catch((error: any) => {
            setState((prev) => ({ ...prev, loading: false }));
            dispatch({ type: CREATE_ERROR, payload: error.message });
        })
    }

    const handleJoinWaitingList = () => {
        setState((prev) => ({ ...prev, loading: true }));
        axios.post(`events/join-waiting-list/${org_id}/${event_id}`).then(() => {
            setState((prev) => ({ ...prev, loading: false, waiting_list: false, reason: "Waiting wo be accepted" }));
        }).catch((error: any) => {
            setState((prev) => ({ ...prev, loading: false }));
            dispatch({ type: CREATE_ERROR, payload: error.message });
        })
    }

    if (join_status.login) {
        return <Button className={styles.join_now} onClick={() => redirect.signIn()}>Sign In</Button>
    } else if (join_status.zoom_link) {
        return <Button className={styles.join_now} onClick={() => window.open(join_status.zoom_link, "_blank")}>Join Now</Button>
    } else if (join_status.join_now) {
        return <Button className={styles.join_now} loading={loading} disabled={loading} onClick={handleJoinEvent}>Join Now</Button>
    } else if (join_status.waiting_list) {
        return <Button className={styles.join_now} loading={loading} disabled={loading} onClick={handleJoinWaitingList}>Join Waiting List</Button>
    } else {
        return <p className={styles.reason}>{join_status.reason}</p>
    }
}

export default JoinNow;