import { meeting } from '../axios';
import EventEmitter from 'events';
import captureStatData from './capture-stat-data';
import DailyIframe, { DailyCall, DailyCallOptions, DailyScreenCaptureOptions } from '@daily-co/daily-js';
import { DailyEventObjectNoPayload, DailyCameraErrorType, DailyTrackSubscriptionOptions, DailyEventObjectTrack, DailyParticipant, DailyEventObjectParticipant, DailyEventObjectCameraError, DailyEventObjectFatalError, DailyEventObjectActiveSpeakerChange } from '@daily-co/daily-js'

export interface EventObjectMeetingError {
    message: string
}

export interface InputDevices {
    audioDeviceId?: string | false;
    audioSource?: MediaStreamTrack | false;
    videoDeviceId?: string | false;
    videoSource?: MediaStreamTrack | false;
}

export interface Bandwidth {
    kbs?: number | "NO_CAP" | null | undefined;
    trackConstraints?: MediaTrackConstraints | undefined;
}

export interface EventObjectCameraError {
    errorMsg: {
        errorMsg: string;
        audioOk?: boolean;
        videoOk?: boolean;
    };
    error?: {
        type: DailyCameraErrorType;
        localizedMsg?: string;
    };
}

export interface Participant extends DailyParticipant {
    user_id: string
    profile: string
    speaker: boolean
    last_name: string
    first_name: string
    raised_hand?: boolean
    spotlighted?: boolean
    type: "participant" | "guest" | "moderator"
}

export interface EventObjectJoinedMeeting {
    screen_sharing?: string
    active_participants: string[]
    spotlighted_participants: string[]
    participants: { [key: string]: Participant }
}

export interface EventObjectSpeakerChanged {
    new: string
    prev?: string
}

export interface Room extends EventEmitter {
    on(event: "left-meeting", listener: () => void): this
    on(event: "leaving-meeting", listener: () => void): this
    on(event: "joining-meeting", listener: () => void): this
    on(event: "joined-meeting", listener: (event: EventObjectJoinedMeeting) => void): this
    on(event: "error", listener: (event: EventObjectMeetingError) => void): this
    on(event: "camera-error", listener: (event: EventObjectCameraError) => void): this
    on(event: "speaker-changed", listener: (event: EventObjectSpeakerChanged) => void): this
    on(event: "screen-sharing", listener: (event?: string) => void): this
    on(event: "participant-left", listener: (event: Participant) => void): this
    on(event: "participant-joined", listener: (event: Participant) => void): this
    on(event: "participant-updated", listener: (event: Participant) => void): this
    on(event: "active-speaker-change", listener: (event: string) => void): this
    on(event: "audio-track-added", listener: (event: MediaStreamTrack) => void): this
    on(event: "audio-track-removed", listener: (event: MediaStreamTrack) => void): this
}

export class Room extends EventEmitter implements Room {
    private user: any;
    private state: string;
    screen_sharing?: string;
    private room: DailyCall;
    private speaker?: string;
    active_participants: string[];
    audio_tracks: MediaStreamTrack[];
    spotlighted_participants: string[];
    participants: { [key: string]: Participant };
    private queue = new Map<string, NodeJS.Timeout>();

    constructor(options?: DailyCallOptions) {
        super();
        this.state = "new";
        this.audio_tracks = [];
        this.participants = {};
        this.active_participants = [];
        this.spotlighted_participants = [];
        this.room = DailyIframe.createCallObject(options);
        this.room = this.room.setBandwidth({ kbs: "NO_CAP" });
        this.speaker = this.room.getActiveSpeaker().peerId;
        this.room.on("error", this.error);
        this.room.on("left-meeting", this.leftMeeting);
        this.room.on("camera-error", this.cameraError);
        this.room.on("track-started", this.trackStarted);
        this.room.on("track-stopped", this.trackStopped);
        this.room.on("participant-left", this.participantLeft);
        this.room.on("participant-joined", this.participantJoined);
        this.room.on("participant-updated", this.participantUpdate);
        this.room.on("active-speaker-change", this.activeSpeakerChange);
    }

    private reset = () => {
        this.audio_tracks = [];
        this.participants = {};
        this.active_participants = [];
        this.screen_sharing = undefined;
        this.spotlighted_participants = [];
        this.queue.forEach(clearTimeout);
        this.queue = new Map<string, NodeJS.Timeout>();
    }

    join = (user: any, metingId?:string|null|undefined) => {
        this.user = user;
        if (this.state === "joined-meeting") {
            console.log("joining-meeting-again");
            this.state = "joining-meeting-again"
            this.emit("joining-meeting");
            this.room.leave();
        } else if (this.state === "leaving-meeting") {
            console.log("still leaving-meeting");
            setTimeout(this.join, 500);
        } else if (this.state !== "joining-meeting") {
            this.reset();
            this.emit("joining-meeting");
            this.state = "joining-meeting";
            meeting.get('rooms/join').then((data: any) => {
                console.log("printing the data object : ", data);
                data.participants.forEach((p: Participant) => this.participants[p.user_id] = p);
                return this.room.join({ token: data.token, url: data.url, activeSpeakerMode: true });
            }).then((participants) => {
                captureStatData.initialize(this.room, user, metingId);
                if (participants) Object.values(participants).forEach(this.updateParticipant);
                this.state = "joined-meeting";
                this.emit("joined-meeting", {
                    participants: this.participants,
                    screen_sharing: this.screen_sharing,
                    active_participants: this.active_participants,
                    spotlighted_participants: this.spotlighted_participants
                });
            }).catch((error) => {
                if (typeof error === "string") this.emit("error", { message: error });
                else this.emit("error", error);
            });
        }
    }

    leave = () => {
        if (this.state === "left-meeting") {
            this.emit("left-meeting");
        } else if (this.state === "joined-meeting") {
            this.state = "leaving-meeting";
            this.emit("leaving-meeting");
            captureStatData.stop();
            this.room.leave();
        }
    }

    setActiveSpeakerMode = (enabled: boolean) => {
        this.room.setActiveSpeakerMode(enabled)
    }

    setBandwidth = (bw: Bandwidth) => {
        this.room.setBandwidth(bw);
        return this;
    }

    setInputDevices = (device: InputDevices) => {
        return this.room.setInputDevicesAsync(device)
    }

    setLocalAudio = (enabled: boolean) => {
        this.room.setLocalAudio(enabled)
    }

    setLocalVideo = (enabled: boolean) => {
        this.room.setLocalVideo(enabled)
    }

    startScreenShare = (captureOptions?: DailyScreenCaptureOptions) => {
        this.room.startScreenShare(captureOptions)
    }

    stopScreenShare = () => {
        this.room.stopScreenShare()
    }

    addFakeParticipant = () => {
        this.room.addFakeParticipant()
    }

    scoreMeeting = (score: number) => {
        captureStatData.addUserScoreInS3Data(score);
    }

    updateParticipant = (participant: DailyParticipant) => {
        const userId = participant.user_id;
        if (this.participants[userId]) {
            this.participants = Object.assign({}, this.participants, {
                [userId]: Object.assign({}, this.participants[userId], participant)
            })
            this.subscribedToAudioTrack(participant);
            this.isScreenSharing(participant);
            if (!this.participants[userId].spotlighted) {
                this.active_participants.push(userId);
            } else {
                this.spotlighted_participants.push(userId);
            }
        }
    }

    subscribeVideo = (userId: string, setSubscribedTracks: DailyTrackSubscriptionOptions) => {
        if (this.state === "joined-meeting") {
            console.log("received subscribe request:", userId);
            const participant = this.participants[userId]
            if (!participant) return console.log("participant not found:", userId);
            if (participant.local) return console.log("participant is local:", userId);
            const timeout = this.queue.get(userId);
            if (timeout) {
                clearTimeout(timeout);
                console.log("removed previous unSubscribe request from queue:", userId);
            } else {
                const { session_id } = this.participants[userId]
                session_id && this.room.updateParticipant(session_id, { setSubscribedTracks });
                console.log("subscribe:", userId);
            }
        }
    }

    unsubscribeVideo = (userId: string, setSubscribedTracks: DailyTrackSubscriptionOptions) => {
        if (this.state === "joined-meeting") {
            console.log("received unSubscribe request:", userId);
            const participant = this.participants[userId]
            if (!participant) return console.log("participant not found:", userId);
            if (participant.local) return console.log("participant is local:", userId);
            this.queue.set(userId, setTimeout(() => {
                if (this.participants[userId]) {
                    const { session_id } = this.participants[userId]
                    session_id && this.room.updateParticipant(session_id, { setSubscribedTracks });
                    console.log("unSubscribe:", userId);
                }
            }, 5000));
            console.log("added unSubscribe request to queue:", userId);
        }
    }

    private isSubscribeToAudio = (participant: DailyParticipant) => {
        const subscribed = participant.tracks.audio.subscribed
        return (subscribed !== "staged" && subscribed)
    }

    private isSubscribeToScreenAudio = (participant: DailyParticipant) => {
        const subscribed = participant.tracks.screenAudio.subscribed
        return (subscribed !== "staged" && subscribed)
    }

    private error = (event?: DailyEventObjectFatalError) => {
        event && this.emit(event.action, { message: event.errorMsg });
        event && captureStatData.stop();
    }

    private cameraError = (event?: DailyEventObjectCameraError) => {
        event && this.emit(event.action, { errorMsg: event.errorMsg, error: event.error });
    }

    private leftMeeting = (event?: DailyEventObjectNoPayload) => {
        if (this.state !== "joining-meeting-again") {
            event && this.emit(event.action);
            this.state = "left-meeting";
            captureStatData.stop();
        } else {
            console.log("left previous room successfully");
            console.log("joining room again");
            captureStatData.stop();
            this.state = "new";
            this.join(this.user);
        }
    }

    private activeSpeakerChange = (event?: DailyEventObjectActiveSpeakerChange) => {
        if (event && event.activeSpeaker.peerId) {
            const participant = this.room.participants()[event.activeSpeaker.peerId];
            if (participant) {
                console.log(event.action, participant)
                const userId = participant.user_id;
                if (this.speaker !== userId) {
                    this.emit("speaker-changed", { prev: this.speaker, new: userId });
                    this.speaker = userId;
                }
            }
        }
    }

    private participantJoined = (event?: DailyEventObjectParticipant) => {
        if (event) {
            console.log(event.participant)
            if (event.participant.user_name.startsWith("Fake")) {
                const name = event.participant.user_name;
                const user_id = event.participant.user_id
                this.participants[user_id] = {
                    ...this.room.participants().local,
                    user_id: user_id,
                    first_name: name,
                    last_name: "",
                    type: "participant",
                    profile: "",
                    speaker: false,
                }
                this.emit(event.action, this.participants[user_id]);
            } else {
                this.emit(event.action, event.participant);
                this.updateParticipant(event.participant);
            }
        }
    }

    private participantLeft = (event?: DailyEventObjectParticipant) => {
        if (event) this.emit(event.action, event.participant);
    }

    private participantUpdate = (event?: DailyEventObjectParticipant) => {
        if (event) {
            this.isScreenSharing(event.participant);
            this.subscribedToAudioTrack(event.participant);
            this.emit("participant-updated", event.participant);
        }
    }

    private trackStarted = (event?: DailyEventObjectTrack) => {
        if (event && event.track.kind === "audio") {
            if (event.participant && event.participant.local) return
            this.emit("audio-track-added", event.track);
            this.audio_tracks.push(event.track)
        }
    }

    private trackStopped = (event?: DailyEventObjectTrack) => {
        if (event && event.track.kind === "audio") {
            if (event.participant && event.participant.local) return
            this.emit("audio-track-removed", event.track);
            this.audio_tracks = this.audio_tracks.filter((track) => track !== event.track);
        }
    }

    private subscribedToAudioTrack = (participant: DailyParticipant) => {
        if (!participant.local) {
            if (!this.isSubscribeToAudio(participant)) {
                this.room.updateParticipant(participant.session_id, {
                    setSubscribedTracks: { audio: true }
                });
            }
            if (!this.isSubscribeToScreenAudio(participant)) {
                this.room.updateParticipant(participant.session_id, {
                    setSubscribedTracks: { screenAudio: true }
                });
            }
        }
    }

    private isScreenSharing = (participant: DailyParticipant) => {
        const { screen, tracks, user_id } = participant;
        if ((screen || (tracks.screenVideo.state !== "off"))) {
            this.subscribeVideo(user_id, { screenVideo: true });
            this.emit("screen-sharing", user_id);
            this.screen_sharing = user_id;
        } else if (user_id === this.screen_sharing) {
            this.emit("screen-sharing", undefined);
            this.screen_sharing = undefined;
        }
    }
}

export default new Room({
    subscribeToTracksAutomatically: false,
    dailyConfig: {
        experimentalChromeVideoMuteLightOff: true,
    }
});