import { FC } from 'react';
import styles from './styles.module.css';
import TextInput from '../../../../text-input';
import Button from '../../../../button';
import useValidation from './useValidation';

interface Props {
    setForgotPassword(arg: boolean): void;
}

const ForgotPasswordForm: FC<Props> = ({ setForgotPassword }) => {
    const [values, isSubmitting, errors, handleChange, handleSubmit] = useValidation();

    return (
        <div className={styles.form}>
            <p className={styles.title}>Reset Password</p>
            {errors.message && <div className={styles.error_message}>{errors.message}</div>}
            <TextInput
                label="Email"
                type="email"
                name="email"
                value={values.email}
                error={errors.email}
                onChange={handleChange}
            />
            <button className={styles.return_button} onClick={() => setForgotPassword(false)}>Return?</button>
            <Button onClick={handleSubmit} loading={isSubmitting} disabled={errors.email}>Reset</Button>
        </div>
    )
}

export default ForgotPasswordForm;