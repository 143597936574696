import { FC } from 'react';
import styles from './styles.module.css';
import Button from '../../../../button';
import useValidation from './useValidation';
import TextInput from '../../../../text-input';

const VerifyForm: FC = () => {
    const [values, isSubmitting, errors, handleChange, handleSubmit, handelResendCode] = useValidation();

    return (
        <div className={styles.form}>
            <p className={styles.title}>Verify Account</p>
            {errors.message && <div className={styles.error_message}>{errors.message}</div>}
            <TextInput
                label="Code"
                type="number"
                name="code"
                value={values.code}
                error={errors.code}
                onChange={handleChange}
            />
            <button className={styles.forgot_password_button} onClick={handelResendCode}>Resend Code</button>
            <p>Thank you for registering! We have sent a verification link to your email.</p>
            <p>If you have not received the verification email, please check your “Spam” or “Bulk Email” folder. You can click the resend button below to have another email sent.</p>
            <Button onClick={handleSubmit} loading={isSubmitting}
                disabled={errors.first_name || errors.last_name || errors.email || errors.password || errors.tc}>
                Verify Account
            </Button>
        </div>
    )
}

export default VerifyForm;