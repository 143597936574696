import { FC, useState } from "react"
import styles from './styles.module.css'
import { RootState } from "../../../../../../index";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { domain } from '../../../../../config';
import { useRedirect } from "../../../../pages/redirect-page";
import _ from "lodash";

const Avatar: FC = () => {
    const history = useHistory();
    const redirect = useRedirect();
    const { org_id } = useParams<any>();

    const [show, toggle] = useState(false);
    const { profile_image, first_name } = useSelector(({ user }: RootState) => ({
        profile_image: user.profile,
        first_name: user.first_name
    }), _.isEqual);

    return (
        <div className={styles.avatar}>
            <img className={styles.profile_image} onClick={() => toggle(!show)} alt="" src={`${domain}/${profile_image}`} />
            {show && <div className={styles.profile_menu}>
                <p className={styles.name}>Hi, {first_name}</p>
                <button className={styles.button} onClick={() => history.push(`/${org_id}/profile`)}>Edit Profile</button>
                <button className={styles.button} onClick={() => history.push(`/${org_id}/community`)}>Community</button>
                <button className={styles.button} onClick={() => history.push(`/${org_id}/account`)}>Account settings</button>
                <button className={styles.button} onClick={() => redirect.signOut()}>Sign Out</button>
            </div>}
        </div>
    )
}

export default Avatar