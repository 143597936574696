import React from 'react';
import styles from './styles.module.css';
import GoBack from './go-back';
import Loading from '../../loading';
import daily from '@daily-co/daily-js';

function BrowserCheck({ children }: any) {
    const browser = daily.supportedBrowser();

    if (!browser.supported) {
        return (
            <Loading className={styles.loading} message={`${browser.name} is not currently supported. Please use Chrome or Firefox.`} stopLoading >
                <GoBack />
            </Loading>
        )
    } else {
        return children
    }
}

export default BrowserCheck;
