import { FC, useEffect, useState, HTMLAttributes, Fragment, useCallback } from 'react';
import styles from './styles.module.css';
import Notification from './notification';
import { RootState } from "../../../index";
import { useTransition } from 'react-spring';
import { useSelector } from 'react-redux';
import { IError } from '../../redux/reducers/error-reducer/types';
import _ from "lodash";

const ErrorNotifications: FC<HTMLAttributes<HTMLDivElement>> = ({ children }) => {
    const [items, setItems] = useState<IError[]>([]);
    const error = useSelector(({ error }: RootState) => error, _.isEqual);

    const transitions = useTransition(items, {
        keys: (item) => item.key,
        enter: { transform: "translateX(0%)" },
        from: { transform: "translateX(-100%)" },
        leave: [{ delay: 5000 }, { opacity: 0, transform: "translateX(-105%)" }],
        onRest: (result, ctrl, item) => setItems((state) => state.filter((i) => i.key !== item.key)),
    });


    useEffect(() => {
        if (error) setItems((state) => ([...state, error]))
    }, [error]);

    const handleRemoveItem = useCallback((key: number) => {
        setItems((state) => state.filter((i) => i.key !== key))
    }, [])

    return (
        <Fragment>
            <div className={styles.error_notifications}>
                {transitions((props, i) => {
                    return (
                        <Notification
                            key={i.key}
                            style={props}
                            error={i.error}
                            remove={() => handleRemoveItem(i.key)}
                        />
                    )
                })}
            </div>
            {children}
        </Fragment>
    )
}

export default ErrorNotifications;