import room, { Participant } from "../../../utility/room"
import { IMeeting, IMeetingActions, SPEAKER_CHANGED, } from './types';
import { JOINED_MEETING, SCREEN_SHARING } from './types';
import { HAND_RAISED, LOWERED_HAND, USER_SPOTLIGHTED } from './types';
import { LOWER_HAND_FULFILLED, RAISED_HAND_FULFILLED } from './types';
import { PARTICIPANT_JOINED, PARTICIPANT_LEFT, PARTICIPANT_UPDATE } from './types';
import { FETCH_MEETING_ROOM_FULFILLED, FETCH_MEETING_ROOM_REJECTED } from './types';
import { UPDATE_BREAKOUT_ROOM_FULFILLED, BREAKOUT_ROOM_UPDATE, EVENT_UPDATED, NEW_USER_ADDED } from './types';
import { UPDATE_EVENT_STATUS_FULFILLED, UPDATE_EVENT_STATUS_PENDING, UPDATE_EVENT_STATUS_REJECTED, } from './types';
import { CREATE_BREAKOUT_ROOM_FULFILLED, CREATE_BREAKOUT_ROOM_PENDING, CREATE_BREAKOUT_ROOM_REJECTED } from './types';
import { DELETE_BREAKOUT_ROOM_FULFILLED, DELETE_BREAKOUT_ROOM_PENDING, DELETE_BREAKOUT_ROOM_REJECTED } from './types';

const initialState: IMeeting = {
    room: room,
    loading: true,
    participants: {},
    raised_hand: false,
    role: "participant",
    active_participants: [],
    spotlighted_participants: [],
}

const meeting = (state: IMeeting = initialState, action: IMeetingActions): IMeeting => {
    switch (action.type) {
        case FETCH_MEETING_ROOM_FULFILLED: {
            const { user, event } = action.payload
            return Object.assign({}, state, user, event, { loading: false });
        } case FETCH_MEETING_ROOM_REJECTED: {
            return Object.assign({}, state, { loading: false, error: action.payload.message });
        } case JOINED_MEETING: {
            return Object.assign({}, state, action.payload);
        } case PARTICIPANT_JOINED: {
            const userId = action.payload.user_id
            return Object.assign({}, state, updateParticipant(state, userId, action.payload));
        } case PARTICIPANT_LEFT: {
            const userId = action.payload.user_id
            return Object.assign({}, state, updateParticipant(state, userId, action.payload, true));
        } case PARTICIPANT_UPDATE: {
            const userId = action.payload.user_id
            return Object.assign({}, state, {
                participants: updatedParticipants(state, userId, action.payload)
            });
        } case SCREEN_SHARING: {
            return Object.assign({}, state, getSpotlightedParticipants(state, action.payload));
        } case SPEAKER_CHANGED: {
            const userId = action.payload.new;
            const prevSpeaker = action.payload.prev;
            let participants = updatedParticipants(state, userId, { speaker: true })
            if (prevSpeaker) {
                participants = updatedParticipants(state, prevSpeaker, { speaker: false })
            }
            return Object.assign({}, state, {
                participants: participants,
                active_participants: sort(state.active_participants, participants, state.user_id)
            });
        } case RAISED_HAND_FULFILLED: {
            return Object.assign({}, state, { raised_hand: true })
        } case LOWER_HAND_FULFILLED: {
            return Object.assign({}, state, { raised_hand: false })
        } case UPDATE_EVENT_STATUS_PENDING: {
            return Object.assign({}, state, { updating_event: true });
        } case UPDATE_EVENT_STATUS_FULFILLED: {
            return Object.assign({}, state, { updating_event: false });
        } case UPDATE_EVENT_STATUS_REJECTED: {
            return Object.assign({}, state, { updating_event: false });
        } case CREATE_BREAKOUT_ROOM_PENDING: {
            return Object.assign({}, state, { updating_breakout_rooms: true })
        } case CREATE_BREAKOUT_ROOM_FULFILLED: {
            return Object.assign({}, state, {
                updating_breakout_rooms: false,
                breakout_rooms: action.payload
            })
        } case CREATE_BREAKOUT_ROOM_REJECTED: {
            return Object.assign({}, state, { updating_breakout_rooms: false })
        } case UPDATE_BREAKOUT_ROOM_FULFILLED: {
            return Object.assign({}, state, { breakout_rooms: action.payload });
        } case DELETE_BREAKOUT_ROOM_PENDING: {
            return Object.assign({}, state, { updating_breakout_rooms: true })
        } case DELETE_BREAKOUT_ROOM_FULFILLED: {
            return Object.assign({}, state, {
                breakout_rooms: undefined,
                updating_breakout_rooms: false
            })
        } case DELETE_BREAKOUT_ROOM_REJECTED: {
            return Object.assign({}, state, { updating_breakout_rooms: false })
        } case HAND_RAISED: {
            const userId = action.payload.user_id;
            const participants = updatedParticipants(state, userId, { raised_hand: true })
            return Object.assign({}, state, {
                participants,
                raised_hand: userId === state.user_id ? true : state.raised_hand,
                active_participants: sort(state.active_participants, participants, state.user_id)
            });
        } case LOWERED_HAND: {
            const userId = action.payload;
            const participants = updatedParticipants(state, userId, { raised_hand: false })
            return Object.assign({}, state, {
                participants,
                raised_hand: userId === state.user_id ? false : state.raised_hand,
                active_participants: sort(state.active_participants, participants, state.user_id)
            });
        } case USER_SPOTLIGHTED: {
            const userId = action.payload.user_id
            if (!state.screen_sharing && state.participants[userId].session_id) {
                return Object.assign({}, state, updateParticipant(state, userId, action.payload));
            } else {
                return Object.assign({}, state, {
                    participants: updatedParticipants(state, userId, action.payload)
                });
            }
        } case NEW_USER_ADDED: {
            const userId = action.payload.user_id;
            state.room.participants[userId] = action.payload;
            return Object.assign({}, state, {
                participants: updatedParticipants(state, userId, action.payload)
            });
        } case EVENT_UPDATED: {
            return Object.assign({}, state, action.payload);
        } case BREAKOUT_ROOM_UPDATE: {
            return Object.assign({}, state, { breakout_room_id: action.payload });
        } default: {
            return state;
        }
    }

}


// at the beginning        |
// on rained/lowbred hand -|
// active speaker        - | and not spotlighted
// participant joined     -|
// when not spotlighted  - |

const sort = (users: string[], participants: IMeeting["participants"], user?: string) => {
    console.log("sort")
    const self: string[] = []
    const rest: string[] = []
    const guests: string[] = []
    const speakers: string[] = []
    const moderators: string[] = []
    const raisedHands: string[] = []

    users.forEach((userId) => {
        const participant = participants[userId]
        if (user && user === userId) return self.push(userId)
        if (participant.speaker) return speakers.push(userId)
        if (participant.raised_hand) return raisedHands.push(userId)
        if (participant.type === "guest") return guests.push(userId)
        if (participant.type === "moderator") return moderators.push(userId)
        return rest.push(userId)
    })
    return [...moderators, ...self, ...speakers, ...raisedHands, ...guests, ...rest]
}

const getSpotlightedParticipants = (state: IMeeting, screenSharing?: string) => {
    const participants = state.participants;
    let activeParticipants: string[] = [];
    let spotlightedParticipants: string[] = [];

    if (screenSharing) {
        activeParticipants = [...state.active_participants, ...state.spotlighted_participants];
    } else {
        state.active_participants.forEach((userId) => {
            if (!participants[userId].spotlighted) activeParticipants.push(userId)
            else spotlightedParticipants.push(userId)
        });
    }
    return {
        screen_sharing: screenSharing,
        spotlighted_participants: spotlightedParticipants,
        active_participants: sort(activeParticipants, participants, state.user_id),
    }
}

const updatedParticipants = (state: IMeeting, userId: string, payload: Partial<Participant>) => {
    if (state.participants[userId]) {
        return Object.assign({}, state.participants, {
            [userId]: Object.assign({}, state.participants[userId], payload)
        })
    } else {
        return Object.assign({}, state.participants, {
            [userId]: payload
        })
    }
}

const updateParticipant = (state: IMeeting, userId: string, payload: Partial<Participant>, left?: boolean) => {
    const participants = updatedParticipants(state, userId, payload);
    let spotlightedParticipants = [...state.spotlighted_participants];
    let activeParticipants = [...state.active_participants];
    const participant = participants[userId];

    if (left) {
        if (participant.spotlighted) {
            spotlightedParticipants = spotlightedParticipants.filter((user) => user !== userId)
        } else {
            activeParticipants = activeParticipants.filter((user) => user !== userId)
        }
    } else {
        if (participant.spotlighted) {
            spotlightedParticipants.push(userId)
            activeParticipants = activeParticipants.filter((user) => user !== userId)
        } else {
            activeParticipants.push(userId)
            activeParticipants = sort(activeParticipants, participants, state.user_id)
            spotlightedParticipants = spotlightedParticipants.filter((user) => user !== userId)
        }
    }

    return {
        participants,
        active_participants: [...activeParticipants],
        spotlighted_participants: [...spotlightedParticipants],
    }
}

export default meeting;