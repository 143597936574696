import { memo, FC } from 'react';
import moment from 'moment';
import styles from './styles.module.css';
import Linkify from 'linkify-react';
import Icon from "../../../../../icon";
import { domain } from '../../../../../../config';
import { IMessage } from "../../../../../../redux/reducers/messages-reducer/types";

interface Props extends IMessage {
    key: any
    resendMessage?: () => void
    reference?: React.MutableRefObject<HTMLDivElement | null>
}

const Message: FC<Props> = ({ reference, is_user, message, name, time_stamp, profile, status, resendMessage }) => {
    return (
        <div className={`${styles.message} ${styles[is_user ? "is_user" : ""]}`} ref={reference}>
            {console.log("Message")}
            <img className={styles.avatar} src={`${domain}/${profile}`} alt='' />
            <div className={styles.bubble}>
                <div className={`${styles.name}`}>{name}</div>
                <div className={styles.content}>
                    <Linkify options={{ target: "_blank" }}>{message}</Linkify>
                </div>
                <p className={`${styles.time}`}>{moment(time_stamp).format("LT")}</p>
                {status && <Icon className={styles.status} icon={status} />}
            </div>
            {resendMessage && <button className={styles.try_again} onClick={resendMessage}>
                Try again <Icon className={styles.status} icon="try-again" />
            </button>}
        </div>
    )
}

export default memo(Message);