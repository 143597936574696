import { FC } from 'react';
import styles from './styles.module.css';
import SignInForm from './signin-form';
import SignUpForm from './signup-form';
import VerifyForm from './verify-form';
import ResetPasswordForm from './reset-password-form';
import { useHistory } from 'react-router-dom';

interface Props {
    type: string | null
}

const Panel: FC<Props> = ({ type }) => {
    const history = useHistory();

    return (
        <div className={styles.panel}>
            {type === 'signup' ? <SignUpForm /> : type === 'verify' ? <VerifyForm /> : type === 'reset' ? <ResetPasswordForm /> : <SignInForm />}
            <p className={styles.switch_text}>
                {type === 'signup' ? 'Already a member? ' : 'Not a member? '}
                <button className={styles.switch_button} onClick={() => history.push({ search: `type=${type === 'signup' ? 'signin' : 'signup'}` })}>{type === 'signup' ? 'Sign In' : 'Sign Up'}</button>
            </p>
            <div className={styles.spacer}></div>
        </div>
    )
}

export default Panel;