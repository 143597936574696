import { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory, useParams } from 'react-router-dom';

const useValidation = () => {
    const history = useHistory();
    const { org_id } = useParams<any>();
    const [errors, setErrors] = useState<any>({});
    const [isSubmitting, setSubmitting] = useState(false);
    const [values, setValues] = useState({ email: "", code: "", password: "" });

    const handleChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
        setValues((prevState) => ({ ...prevState, [name]: value }));
        if (errors[name]) {
            setErrors((prevState: any) => {
                delete prevState[name];
                return prevState;
            });
        }
    }

    const validate = () => {
        const errors = {} as any
        if (!values.code) errors.code = "Code is required."
        if (!values.email) errors.email = "Email is required."
        if (!values.password) errors.password = "Password is required."
        return errors;
    }

    const handleSubmit = () => {
        setErrors({});
        setSubmitting(true);
        const errors = validate();
        if (Object.values(errors).length) {
            setErrors(errors);
            setSubmitting(false);
        } else {
            Auth.forgotPasswordSubmit(values.email, values.code, values.password).then((data) => {
                console.log(data);
                history.push(`/${org_id}/authentication`);
            }).catch((err: any) => {
                console.log(JSON.stringify(err))
                setSubmitting(false);
                setErrors({ message: err.message || "Sorry an Error Occurred. Please Try Again." });
            });
        }
    }

    return [values, isSubmitting, errors, handleChange, handleSubmit]
}

export default useValidation;