import { FC } from "react";
import styles from './styles.module.css';
import { domain } from '../../../../config';

interface Props {
    thumbnail: string
    heading: string
    description: string
}

const AltHero: FC<Props> = ({ heading, description, thumbnail }) => {
    return (
        <div className={styles.alt_hero}>
            <img className={styles.thumbnail} alt="" src={`${domain}/${thumbnail}`} />
            <div className={styles.details}>
                <p className={styles.title}>{heading}</p>
                <p className={styles.body}>{description}</p>
            </div>
        </div>
    )
}

export default AltHero;