import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { RootState } from '../../index';
import { useDispatch, useSelector } from 'react-redux';
import { participantUpdate, participantJoined, participantLeft, speakerChanged } from '../redux/action/meeting';
import { devicePermission, joinedMeeting, screenSharing } from '../redux/action/meeting';
import room, { Participant, EventObjectCameraError, EventObjectJoinedMeeting, EventObjectMeetingError, EventObjectSpeakerChanged } from '../utility/room';
import _ from "lodash";

const useMeetingRoom = () => {
    const dispatch = useDispatch();
    const { meeting_id } = useParams<any>();
    const [state, setState] = useState<any>({ joining: true });
    const { user, breakout_room_id } = useSelector(({ meeting, user }: RootState) => {
        return {
            breakout_room_id: meeting.breakout_room_id,
            user: {
                email: user.email,
                userId: user.user_id,
                lastName: user.last_name,
                firstName: user.first_name,
            },
        }
    }, _.isEqual);

    useEffect(() =>{
        let paramTestId = null;
        if(meeting_id !== null && meeting_id !== undefined){
            paramTestId = meeting_id;
            }
        room.join(user, paramTestId)
    } , [breakout_room_id, user, meeting_id]);

    // setting up the room
    useEffect(() => {
        const handleError = (event: EventObjectMeetingError) => {
            console.log("meeting-error", event);
            setState({ error: event.message });
        }
        const handleJoiningMeeting = () => {
            console.log("joining-meeting");
            setState({ joining: true });
        }
        const handleJoinedMeeting = (event: EventObjectJoinedMeeting) => {
            console.log("joined-meeting", event);
            dispatch(joinedMeeting(event));
            setState({ joining: false });
        }
        const handleLeftMeeting = () => {
            console.log("left-meeting");
            setState((prev: any) => ({ error: prev.error, left: true }));
        }
        const handleScreenSharing = (userId?: string) => {
            console.log("screen-sharing", userId);
            dispatch(screenSharing(userId));
        }
        const handleDeviceError = (event: EventObjectCameraError) => {
            console.log("", event);
            dispatch(devicePermission(event));
        }
        const handleParticipantJoined = (participant: Participant) => {
            console.log("participant-joined", participant);
            dispatch(participantJoined(participant));
        }
        const handleParticipantLeft = (participant: Participant) => {
            console.log("participant-left", participant);
            dispatch(participantLeft(participant));
        }
        const handleParticipantUpdate = (participant: Participant) => {
            console.log("participant-updated", participant);
            dispatch(participantUpdate(participant));
        }
        const handleSpeakerChanged = (event: EventObjectSpeakerChanged) => {
            dispatch(speakerChanged(event));
        }

        room.on("error", handleError);
        room.on("camera-error", handleDeviceError);
        room.on("left-meeting", handleLeftMeeting);
        room.on("screen-sharing", handleScreenSharing);
        room.on("joined-meeting", handleJoinedMeeting);
        room.on("speaker-changed", handleSpeakerChanged);
        room.on("joining-meeting", handleJoiningMeeting);
        room.on("participant-left", handleParticipantLeft);
        room.on("participant-joined", handleParticipantJoined);
        room.on("participant-updated", handleParticipantUpdate);
        console.log("MeetingRooms mounted");
        return () => {
            // remove all event listeners
            room.leave();
            room.off("error", handleError);
            room.off("camera-error", handleDeviceError);
            room.off("left-meeting", handleLeftMeeting);
            room.off("screen-sharing", handleScreenSharing);
            room.off("joined-meeting", handleJoinedMeeting);
            room.off("speaker-change", handleSpeakerChanged);
            room.off("joining-meeting", handleJoiningMeeting);
            room.off("participant-left", handleParticipantLeft);
            room.off("participant-joined", handleParticipantJoined);
            room.off("participant-updated", handleParticipantUpdate);
            console.log("MeetingRooms unmounted");
        }
    }, [dispatch]);

    return { ...state, isBreakoutRoom: !!breakout_room_id };
}

export default useMeetingRoom;