module.exports = {
    endpoint: process.env.REACT_APP_ENDPOINT || "https://pbitkihh61.execute-api.us-east-1.amazonaws.com/dev",
    sseURL: process.env.REACT_APP_SSE_URL || "https://api.sharedstudios.events",
    domain: process.env.REACT_APP_DOMAIN || "https://vcsp.sharedstudios.events",
    statsEnabled: true,
    statsConfig: {
        identityPoolId: "us-east-2:61c8015f-5c19-4d6f-8c0a-280fdf3b9835",
        webstatsBucketRegion: "us-east-2",
        webstatsBucketName: "ss-web-stats",
        intervalInSecWebStatsDataCollector: 15000,
        intervalInSecWebStatsDataUpload: 60000
    },
    aws_exports: {
        Auth: {
            region: process.env.REACT_APP_AWS_REGION || "us-east-1",
            userPoolId: process.env.REACT_APP_USER_POOL_ID || "us-east-1_cxdilsXQO",
            userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID || "36aitheimoqjvckf39erm7kl6k",
            oauth: {
                responseType: "code",
                scope: ["email", "profile", "openid"],
                redirectSignIn: `${process.env.REACT_APP_DOMAIN || "http://localhost:3000"}/redirect`,
                redirectSignOut: `${process.env.REACT_APP_DOMAIN || "http://localhost:3000"}/redirect`,
                domain: process.env.REACT_APP_USER_POOL_DOMAIN || "vcs-platform-dev.auth.us-east-1.amazoncognito.com",
            }
        }
    }
}