import { FC, Fragment, useEffect, useState } from 'react';
import styles from './styles.module.css';
import GoBack from './go-back';
import Loading from '../../loading';
import { useDispatch } from 'react-redux';
import { sseURL } from '../../../config';
import EventToken from '../../../utility/event-token';
import FlowerSpinner from './../../loading-animation/flower-spinner';

const ServerSentEvent: FC = ({ children }) => {
    const dispatch = useDispatch();
    const [connected, setConnect] = useState(false);
    const [socketErrorMessage, serErrorMessage] = useState<string | null>(null);

    useEffect(() => {
        const token = EventToken.isValidToken();
        if (!token) {
            serErrorMessage("Session expired. No token found!");
        } else {
            const eventSource = new EventSource(`${sseURL}/stream?authorization=${token}`);

            const onMessage = (e: MessageEvent<any>) => {
                const { payload, type } = JSON.parse(e.data)
                if (type === "ping") return console.log("ping");
                return dispatch({ type, payload });
            }
            const onOpen = (e: Event) => {
                setConnect(true);
            }
            const onError = (e: Event) => {
                serErrorMessage("Having trouble connecting. Please refresh the page.");
            }

            eventSource.addEventListener('open', onOpen);
            eventSource.addEventListener('error', onError);
            eventSource.addEventListener('message', onMessage);
            return () => {
                eventSource.removeEventListener('open', onOpen);
                eventSource.removeEventListener('error', onError);
                eventSource.removeEventListener('message', onMessage);
                eventSource.close();
            }
        }
    }, [dispatch]);

    if (!connected) {
        return <Loading className={styles.loading} message="Connecting to event. Please wait." spinner={FlowerSpinner} />
    } else if (socketErrorMessage) {
        return (
            <Loading className={styles.loading} message={socketErrorMessage} stopLoading >
                <GoBack />
            </Loading>
        )
    } else {
        return <Fragment>{children}</Fragment>
    }
}

export default ServerSentEvent;
