import { memo, forwardRef, HTMLAttributes } from 'react';
import styles from './styles.module.css';
import Linkify from 'linkify-react';
import { animated } from 'react-spring';
import { domain } from '../../../../../../config';
import { IMessage } from "../../../../../../redux/reducers/messages-reducer/types";

interface Props extends HTMLAttributes<HTMLDivElement>, IMessage {
    style: any
}

const Notification = forwardRef<HTMLDivElement, Props>(({ style, message, name, profile }, ref) => {
    return (
        <animated.div style={style} className={styles.notification}>
            <img className={styles.avatar} src={`${domain}/${profile}`} alt='' />
            <div className={styles.bubble} ref={ref}>
                <div className={styles.name}>{name}</div>
                <div className={styles.content}>
                    <Linkify options={{ target: "_blank" }}>{message}</Linkify>
                </div>
            </div>
        </animated.div>
    )
});

export default memo(Notification);