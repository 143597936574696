import { useLocation } from 'react-router-dom';

const useQuery = () => {
    const query: any = {};
    const location = useLocation();
    const url = new URLSearchParams(location.search);
    url.forEach((value, key) => query[key] = value);
    return query;
}

export default useQuery;