import { FC, useEffect, useState } from 'react';
import styles from './styles.module.css';
import Input from "./input";
import Icon from "../../../icon";
import Messages from "./messages";
import Notification from "./notifications";

const ParticipantChat: FC = () => {
    const [show, toggle] = useState(true);
    const [clicked, setClicked] = useState(false);

    useEffect(() => {
        if (!clicked) {
            const timeout = setTimeout(() => toggle(false), 3000);
            return () => clearTimeout(timeout);
        }
    }, [clicked]);

    return (
        <div className={styles.participant_chat} onClick={() => setClicked(true)}>
            <div className={`${styles.container} ${!show && styles.hide}`}>
                <button className={styles.toggle} onClick={() => toggle(!show)}>
                    <Icon className={styles.icon} icon="arrow" />
                </button>
                <div className={styles.chat}>
                    {show && <Messages />}
                    {show && <Input />}
                </div>
            </div>
            {!show && <Notification show={() => toggle(true)} />}
        </div>
    )
}

export default ParticipantChat;