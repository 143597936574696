import { FC } from 'react';
import ControlButton from './control-button';
import { RootState } from '../../../../../../index';
import { useDispatch, useSelector, } from 'react-redux';
import { raiseHand, lowerHand } from '../../../../../redux/action/meeting';
import _ from "lodash";

const RaiseHandButton: FC = () => {
    const dispatch = useDispatch();
    const raisedHand = useSelector(({ meeting }: RootState) => meeting.raised_hand, _.isEqual);

    const handleRaiseHand = () => {
        if (raisedHand) dispatch(lowerHand())
        if (!raisedHand) dispatch(raiseHand())
    }

    return <ControlButton active={raisedHand} icon="raise-hand" onClick={handleRaiseHand} />
}

export default RaiseHandButton;