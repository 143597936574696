import { HAND_RAISED, LOWERED_HAND } from '../meeting-reducer/types';
import { IActions, IRaisedHand } from './types';


const raisedHand = (state: IRaisedHand[] = [], action: IActions): IRaisedHand[] => {
    switch (action.type) {
        case HAND_RAISED: {
            return [...state, action.payload];
        } case LOWERED_HAND: {
            return state.filter(({ user_id }) => user_id !== action.payload);
        } default: {
            return state;
        }
    }
}

export default raisedHand;