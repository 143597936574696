import { FC, memo } from 'react';
import styles from './styles.module.css';
import Icon from '../../../../../icon';
import { IRaisedHand } from '../../../../../../redux/reducers/raised-hands-reducer/types';

const RaiseHandItem: FC<IRaisedHand> = ({ name }) => {
    return (
        <div className={styles.raised_hand}>
            <div className={styles.raise_hand_icon}>
                <Icon className={styles.icon} icon="raise-hand" />
            </div>
            <p className={styles.name}>{name}</p>
        </div>
    );
};

export default memo(RaiseHandItem);