import { FC, useState } from "react";
import styles from './styles.module.css';
import axios_s3 from 'axios';
import Icon from '../../../icon';
import Modal from '../../../modal';
import Button from '../../../button';
import axios from '../../../../axios';
import FileSelector from "./file-selector";
import ImageCropper from "./image-cropper";
import { domain } from '../../../../config';
import { useDispatch } from 'react-redux';
import { PROFILE_UPDATED } from "../../../../redux/reducers/user-reducer/types";

interface Props {
    profile_image?: string
    onClose: () => void
}

interface state {
    file: any,
    blob: any,
    blob_url: any,
    profileUploadPercent: number,
    error: any
}

const UploadPhotoModal: FC<Props> = ({ profile_image, onClose }) => {
    const dispatch = useDispatch();
    const [state, setState] = useState<state>({ file: null, blob: null, blob_url: null, profileUploadPercent: 0, error: null });

    const handleDelete = () => {
        setState({ file: null, blob: null, blob_url: null, profileUploadPercent: 0, error: null });
    }

    const handleClose = () => {
        setState((prevState) => ({ ...prevState, file: null }))
    };

    const onCrop = (blob: Blob) => {
        setState((prevState) => ({ ...prevState, blob, blob_url: URL.createObjectURL(blob), file: null }))
    };

    const handleUpload = () => {
        setState((prevState) => ({ ...prevState, error: null }))
        axios.put("users/picture").then((data: any) => {
            const file = state.blob;
            const formData = new FormData();
            const fields = { ...data.fields, 'Content-Type': file.type, file };
            Object.entries(fields).forEach(([key, value]) => formData.append(key, value as string));
            return axios_s3.post(data.url, formData, { headers: { "Content-Type": "multipart/form-data" } });
        }).then(() => {
            onClose()
            dispatch({ type: PROFILE_UPDATED });
        }).catch((error) => {
            setState((prevState) => ({ ...prevState, error: error.message }))
        });
    }

    // const handleServerDelete = () => { }

    return (
        <Modal className={styles.modal}>
            <div className={styles.upload_photo}>
                <button className={styles.close_button} onClick={onClose}>
                    <Icon className={styles.close_icon} icon="close" />
                </button>
                <p className={styles.title}>Update Profile Picture</p>
                {state.error && <div className={styles.error_message}>{state.error}</div>}
                <div className={styles.image_edit}>
                    <img className={styles.profile_image} alt="" src={state.blob_url || `${domain}/${profile_image}`} />
                    <FileSelector {...{ setState }} />
                </div>
                {state.profileUploadPercent > 0 && <div className={styles.progress_bar_holder}><div style={{ width: '' + state.profileUploadPercent + '%' }} className={styles.progress_bar} /></div>}
                {state.file && <ImageCropper {...{ onCrop, handleClose, handleDelete, file: state.file }} />}
                <div className={styles.buttons}>
                    {/* {(profile_image && !state.blob) && <Button onClick={handleServerDelete}>Delete Current Picture</Button>} */}
                    {state.blob && <Button onClick={handleUpload}>Upload Picture</Button>}
                </div>
            </div>
        </Modal>
    )
}

export default UploadPhotoModal;