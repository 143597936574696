import AWS from 'aws-sdk';
import { statsEnabled, statsConfig } from '../config';

let statsArr: any = [];

let timestampPrevUpload: any;
let timestampPrevDown: any;
let bytesPrevUpload: any;
let bitrateUpload: number=0;
let bytesPrevDown: any;
let bitrateDownload: number=0;
let intervalDataCollectorObject: any = undefined;
let intervalCollectorPacketLostObj: any = undefined;
let intervalDataUpload: any = undefined;

let dataObjectAudio: any={};
let dataObjectVideo: any={};

let lossForAudio: number[] = [];
let lossForVideo: number[] = [];

let packetLostAudioPercentage: number = 0;
let packetLostVideoPercentage: number = 0;

let userScore: number = 0;

let roomConfigGlobal: any = {}
let gUser: any;
let gTestId: string | null | undefined;
let gS3: any;
interface PacketsData {
    packetLostAudioPercentage: number,
    packetLostVideoPercentage: number

}
interface JitterData {
    jitter: 0
}

interface WebStat {
    timestamp: number,
	uploadSpeed: string ,//transport
	downSpeed: string, //transport
    jitterData: JitterData[],
    packetLostData: PacketsData,
    currentRoundTripTime: number, //candidate-pair
    totalRoundTripTime: number, //candidate-pair,
    userScore?: number
}

const CaptureStatData = {

    /**
     * Call to the getStats API by using the room object and push the webstats JSON Array to the s3 bucket
     * @param room Room, current room object of dailyco-video's Room
     * @param user firstName, lastName, userId
     */
    initialize: (room: any, user: any, testId: string | null | undefined) => {
        if(statsEnabled){
            // configure S3
            AWS.config.update({
                signatureVersion: 'v4',
                region: statsConfig.webstatsBucketRegion,
                credentials: new AWS.CognitoIdentityCredentials({
                    IdentityPoolId: statsConfig.identityPoolId
                })
            });

            const s3 = new AWS.S3({
                apiVersion: '2006-03-01',
                params: {Bucket: statsConfig.webstatsBucketName}
            });
            gS3 = s3;
            
            room.room().then((roomConfig: any) => {
                roomConfigGlobal = roomConfig;
                const timeNow = Date.now();
                let metadata = {
                    eventName: roomConfig.name,
                    eventId: roomConfig.id,
                    userName: user.email,
                    startTimestamp: timeNow,
                    product: "dailyco",
                    testId: (testId !== null && testId !== "" ? testId : "default")
                }
                gTestId = testId;
                gUser = user;
                //meta upload
                const file = new File([JSON.stringify(metadata)], "metadata.json");
                const fileName = "metadata.json";
                const filePath = 'stats/dailyco/'+roomConfig.name+'/'+ (testId !== null && testId !== "" ? testId +"/" : "default/") + user.email.replace("@", "-")  +'/'+ fileName;
    
                s3.upload({"Body": file, Key:filePath, ACL:"public-read", Bucket:statsConfig.webstatsBucketName}, function(err: any, data: any) {
                    if(err) {
                        console.log("Failed to upload metadata to s3")
                    }
                    else {
                        console.log("Successfully Uploaded metadata to s3")
                    }
                });
            })
            
            // added interval to fetch getStats API and capture data at interval
            intervalDataCollectorObject = setInterval(() => {
                try {
                    // @ts-ignore
                    if(rtcpeers !== undefined && rtcpeers !== null ){
                        // @ts-ignore
                        let keys = Object.keys(rtcpeers.peerToPeer.rtcPeerConnections);
                        if(keys !== undefined && keys !== null && keys.length > 0){
                            // @ts-ignore
                            if(rtcpeers.peerToPeer.rtcPeerConnections[keys[0]] !== undefined && rtcpeers.peerToPeer.rtcPeerConnections[keys[0]] !== null) {
                                // @ts-ignore
                                rtcpeers.peerToPeer.rtcPeerConnections[keys[0]].getStats(null).then(CaptureStatData.showRemoteStats, (err: any) => console.log(err))
                            }
                        }
                    }
                }
                catch(error){ }
            }, statsConfig.intervalInSecWebStatsDataCollector);
            

            // Added this so we could get the average of the packet lost of video and audio.
            // had to perform all the calculation here because the data coming from WEBRTC is not consistant when the user turns on/off the video/audio 
            intervalCollectorPacketLostObj = setInterval(() => {
                try {
                    // @ts-ignore
                    let keys = Object.keys(rtcpeers.peerToPeer.rtcPeerConnections);
                    if(keys !== undefined && keys !== null && keys.length > 0){
                        // @ts-ignore
                        if(rtcpeers.peerToPeer.rtcPeerConnections[keys[0]] !== undefined && rtcpeers.peerToPeer.rtcPeerConnections[keys[0]] !== null) {
                            // @ts-ignore
                            rtcpeers.peerToPeer.rtcPeerConnections[keys[0]].getStats(null).then(CaptureStatData.getPacketLoss, (err: any) => console.log(err))
                        }
                    }
                }
                catch(error) { }
            }, 1000);

            // Upload to S3
            intervalDataUpload = setInterval(() => {
                CaptureStatData.pushStatsToS3();
            }, statsConfig.intervalInSecWebStatsDataUpload);
        }
    },

    pushStatsToS3: () => {
        if (statsArr.length <= 0) {
            return;
        }
        const currentTime = Date.now();
        const file = new File([JSON.stringify(statsArr)], currentTime+".txt");
        const fileName = currentTime+".txt";
        const filePath = 'stats/dailyco/'+roomConfigGlobal.name+'/'+ (gTestId !== null && gTestId !== "" ? gTestId +"/" : "default/") + gUser.email.replace("@", "-") +'/'+ fileName;

        gS3.upload({"Body": file, Key:filePath, ACL:"public-read", Bucket:statsConfig.webstatsBucketName}, function(err: any, data:any) {
            if(err) {
                console.log("failed to upload webstats data to s3")
            }
            else {
                console.log("Successfully Uploaded webstats data to s3")
            }
        });
        statsArr = [];
    },

    /**
     * Capture and perform calculations for every iteration of data from the getStats API call
     * @param results Array of objects returend from getStats API
     */
    showRemoteStats: (results: any) => {
        const statObj: WebStat = {
            timestamp: 0,
            uploadSpeed: "", //transport
            downSpeed: "", //transport
            jitterData: [], //inbound-rtp - the less the number the better the quality - calculated by average of 
            packetLostData: {
                packetLostAudioPercentage: -1,
                packetLostVideoPercentage: -1
            } as PacketsData,  //inbound-rtp
            currentRoundTripTime: 0, //candidate-pair
            totalRoundTripTime: 0, //candidate-pair
        }
            
        results.forEach((report: any) => {
            statObj.timestamp = report.timestamp;
            if (report.type  ===  'transport') {
                // download speed
                let now = report.timestamp;
                let bytes = report.bytesReceived;
                if (timestampPrevDown) {
                    bitrateDownload = 8 * (bytes - bytesPrevDown) / (now - timestampPrevDown);
                    bitrateDownload = Math.floor(bitrateDownload);
                    
                }
                bytesPrevDown = bytes;
                timestampPrevDown = now;
                if (bitrateDownload && isFinite(bitrateDownload) && bitrateDownload>=0) {
                    statObj.downSpeed = (Math.round(bitrateDownload * 100) / 100).toFixed(2)+ " kbps";
                }

                // upload speed
                now = report.timestamp;
                bytes = report.bytesSent;
                if (timestampPrevUpload) {
                    bitrateUpload = 8 * (bytes - bytesPrevUpload) / (now - timestampPrevUpload);
                    bitrateUpload =Math.floor(bitrateUpload);
                }
                bytesPrevUpload = bytes;
                timestampPrevUpload = now;
                if (bitrateUpload && isFinite(bitrateUpload) && bitrateUpload >= 0 ) {
                    statObj.uploadSpeed =(Math.round(bitrateUpload * 100) / 100).toFixed(2)+ " kbps";
                }
            }
            
            if (report.type  ===  'inbound-rtp') {
                if(statObj.jitterData !== null && statObj.jitterData  !==  undefined && report.hasOwnProperty("jitter") && report.jitter) {
                    statObj.jitterData.push({jitter: report.jitter});
                }
                if(statObj.packetLostData !== null && statObj.packetLostData !== undefined 
                    && statObj.packetLostData.packetLostAudioPercentage === -1 && statObj.packetLostData.packetLostVideoPercentage === -1){
                    statObj.packetLostData = {
                        packetLostAudioPercentage: packetLostAudioPercentage ? packetLostAudioPercentage : 0,
                        packetLostVideoPercentage: packetLostVideoPercentage ? packetLostVideoPercentage : 0
                    }
                }
            }
            if (report.type  ===  'candidate-pair') {
                if(statObj.currentRoundTripTime !== null && statObj.currentRoundTripTime  !==  undefined){
                    statObj.currentRoundTripTime = report.currentRoundTripTime;
                }
                if(statObj.totalRoundTripTime !== null && statObj.totalRoundTripTime  !==  undefined){
                    statObj.totalRoundTripTime =report.totalRoundTripTime;
                }
            }
        });
        statsArr.push(statObj);
        //console.log("statsArr: ",statsArr);
    },

    /**
     * Get expected packet loss after all processing
     * @param results getstats results json array 
     */
    getPacketLoss:  (results: any) => {
         results.forEach( (report: any,)=> {
            if (report.type === 'inbound-rtp') {
                dataObjectAudio["packetLoss"+report.ssrc]=[0, 0, 0];
                dataObjectVideo["packetLoss"+report.ssrc]=[0, 0, 0];
                
                if (report.kind==="audio" && report.packetsReceived === 0) {
                    dataObjectAudio["packetLoss"+report.ssrc]=[report.packetsLost, report.packetsReceived,report.packetsDiscarded];
                    lossForAudio.push(CaptureStatData.lossPercentage(report.packetsLost, report.packetsReceived,report.packetsDiscarded));
                }
                if (report.kind==="audio" && report.packetsReceived !== 0) {
                    lossForAudio.push( CaptureStatData.lossPercentage(report.packetsLost, report.packetsReceived, report.packetsDiscarded, dataObjectAudio["packetLoss" + report.ssrc][0] , dataObjectAudio["packetLoss" + report.ssrc][1],dataObjectAudio["packetLoss" + report.ssrc][2]));
                    dataObjectAudio["packetLoss"+report.ssrc]=[report.packetsLost, report.packetsReceived,report.packetsDiscarded];
                }
                if (report.kind === "video" && report.packetsReceived === 0) {
                    dataObjectVideo["packetLoss"+report.ssrc]=[report.packetsLost, report.packetsReceived,report.packetsDiscarded];
                    lossForVideo.push(CaptureStatData.lossPercentage(report.packetsLost, report.packetsReceived, report.packetsDiscarded));
                }
                if (report.kind === "video" && report.packetsReceived !== 0) {
                    lossForVideo.push( CaptureStatData.lossPercentage(report.packetsLost, report.packetsReceived, report.packetsDiscarded, dataObjectVideo["packetLoss" + report.ssrc][0]  ,  dataObjectVideo["packetLoss" + report.ssrc][1], dataObjectVideo["packetLoss" + report.ssrc][2]));
                    dataObjectVideo["packetLoss"+report.ssrc]=[report.packetsLost, report.packetsReceived,report.packetsDiscarded];                        
                } 
            }
        });
       
        let avgAudioLoss = lossForAudio.reduce((sum, a) => { return sum + a }, 0) / (lossForAudio.length || 1)
        let avgVideoLoss = lossForVideo.reduce((sum, a) => { return sum + a }, 0) / (lossForVideo.length || 1)
        /* console.log("avgScore for Audio ",(isNaN(avgAudioLoss) ? 0 : (avgAudioLoss * 100))," Array for it ",lossForAudio);
        console.log("avgScore for  Video ",(isNaN(avgVideoLoss) ? 0 : (avgVideoLoss * 100))," Array for it ",lossForVideo); */
        packetLostAudioPercentage = isNaN(avgAudioLoss) ? 0 : (avgAudioLoss * 100);
        packetLostVideoPercentage = isNaN(avgVideoLoss) ? 0 : (avgVideoLoss * 100);

        lossForAudio = [];
        lossForVideo = [];
    },

    /**
     * Create a DOM element to dump the webstats data, this DOM element will be accessed from the automation script
     */
     createAndUpdateDomEleWithDump: () =>  {
        let tag = document.createElement("div");
        tag.id = "webstats-data";
        tag.style.display="none";
        let text = document.createTextNode(JSON.stringify(statsArr));
        tag.appendChild(text);
        let element = document.getElementById("root");
        // @ts-ignore
        element.appendChild(tag);
        clearInterval(intervalDataCollectorObject);
    },

    /**
     * Calculates the average of the packetlost from packetRecieved, packetDiscarded, 
     * @param currentPacketLoss 
     * @param currentPacketReceived 
     * @param currentPacketDiscarded 
     * @param prePacketLoss 
     * @param prePacketReceived 
     * @param prePacketDiscarded 
     * @returns loss number not in percentage
     */
    lossPercentage: (currentPacketLoss: number, currentPacketReceived: number, currentPacketDiscarded: number = 0, prePacketLoss = 0, prePacketReceived = 0, prePacketDiscarded = 0) => {
        let loss = (currentPacketLoss - prePacketLoss) / ((currentPacketLoss + currentPacketReceived + currentPacketDiscarded) - (prePacketLoss + prePacketReceived + prePacketDiscarded))
        return loss;
    },

    /**
     * Function to add captured user score in the user json to be used for analysis.
     * @param score 
     */
    addUserScoreInS3Data: (score: number | undefined) => {
        score!==undefined && score!==null ? userScore = score : userScore = -1;
        if(statsArr.length > 0 && userScore !== -1){
            statsArr[statsArr.length-1].userScore = userScore;
        }
        else if (statsArr.length === 0 && userScore !== -1) {
            const tempStat: WebStat = {
                timestamp: 0,
                uploadSpeed: "", //transport
                downSpeed: "", //transport
                jitterData: [], //inbound-rtp - the less the number the better the quality - calculated by average of 
                packetLostData: {
                    packetLostAudioPercentage: -1,
                    packetLostVideoPercentage: -1
                } as PacketsData,  //inbound-rtp
                currentRoundTripTime: 0, //candidate-pair
                totalRoundTripTime: 0, //candidate-pair
            }
            tempStat.timestamp = new Date().getTime()
            tempStat.userScore = score;
            statsArr.push(tempStat);
        }
        CaptureStatData.pushStatsToS3();
    },

     /**
     * Stop the interval counters of capturing getStats API data and uploading to S3
     */
    stop: () => {
        clearInterval(intervalDataCollectorObject);
        clearInterval(intervalCollectorPacketLostObj);
        clearInterval(intervalDataUpload);
    },
}

export default CaptureStatData;