import { AxiosError } from 'axios';
import { IActions, IError, CREATE_ERROR } from './types';
import { SPOTLIGHT_USER_REJECTED } from '../meeting-reducer/types';
import { FETCH_MESSAGES_REJECTED } from '../messages-reducer/types';
import { UPDATE_EVENT_STATUS_REJECTED, UPDATE_BREAKOUT_ROOM_REJECTED } from '../meeting-reducer/types';
import { CREATE_BREAKOUT_ROOM_REJECTED, DELETE_BREAKOUT_ROOM_REJECTED, } from '../meeting-reducer/types';
import { RAISED_HAND_REJECTED, LOWER_HAND_REJECTED, ADMIN_LOWER_HAND_REJECTED } from '../meeting-reducer/types';

const raisedHand = (state: IError | null = null, action: IActions): IError | null => {
    switch (action.type) {
        case CREATE_ERROR: {
            return { key: Date.now(), error: action.payload }
        } case FETCH_MESSAGES_REJECTED: {
            return addError(action.payload);
        } case RAISED_HAND_REJECTED: {
            return addError(action.payload);
        } case LOWER_HAND_REJECTED: {
            return addError(action.payload);
        } case SPOTLIGHT_USER_REJECTED: {
            return addError(action.payload);
        } case ADMIN_LOWER_HAND_REJECTED: {
            return addError(action.payload);
        } case UPDATE_EVENT_STATUS_REJECTED: {
            return addError(action.payload);
        } case CREATE_BREAKOUT_ROOM_REJECTED: {
            return addError(action.payload);
        } case UPDATE_BREAKOUT_ROOM_REJECTED: {
            return addError(action.payload);
        } case DELETE_BREAKOUT_ROOM_REJECTED: {
            return addError(action.payload);
        } default: {
            return state;
        }
    }
}

const addError = (payload: AxiosError): IError => {
    if (payload.message) {
        return { key: Date.now(), error: JSON.stringify(payload.message) }
    } else {
        return { key: Date.now(), error: JSON.stringify(payload) }
    }
}

export default raisedHand;