import { FC } from 'react';
import styles from './styles.module.css';
import Item from './item';

interface Props {
    title: string
    selected?: string
    devices: MediaDeviceInfo[]
    handleChange: (value: string) => void
}

const MenuSettings: FC<Props> = ({ title, devices, selected, handleChange }) => {
    return (
        <div className={styles.menu_settings}>
            <div className={styles.title}>{title}</div>
            {devices.map(({ deviceId, label }) => {
                return <Item
                    key={deviceId}
                    label={label}
                    checked={(selected === deviceId)}
                    onChange={() => handleChange(deviceId)}
                />
            })}
        </div>
    )
}

export default MenuSettings;