import styles from './styles.module.css'
import Icon from '../../../../icon';

const FileSelector = ({ setState }) => {

    const checkImageSize = (file) => {
        if (file) {
            const image = new Image();
            setState((prevState) => ({ ...prevState, error: null }));

            image.addEventListener('error', function () {
                setState((prevState) => ({ ...prevState, error: "Invalid file." }));
            });

            image.addEventListener("load", function () {
                if (this.height > 500 && this.width > 500) {
                    setState((prevState) => ({ ...prevState, file }));
                } else {
                    setState((prevState) => ({ ...prevState, error: "Image must be of resolution 500x500 or higher." }));
                }
                URL.revokeObjectURL(file);
            });

            image.src = URL.createObjectURL(file);
        }
    }

    const onSelectImage = (e) => {
        setState((prevState) => ({ ...prevState, error: null }));
        e.preventDefault();
        checkImageSize(e.target.files[0])
    }

    return (
        <div className={styles.file_selector}>
            <label className={styles.icon_button} >
                <Icon className={styles.icon} icon="edit" />
                <input style={{ display: "none" }} type="file" accept="image/*" onChange={onSelectImage} />
            </label>
        </div>
    );
}

export default FileSelector;