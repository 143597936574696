import React from 'react';

const Message = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 24 24" fill="none">
            <g clipPath="url(#clip0)">
                <path d="M21.1875 2.50023e-06H2.8125C2.06658 2.50023e-06 1.35121 0.296319 0.823762 0.823765C0.296316 1.35121 0 2.06658 0 2.8125L0 14.9194C0 15.6653 0.296316 16.3807 0.823762 16.9081C1.35121 17.4356 2.06658 17.7319 2.8125 17.7319H7.1775C7.22723 17.7319 7.27492 17.7516 7.31008 17.7868C7.34525 17.822 7.365 17.8696 7.365 17.9194V22.6875C7.36498 22.9471 7.44195 23.2009 7.58617 23.4167C7.73038 23.6326 7.93538 23.8008 8.17521 23.9002C8.41505 23.9995 8.67897 24.0255 8.93358 23.9749C9.1882 23.9242 9.42207 23.7992 9.60563 23.6156L15.4331 17.7881C15.4506 17.7708 15.4714 17.757 15.4943 17.7477C15.5171 17.7383 15.5416 17.7336 15.5662 17.7338H21.1912C21.9372 17.7338 22.6525 17.4374 23.18 16.91C23.7074 16.3825 24.0037 15.6672 24.0037 14.9213V2.8125C24.0037 2.44284 23.9309 2.07681 23.7893 1.73534C23.6477 1.39387 23.4402 1.08365 23.1787 0.822438C22.9171 0.561224 22.6066 0.354135 22.2649 0.213014C21.9233 0.0718934 21.5572 -0.000490378 21.1875 2.50023e-06ZM22.875 14.9194C22.875 15.3669 22.6972 15.7962 22.3807 16.1126C22.0643 16.4291 21.6351 16.6069 21.1875 16.6069H15.5625C15.2157 16.6044 14.8821 16.7392 14.6344 16.9819L8.8125 22.8188C8.78627 22.8452 8.75277 22.8632 8.71625 22.8706C8.67974 22.8779 8.64187 22.8742 8.60747 22.8599C8.57308 22.8456 8.54371 22.8214 8.52313 22.7904C8.50254 22.7593 8.49166 22.7229 8.49187 22.6856V17.9194C8.49187 17.5713 8.35359 17.2374 8.10745 16.9913C7.86131 16.7452 7.52747 16.6069 7.17938 16.6069H2.8125C2.36495 16.6069 1.93572 16.4291 1.61926 16.1126C1.30279 15.7962 1.125 15.3669 1.125 14.9194V2.8125C1.125 2.36495 1.30279 1.93573 1.61926 1.61926C1.93572 1.30279 2.36495 1.125 2.8125 1.125H21.1875C21.6351 1.125 22.0643 1.30279 22.3807 1.61926C22.6972 1.93573 22.875 2.36495 22.875 2.8125V14.9194Z" fill="#FFFFFF" />
                <circle cx="6" cy="9" r="2" fill="#FFFFFF" />
                <circle cx="12" cy="9" r="2" fill="#FFFFFF" />
                <circle cx="18" cy="9" r="2" fill="#FFFFFF" />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Message;