import { FC, useEffect, useRef, memo } from 'react';
import styles from './styles.module.css';
import Icon from '../../../../../icon';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../..';
import { useSpring, animated } from 'react-spring';
import _ from "lodash";
import room from '../../../../../../utility/room';

interface Props {
    userId: string
}

const SharingScreen: FC<Props> = ({ userId }) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const props = useSpring({ from: { scale: 0 }, to: { scale: 1 } });
    const videoTrack = useSelector(({ meeting }: RootState) => {
        const user = meeting.participants[userId]
        return user.screen && user.screenVideoTrack
    }, _.isEqual);

    useEffect(() => {
        room.subscribeVideo(userId, { screenVideo: true });
        return () => {
            room.unsubscribeVideo(userId, { screenVideo: false });
        }
    }, [userId]);

    useEffect(() => {
        const video = videoRef.current;
        if (video && videoTrack) {
            video.srcObject = new MediaStream([videoTrack]);
            if (video.paused) video.play();
            return () => {
                video.srcObject = null;
                video.load();
            }
        }
    }, [videoTrack, videoRef]);

    return (
        <animated.div style={props} className={styles.participant} onContextMenu={(e) => e.preventDefault()}>
            {console.log("SharingScreen")}
            <div className={styles.title}>
                <Icon className={styles.icon} icon="screen-share-big" />
                Screen sharing in progress
            </div>
            <video ref={videoRef} className={styles.video} controls={false} playsInline={true} />
        </animated.div>
    )
}

export default memo(SharingScreen);