import { FC, useEffect, useState, useCallback } from 'react';
import styles from './styles.module.css';
import Cream from '../../cream';
import Header from './header';
import Footer from './footer';
import { AxiosError } from 'axios';
import axios from '../../../axios';
import Loading from '../../loading';
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { Event } from '../../../redux/reducers/meeting-reducer/types';

interface State {
    loading: boolean
    error?: string,
    metadata?: any,
}

interface Props extends React.HTMLProps<HTMLDivElement> {
    render: (props: any) => React.FunctionComponent<any>
}

const MeetingLayout: FC<Props> = ({ children, render }) => {
    const { org_id } = useParams<any>();
    const [event, setEvent] = useState<Event>();
    const [{ metadata, loading, error }, setState] = useState<State>({ loading: true });

    useEffect(() => {
        axios.get(`organizations/metadata/${org_id}`).then((data: any) => {
            setState({ metadata: data, loading: false })
        }).catch((error: AxiosError) => {
            setState({ error: error.message, loading: false })
        });
    }, [org_id]);

    const updateMetadata = useCallback((event: Event) => setEvent(event), [])

    if (loading) {
        return <Loading />
    } else if (error) {
        return <Loading message={error} stopLoading />
    } else {
        return (
            <Cream themes={metadata.theme}>
                <div className={styles.meeting}>
                    <Helmet>
                        <link rel="icon" href={metadata.favicon} />
                        <title>{event?.title || metadata.tab_title}</title>
                        {event && <meta name="title" property='event:title' content={event.title} />}
                        {event && <meta name="description" property="event:description" content={event.description} />}
                        {event && <meta name="image" property='event:cover_image' content={event.cover_image} />}
                    </Helmet>
                    <Header logo={metadata.logo} event={event} />
                    {render ? render({ updateMetadata }) : children}
                    <Footer event={event} orgId={org_id} />
                </div>
            </Cream >
        )
    }
}

export default MeetingLayout;