import { FC, Fragment } from 'react';
import styles from '../styles.module.css';
import GoBack from '../go-back';
import Controls from './controls';
import Meeting from './meeting';
import Loading from './../../../loading';
import AudioPlayer from './audio-player';
import useMeetingRoom from '../../../../hooks/use-meeting-room';
import UserRatingModal from './controls/end-call/user-score-modal';
import FlowerSpinner from './../../../loading-animation/flower-spinner';

const MeetingRooms: FC = () => {
    const { joining, left, error, isBreakoutRoom } = useMeetingRoom();

    return (
        <Fragment>
            {!joining && <Controls />}
            {(!joining && !left) && <Meeting />}
            {(left && !error) && <UserRatingModal />}
            {(error || left) && <Loading className={styles.loading} message={error ? error : "Left meeting!"} stopLoading ><GoBack /></Loading>}
            {joining && <Loading className={styles.loading} message={isBreakoutRoom ? "Joining breakout room..." : "Joining main room..."} spinner={FlowerSpinner} />}
            <AudioPlayer />
        </Fragment>
    )
}

export default MeetingRooms;