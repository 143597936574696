import { FC } from 'react';
import styles from './styles.module.css';
import RaiseHandItem from './raise-hand-item';
import { RootState } from '../../../../../../../index';
import { useSelector } from 'react-redux';
import _ from 'lodash'

const RaiseHandNotification: FC = () => {
    const raisedHands = useSelector(({ raised_hands }: RootState) => raised_hands, _.isEqual);

    return (
        <div className={styles.raise_hand_notification}>
            {raisedHands.map((item) => <RaiseHandItem key={item.user_id} {...item} />)}
        </div>
    );
};

export default RaiseHandNotification;