import React, { FC, useState } from 'react';
import styles from './styles.module.css';
import Icon from '../../icon';
import ProfileForm from './profile-form';
import { domain } from '../../../config';
import { RootState } from "../../../../index";
import UploadPhotoModal from './upload-photo-modal';
import { useSelector } from 'react-redux';
import _ from "lodash";

const ProfilePage: FC = () => {
    const [values, setValues] = useState({ changePW: false, uploadPhoto: false })
    const { first_name, last_name, email, profile_image } = useSelector(({ user }: RootState) => {
        return {
            email: user.email,
            last_name: user.last_name,
            first_name: user.first_name,
            profile_image: user.profile
        }
    }, _.isEqual);

    return (
        <div className={styles.profile_page}>
            <div className={styles.avatar}>
                <div className={styles.image_edit}>
                    <img className={styles.profile_image} alt="" src={`${domain}/${profile_image}`} />
                    <button className={`${styles.button} ${styles.edit_photo}`} onClick={() => setValues(prevState => ({ ...prevState, uploadPhoto: true }))}>
                        <Icon className={styles.icon} icon="camera" />
                    </button>
                </div>
                <p className={styles.name}>{first_name} {last_name}</p>
                <p className={styles.email}>{email}</p>
            </div>
            <ProfileForm />
            {values.uploadPhoto && <UploadPhotoModal profile_image={profile_image} onClose={() => setValues(prevState => ({ ...prevState, uploadPhoto: false }))} />}
        </div>
    )
}

export default ProfilePage;