import { FC, useState } from 'react';
import styles from './styles.module.css';
import Modal, { Button } from '../../../../modal';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { MdContentCopy } from 'react-icons/md'


interface Props {
    close: () => void,
    meetingLink: string
}

const NewMeetingModal: FC<Props> = ({ close, meetingLink }) =>{
    const [value, setValue] = useState("My copy text");
    const [copied, setCopied] = useState(false);
    const [copySuccessfull, setCopySuccessfull] = useState(false);
    let interval: any = undefined;
    const showCopySuccessful = () => {
        if(interval !== undefined) {
            interval = undefined;
            clearInterval(interval);
            setCopySuccessfull(false);
        }
        setCopySuccessfull(true);
        interval = setTimeout(() => {
            setCopySuccessfull(false);
        }, 3000);
        setCopied(copied);
    }

    console.log("meetingLink: ",meetingLink)
    return (
        <Modal className={styles.modal} id="newMeetingModal">
            <p className={styles.title}>Share your new meeting</p>
            <div className={styles.copy_meeting_url_section}> 
                <div className={styles.copy_meeting_url_box}
                onChange={(e) => { setValue(value); setCopied(false); }}>{meetingLink}</div>
                <div className={styles.copy_button}>
                    <CopyToClipboard 
                        options={{ message: "" }} text={meetingLink} onCopy={() => {setCopied(true);showCopySuccessful();}} >
                            <MdContentCopy size={20} ></MdContentCopy>
                    </CopyToClipboard>
                </div>
            </div>
            {(copySuccessfull) && <div className={styles.copy_successfull}>Copied Meeting Link.</div>}
            <div className={styles.modal_buttons}>
                <Button onClick={close}>Cancel</Button>
            </div>
        </Modal>
    )
}

export default NewMeetingModal;