import { useRef, useEffect, useState } from 'react';
import styles from './styles.module.css';
import Cropper from 'cropperjs';
import Icon from "../../../../icon";
import Button from "../../../../button";
import "cropperjs/dist/cropper.css";

const ImageCropper = ({ file, onCrop, handleClose, handleDelete }) => {
    const imageRef = useRef(null)
    const [cropper, setCropper] = useState(null)

    useEffect(() => {
        const cropper = new Cropper(imageRef.current, {
            aspectRatio: 1 / 1,
            viewMode: 1,
            dragMode: "move",
            responsive: true,
            cropBoxMovable: false,
            cropBoxResizable: false,
            toggleDragModeOnDblclick: false,
            minCropBoxWidth: 800,
            minCropBoxHeight: 800,
            zoomOnWheel: false,
            zoomOnTouch: false,
            maxZoom: 2,
            ready: () => cropper.zoomTo(0)
        }).destroy().replace();
        setCropper(cropper)
    }, [])

    useEffect(() => {
        if (cropper) {
            cropper.destroy().replace(URL.createObjectURL(file));
            return () => URL.revokeObjectURL(file);
        }
    }, [file, cropper])

    const cropImage = () => {
        const canvas = cropper.getCroppedCanvas({ width: 500, height: 500 })
        canvas.toBlob(onCrop, file.type, 1)
    }

    const handleZoom = ({ target: { value } }) => {
        cropper.zoomTo(value)
    }

    return (
        <div className={styles.modal}>
            <div className={styles.upload_photo} aria-label="Crop Photo">
                <div className={styles.header}>
                    <p className={styles.title} tabIndex="18">Edit Your Photo</p>
                    <button className={styles.close_modal} tabIndex="22" aria-label="close photo editor" onClick={handleClose}>
                        <Icon className={styles.close_icon} icon="close" />
                    </button>
                </div>
                <div className={styles.upload_photo}>
                    <div className={styles.image_cropper}>
                        <div className={styles.cropper_js}>
                            <img ref={imageRef} alt="selected file in editor" />
                        </div>
                        <input
                            className={styles.slider}
                            type="range"
                            min="0"
                            max="10"
                            step="0.1"
                            defaultValue="0"
                            onChange={handleZoom} tabIndex="19" aria-label="photo zoom slider" />
                    </div>
                    <div className={styles.footer}>
                        <Button className={styles.delete_photo} tabIndex="21" aria-label="delete photo" onClick={handleDelete}>Remove</Button>
                        <Button className={styles.apply_button} type="turquoise" tabIndex="20" aria-label="apply edits and save" onClick={cropImage}>Apply</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ImageCropper;


