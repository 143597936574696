import { FC, useEffect, useState } from 'react';
import styles from './styles.module.css';
import Settings from './menu-settings';
import MenuButton from './menu-button';
import usePermission from "../../../../../hooks/use-permission";
import ControlButton from './control-button';
import useGetUserDevices from "../../../../../hooks/use-get-user-devices";
import room, { Participant } from '../../../../../utility/room';

const VideoControl: FC = () => {
    const [state] = usePermission("camera");
    const [isPublished, setState] = useState(false);
    const [devices, selected, handleChange] = useGetUserDevices("videoinput", state);

    useEffect(() => {
        room.setInputDevices({ videoDeviceId: selected }).catch((error) => {
            console.error("DailyDeviceInfos", error);
        });
    }, [selected])

    useEffect(() => {
        const handleUpdate = (participant: Participant) => {
            if (participant.local) {
                setState(participant.video);
                console.log("participant-updated-video", participant);
            }
        }
        room.on("participant-updated", handleUpdate);
        return () => {
            room.off("participant-updated", handleUpdate)
        }
    }, [])

    const handleTrack = () => {
        room.setLocalVideo(!isPublished);
    }

    if (devices.length) {
        return (
            <MenuButton onClick={handleTrack} icon={isPublished ? "video" : "video-mute"}>
                <Settings {...{ title: "Select a Camera", devices, selected, handleChange }} />
            </MenuButton>
        )
    } else {
        return (
            <ControlButton className={styles.disabled_button} disabled={true} icon="video-mute" />
        )
    }
}

export default VideoControl;