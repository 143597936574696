import { Dispatch } from 'redux';
import { meeting } from '../../axios';
import { DispatchLowerHand, LOWER_HAND } from '../reducers/meeting-reducer/types';
import { DispatchRaisedHand, RAISED_HAND } from '../reducers/meeting-reducer/types';
import { DispatchScreenSharing, SCREEN_SHARING } from '../reducers/meeting-reducer/types';
import { DispatchJoinedMeeting, JOINED_MEETING } from '../reducers/meeting-reducer/types';
import { DispatchSpotlightUser, SPOTLIGHT_USER } from '../reducers/meeting-reducer/types';
import { DispatchSpeakerChanged, SPEAKER_CHANGED, } from '../reducers/meeting-reducer/types';
import { DispatchAdminLowerHand, ADMIN_LOWER_HAND } from '../reducers/meeting-reducer/types';
import { DispatchParticipantLeft, PARTICIPANT_LEFT } from '../reducers/meeting-reducer/types';
import { DispatchDevicePermission, DEVICE_PERMISSION } from '../reducers/meeting-reducer/types';
import { DispatchParticipantUpdate, PARTICIPANT_UPDATE } from '../reducers/meeting-reducer/types';
import { DispatchParticipantJoined, PARTICIPANT_JOINED } from '../reducers/meeting-reducer/types';
import { DispatchUpdateEventStatus, UPDATE_EVENT_STATUS } from '../reducers/meeting-reducer/types';
import { DispatchCreateBreakoutRoom, CREATE_BREAKOUT_ROOM } from '../reducers/meeting-reducer/types';
import { DispatchUpdateBreakoutRoom, UPDATE_BREAKOUT_ROOM } from '../reducers/meeting-reducer/types';
import { DispatchDeleteBreakoutRoom, DELETE_BREAKOUT_ROOM } from '../reducers/meeting-reducer/types';
import { DispatchFetchMeetingRoom, FETCH_MEETING_ROOM_FULFILLED, FETCH_MEETING_ROOM_REJECTED } from '../reducers/meeting-reducer/types';
import { Participant, EventObjectCameraError, EventObjectJoinedMeeting, EventObjectSpeakerChanged } from '../../utility/room';


export const fetchMeetingRoom = (update: (data: any) => void) => {
    return (dispatch: Dispatch<DispatchFetchMeetingRoom>) => {
        meeting.get('rooms/meeting').then((data: any) => {
            update(data.event);
            dispatch({ type: FETCH_MEETING_ROOM_FULFILLED, payload: data })
        }).catch((error) => {
            dispatch({ type: FETCH_MEETING_ROOM_REJECTED, payload: error })
        })
    }
}

export const joinedMeeting = (payload: EventObjectJoinedMeeting) => {
    return (dispatch: Dispatch<DispatchJoinedMeeting>) => {
        dispatch({ type: JOINED_MEETING, payload });
    }
}

export const participantJoined = (payload: Participant) => {
    return (dispatch: Dispatch<DispatchParticipantJoined>) => {
        dispatch({ type: PARTICIPANT_JOINED, payload });
    }
}

export const participantLeft = (payload: Participant) => {
    return (dispatch: Dispatch<DispatchParticipantLeft>) => {
        dispatch({ type: PARTICIPANT_LEFT, payload });
    }
}

export const participantUpdate = (payload: Participant) => {
    return (dispatch: Dispatch<DispatchParticipantUpdate>) => {
        dispatch({ type: PARTICIPANT_UPDATE, payload });
    }
}

export const screenSharing = (payload?: string) => {
    return (dispatch: Dispatch<DispatchScreenSharing>) => {
        dispatch({ type: SCREEN_SHARING, payload });
    }
}

export const speakerChanged = (payload: EventObjectSpeakerChanged) => {
    return (dispatch: Dispatch<DispatchSpeakerChanged>) => {
        dispatch({ type: SPEAKER_CHANGED, payload });
    }
}

export const devicePermission = (payload: EventObjectCameraError) => {
    return (dispatch: Dispatch<DispatchDevicePermission>) => {
        dispatch({ type: DEVICE_PERMISSION, payload });
    }
}

export const raiseHand = () => {
    return (dispatch: Dispatch<DispatchRaisedHand>) => {
        dispatch({ type: RAISED_HAND, payload: meeting.post('rooms/raise-hand') });
    }
}

export const lowerHand = () => {
    return (dispatch: Dispatch<DispatchLowerHand>) => {
        dispatch({ type: LOWER_HAND, payload: meeting.post('rooms/lower-hand') });
    }
}

export const adminLowerUserHand = (user_id: string) => {
    return (dispatch: Dispatch<DispatchAdminLowerHand>) => {
        dispatch({ type: ADMIN_LOWER_HAND, payload: meeting.post(`rooms/lower-hand/${user_id}`) });
    }
}

export const spotlightUser = (user_id: string) => {
    return (dispatch: Dispatch<DispatchSpotlightUser>) => {
        dispatch({ type: SPOTLIGHT_USER, payload: meeting.post(`rooms/spotlight-user/${user_id}`) });
    }
}

export const startMeeting = () => {
    return (dispatch: Dispatch<DispatchUpdateEventStatus>) => {
        dispatch({ type: UPDATE_EVENT_STATUS, payload: meeting.post('rooms/start-meeting') });
    }
}

export const endMeeting = (mark_as_previous: boolean) => {
    return (dispatch: Dispatch<DispatchUpdateEventStatus>) => {
        dispatch({ type: UPDATE_EVENT_STATUS, payload: meeting.post('rooms/end-meeting', { mark_as_previous }) });
    }
}

export const createBreakoutRoom = (data: { name: string, users: string[] }[]) => {
    return (dispatch: Dispatch<DispatchCreateBreakoutRoom>) => {
        dispatch({ type: CREATE_BREAKOUT_ROOM, payload: meeting.put('rooms/breakout-room', data) });
    }
}

export const updateBreakoutRoom = (userId: string, newRoomId?: string, currentRoomId?: string) => {
    const data = { user_id: userId, new_room_id: newRoomId, current_room_id: currentRoomId };
    return (dispatch: Dispatch<DispatchUpdateBreakoutRoom>) => {
        dispatch({ type: UPDATE_BREAKOUT_ROOM, payload: meeting.post('rooms/breakout-room', data) });
    }
}

export const deleteBreakoutRoom = () => {
    return (dispatch: Dispatch<DispatchDeleteBreakoutRoom>) => {
        dispatch({ type: DELETE_BREAKOUT_ROOM, payload: meeting.delete('rooms/breakout-room') });
    }
}