import { FC, useEffect, useRef, memo } from 'react';
import styles from './styles.module.css';
import Icon from '../../../../../icon';
import { animated } from 'react-spring';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../..';
import { domain } from '../../../../../../config';
import _ from "lodash";

interface Props {
    style?: any
    userId: string
}

const Participant: FC<Props> = ({ style, userId }) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const participant = useSelector(({ meeting }: RootState) => {
        const user = meeting.participants[userId]
        return {
            audio: user.audio,
            speaker: user.speaker,
            profile: user.profile,
            last_name: user.last_name,
            first_name: user.first_name,
            raised_hand: user.raised_hand,
            videoTrack: user.video && user.videoTrack,
        }
    }, _.isEqual);

    const { profile, raised_hand, first_name, last_name, audio, videoTrack, speaker } = participant;

    useEffect(() => {
        const video = videoRef.current;
        if (video && videoTrack) {
            video.srcObject = new MediaStream([videoTrack]);
            if (video.paused) video.play();
            return () => {
                video.srcObject = null;
                video.load();
            }
        }
    }, [videoTrack, videoRef]);

    return (
        <animated.div style={style} className={`${styles.participant} ${speaker && styles.speaker}`} onContextMenu={(e) => e.preventDefault()}>
            {console.log(`Participant | ${first_name} ${last_name}:`, participant)}
            {raised_hand && <Icon className={styles.raised_hand} icon="raise-hand-emoji" />}
            <p className={styles.name}>
                <Icon className={styles.mic} icon={audio ? "mic" : "mic-mute"} />
                {first_name} {last_name?.charAt(0)}.
            </p>
            <img className={styles.avatar} src={`${domain}/${profile}`} alt="profile" />
            <video ref={videoRef} className={styles.video} controls={false} playsInline muted autoPlay />
        </animated.div>
    )
}

export default memo(Participant, _.isEqual);