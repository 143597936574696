import { FC } from 'react';
import styles from './styles.module.css';

interface Props {
    type: string
    className?: string
    onClick: () => void
}

const PlayButton: FC<Props> = ({ className, onClick, type }) => {
    return <div className={`${className} ${styles.play_button} ${type === "alternate" && styles.alternate}`} onClick={onClick} />
}

export default PlayButton;