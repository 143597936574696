import { FC, memo } from 'react';
import styles from './styles.module.css';
import EachGuest from './guest';
import Modal from '../../../../modal';
import Icon from '../../../../icon';

export interface Guest {
    name: string
    title: string
    description: string
    profile: string
}

interface Props {
    guests: Guest[]
    onClose: () => void
}

const EventOverview: FC<Props> = ({ onClose, guests }) => {
    return (
        <Modal className={styles.invited_guests}>
            <div className={styles.guests}>
                {guests.map(({ name, description, profile }, i) => <EachGuest key={i} {...{ name, description, profile }} />)}
            </div>
            <button className={styles.close_button} onClick={onClose}>
                <Icon className={styles.close_icon} icon="close" />
            </button>
        </Modal>
    )
}

export default memo(EventOverview);