import { useState } from "react";
import { RootState } from '../../index';
import { useSelector } from 'react-redux';
import _ from 'lodash';

const useParticipantsSelector = () => {
    const [page, setPage] = useState(0);
    const [pageLength, setPageLength] = useState(20);
    const participants = useSelector(({ meeting }: RootState) => meeting.active_participants, _.isEqual);

    const scrollLeft = () => {
        if (page - pageLength <= 0) setPage(0)
        else setPage(page - pageLength)
    }

    const scrollRight = () => {
        const itemsLeft = (participants.length - (page + pageLength))
        if (itemsLeft > pageLength) return setPage(page + pageLength)
        setPage((page + pageLength) - (pageLength - itemsLeft))
    }

    return {
        page,
        scrollLeft, scrollRight,
        pageLength, setPageLength,
        total: participants.length,
        participants: participants.slice(page, page + pageLength)
    }
}

export default useParticipantsSelector;