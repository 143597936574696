import { FC, memo } from 'react';
import styles from './styles.module.css';
import Share from './share';
import TawkTo from './tawk-to';
import { Event } from '../../../../redux/reducers/meeting-reducer/types';

interface Props {
    orgId: string
    event?: Event
}

const Footer: FC<Props> = ({ orgId, event }) => {
    return (
        <div className={styles.footer}>
            {(event && event.shared_text) && <Share {...{ org_id: orgId, event_id: event.event_id, shared_text: event.shared_text }} />}
            <TawkTo />
        </div>
    )
}

export default memo(Footer);