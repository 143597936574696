import { FC, memo, useEffect, useState } from 'react';
import styles from './styles.module.css';
import Sidebar from '../../sidebar-layout';
import { IBreakoutRoomUser } from './participant';
import Button from '../../../../../../button';
import { useDispatch, useSelector } from 'react-redux';
import RoomArea, { IBreakoutRoom } from './breakout-room';
import { RootState } from '../../../../../../../../index';
import { createBreakoutRoom, deleteBreakoutRoom, updateBreakoutRoom } from "../../../../../../../redux/action/meeting";
import _ from 'lodash';

interface Props {
    close: () => void
}

interface IParticipant {
    user_id: string
    profile: string
    last_name: string
    first_name: string
    type: "participant" | "guest" | "moderator"
}

const BreakoutRoomSidebar: FC<Props> = ({ close }) => {
    const dispatch = useDispatch();
    const [rooms, setRooms] = useState<IBreakoutRoom[]>([]);
    const [collapseAll, toggleCollapseAll] = useState(false);
    const [values, setValues] = useState({ rooms: 1, panelists: false, moderator: false });
    const { loading, breakout_rooms, participants, users, room_id, user_id } = useSelector(({ meeting }: RootState) => {
        const participants: IParticipant[] = [];
        const users = new Map<string, IParticipant>();
        Object.values(meeting.participants).forEach((p) => {
            if (p.session_id) {
                const { user_id, profile, last_name, first_name, type } = p
                participants.push({ user_id, profile, last_name, first_name, type });
                users.set(p.user_id, { user_id, profile, last_name, first_name, type });
            }
        });
        return {
            participants, users,
            user_id: meeting.user_id,
            room_id: meeting.breakout_room_id,
            breakout_rooms: meeting.breakout_rooms,
            loading: meeting.updating_breakout_rooms,
        }
    }, _.isEqual);

    useEffect(() => {
        setRooms((prevRooms) => {
            const _users = new Map(users);
            const rooms: IBreakoutRoom[] = [];

            if (breakout_rooms) {
                breakout_rooms.forEach(({ room_id, users, name }, i) => {
                    const participants: IBreakoutRoomUser[] = []
                    users.forEach((userId) => {
                        const user = _users.get(userId);
                        if (user) {
                            _users.delete(userId);
                            participants.push(user);
                        }
                    })
                    rooms.push({ room_id, participants, isMain: false, name })
                })
            } else {
                for (let i = 1; i <= values.rooms; i++) {
                    const room = prevRooms[i];
                    if (!room) {
                        rooms.push({ name: `Breakout room ${i}`, isMain: false, participants: [] });
                    } else {
                        rooms.push({
                            isMain: false,
                            name: room.name,
                            participants: room.participants.filter((p) => {
                                const user = _users.get(p.user_id);
                                if (!user) return false;
                                if (!values.panelists && p.type === "guest") return false;
                                if (!values.moderator && p.type === "moderator") return false;
                                return _users.delete(p.user_id);
                            })
                        });
                    }
                }
            }

            const participants: IBreakoutRoomUser[] = [];
            _users.forEach(({ user_id }) => {
                const user = _users.get(user_id);
                if (user) {
                    const { type, profile, last_name, first_name } = user;
                    participants.push({ type, user_id, profile, last_name, first_name });
                }
            })
            rooms.unshift({ participants, isMain: true, name: "Main room" });
            return rooms;
        })
    }, [users, breakout_rooms, participants, values]);

    const handleChange = ({ target: { name, value, type, checked } }: React.ChangeEvent<HTMLInputElement>) => {
        if (type === "checkbox") {
            setValues((prevState) => ({ ...prevState, [name]: checked }));
        } else {
            setValues((prevState) => ({ ...prevState, [name]: value }));
        }
    }

    const handleAutoAssign = () => {
        const rooms: IBreakoutRoom[] = [{ isMain: true, participants: [], name: "Main room" }];
        let _participants: IBreakoutRoomUser[] = [...participants]

        if (!values.moderator && !values.panelists) {
            rooms[0].participants.push(...participants.filter((p) => p.type === "moderator" || p.type === "guest"));
            _participants = _participants.filter((p) => p.type !== "moderator" && p.type !== "guest");
        } else if (!values.moderator && values.panelists) {
            rooms[0].participants.push(...participants.filter((p) => p.type === "moderator"));
            _participants = _participants.filter((p) => p.type !== "moderator");
        } else if (!values.panelists && values.moderator) {
            rooms[0].participants.push(...participants.filter((p) => p.type === "guest"));
            _participants = _participants.filter((p) => p.type !== "guest");
        }

        let remainder = _participants.length % values.rooms;
        const totalItems = _participants.length - remainder;
        const participantsPerRoom = totalItems / values.rooms;

        for (let i = 1; i <= values.rooms; i++) {
            let loop = participantsPerRoom;
            const room: IBreakoutRoom = { isMain: false, participants: [], name: `Breakout room ${i}` };

            if (remainder && i >= remainder) { loop += 1; remainder--; }

            for (let j = 1; j <= loop; j++) {
                const randomIndex = Math.floor(Math.random() * _participants.length);
                const participant = _participants.splice(randomIndex, 1)[0];
                room.participants.push(participant);
            }
            rooms.push(room);
        }
        setRooms(rooms);
    }

    const handleJoinRoom = (newRoomId?: string) => {
        if (user_id) dispatch(updateBreakoutRoom(user_id, newRoomId, room_id));
    }

    const handleRoomChange = (userId: string, from: number, to: number = 0) => {
        if (breakout_rooms) {
            const newRoomId = to ? rooms[to].room_id : undefined;
            const currentRoomId = from ? rooms[from].room_id : undefined;
            dispatch(updateBreakoutRoom(userId, newRoomId, currentRoomId));
        } else {
            const user = users.get(userId);
            if (user) {
                const participants = rooms[from].participants.filter((p) => p.user_id !== userId);
                rooms[from].participants = [...participants];
                rooms[to].participants.push(user);
                setRooms([...rooms]);
            }
        }
    }

    const handleCreateBreakoutRooms = () => {
        const breakoutRooms = rooms.slice(1, rooms.length).map((room) => {
            return {
                name: room.name,
                users: room.participants.map(({ user_id }) => user_id)
            }
        });
        dispatch(createBreakoutRoom(breakoutRooms));//update to passing 2D array
    }

    const handleCloseBreakoutRooms = () => {
        dispatch(deleteBreakoutRoom());
    }

    return (
        <Sidebar title="Create Breakout Rooms" onClose={close}>
            <div className={styles.breakout_rooms}>
                {console.log('rooms', rooms)}
                <p className={styles.sub_title}>Total <b>{participants.length}</b> participants in the room</p>
                <p className={styles.sub_title}>Drag participants between breakout rooms</p>
                <div className={styles.items}>
                    <div className={`${styles.item} ${styles.number_item}`}>
                        <label>Number of rooms</label>
                        <input type="number" name="rooms" value={values.rooms} min={1} onChange={handleChange} disabled={!!breakout_rooms} />
                    </div>
                    <div className={styles.item}>
                        <input type="checkbox" name="moderator" checked={values.moderator} onChange={handleChange} disabled={!!breakout_rooms} />
                        <label>Include moderator</label>
                    </div>
                    <div className={styles.item}>
                        <input type="checkbox" name="panelists" checked={values.panelists} onChange={handleChange} disabled={!!breakout_rooms} />
                        <label>Include panelists</label>
                    </div>
                    <div className={styles.item}>
                        <input type="checkbox" name="collapse_all" checked={collapseAll} onChange={() => toggleCollapseAll(!collapseAll)} />
                        <label>Collapse all Rooms</label>
                    </div>
                </div>
                <Button className={styles.auto_assign_button} onClick={handleAutoAssign}>Assign Automatically</Button>
                <div className={styles.all_rooms}>
                    {rooms.map((room, i) => <RoomArea key={i} room={room} indx={i} currentRoomId={room_id} collapseAll={collapseAll} onRoomChange={handleRoomChange} joinRoom={handleJoinRoom} />)}
                </div>
                {breakout_rooms && <Button className={`${styles.button} ${styles.close}`} onClick={handleCloseBreakoutRooms} loading={loading} disabled={loading}>
                    Close Breakout Rooms
                </Button>}
                {!breakout_rooms && <Button className={styles.button} onClick={handleCreateBreakoutRooms} loading={loading} disabled={loading}>
                    Create Breakout Rooms
                </Button>}
            </div>
        </Sidebar>
    )
}

export default memo(BreakoutRoomSidebar);