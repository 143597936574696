import { FC, Fragment, useState } from 'react';
import ControlButton from '../control-button';
import BreakoutRoomSidebar from './breakout-room-sidebar';

const BreakoutRoom: FC = () => {
    const [show, toggle] = useState(false);
    return (
        <Fragment>
            <ControlButton active={show} onClick={() => toggle(!show)} icon="breakout-rooms" />
            {show && <BreakoutRoomSidebar close={() => toggle(false)} />}
        </Fragment>
    )
}

export default BreakoutRoom;