import { FC } from 'react';
import styles from './styles.module.css';
import Icons from '../../../../../icon';
import Spinner from '../../../../../loading-animation/spinner';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    icon: string
    active?: boolean
    loading?: boolean
}

const Button: FC<Props> = ({ icon, className, children, loading, active, ...rest }) => {
    return (
        <button className={`${styles.button} ${active && styles.active} ${className}`} {...rest} disabled={loading}>
            {children}
            {loading && <Spinner className={styles.loading} />}
            {!loading && <Icons className={styles.icon} icon={icon} />}
        </button>
    )
}

export default Button;