import styles from './styles.module.css';

const FlowerSpinner = ({ className }: any) => {
    return (
        <div className={`${styles.spinner} ${className}`}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    )

}

export default FlowerSpinner;
