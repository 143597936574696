import { AxiosError } from 'axios';
import { Room, Participant, EventObjectJoinedMeeting, EventObjectCameraError, EventObjectSpeakerChanged } from "../../../utility/room"

export const PARTICIPANT_UPDATE = "PARTICIPANT-UPDATE";
export const PARTICIPANT_JOINED = "PARTICIPANT-JOINED";
export const PARTICIPANT_LEFT = "PARTICIPANT-LEFT";
export const SPEAKER_CHANGED = "SPEAKER-CHANGED";
export const JOINED_MEETING = "JOINED-MEETING";
export const SCREEN_SHARING = "SCREEN-SHARING";

export const FETCH_MEETING_ROOM_FULFILLED = "FETCH-MEETING-ROOM-FULFILLED";
export const FETCH_MEETING_ROOM_REJECTED = "FETCH-MEETING-ROOM-REJECTED";
export const FETCH_MEETING_ROOM = "FETCH-MEETING-ROOM";

export const SPOTLIGHT_USER_FULFILLED = "SPOTLIGHT-USER-FULFILLED";
export const SPOTLIGHT_USER_REJECTED = "SPOTLIGHT-USER-REJECTED";
export const SPOTLIGHT_USER_PENDING = "SPOTLIGHT-USER-PENDING";
export const SPOTLIGHT_USER = "SPOTLIGHT-USER";

export const ADMIN_LOWER_HAND_FULFILLED = "LOWER-USER-HAND-FULFILLED";
export const ADMIN_LOWER_HAND_REJECTED = "LOWER-USER-HAND-REJECTED";
export const ADMIN_LOWER_HAND_PENDING = "LOWER-USER-HAND-PENDING";
export const ADMIN_LOWER_HAND = "LOWER-USER-HAND";

export const LOWER_HAND_FULFILLED = "LOWER-HAND-FULFILLED";
export const LOWER_HAND_REJECTED = "LOWER-HAND-REJECTED";
export const LOWER_HAND = "LOWER-HAND";

export const RAISED_HAND_FULFILLED = "RAISE-HAND-FULFILLED";
export const RAISED_HAND_REJECTED = "RAISE-HAND-REJECTED";
export const RAISED_HAND = "RAISE-HAND";

export const UPDATE_EVENT_STATUS_FULFILLED = "UPDATE-EVENT-STATUS-FULFILLED";
export const UPDATE_EVENT_STATUS_REJECTED = "UPDATE-EVENT-STATUS-REJECTED";
export const UPDATE_EVENT_STATUS_PENDING = "UPDATE-EVENT-STATUS-PENDING";
export const UPDATE_EVENT_STATUS = "UPDATE-EVENT-STATUS";

export const CREATE_BREAKOUT_ROOM_FULFILLED = "CREATE-BREAKOUT-ROOM-FULFILLED";
export const CREATE_BREAKOUT_ROOM_REJECTED = "CREATE-BREAKOUT-ROOM-REJECTED";
export const CREATE_BREAKOUT_ROOM_PENDING = "CREATE-BREAKOUT-ROOM-PENDING";
export const CREATE_BREAKOUT_ROOM = "CREATE-BREAKOUT-ROOM";

export const UPDATE_BREAKOUT_ROOM_FULFILLED = "UPDATE-BREAKOUT-ROOM-FULFILLED";
export const UPDATE_BREAKOUT_ROOM_REJECTED = "UPDATE-BREAKOUT-ROOM-REJECTED";
export const UPDATE_BREAKOUT_ROOM = "UPDATE-BREAKOUT-ROOM";

export const DELETE_BREAKOUT_ROOM_FULFILLED = "DELETE-BREAKOUT-ROOM-FULFILLED";
export const DELETE_BREAKOUT_ROOM_REJECTED = "DELETE-BREAKOUT-ROOM-REJECTED";
export const DELETE_BREAKOUT_ROOM_PENDING = "DELETE-BREAKOUT-ROOM-PENDING";
export const DELETE_BREAKOUT_ROOM = "DELETE-BREAKOUT-ROOM";

export const HAND_RAISED = "HAND-RAISED";
export const LOWERED_HAND = "HAND-LOWERED";
export const EVENT_UPDATED = "EVENT-UPDATED";
export const NEW_USER_ADDED = "NEW-USER-ADDED";
export const USER_SPOTLIGHTED = "USER-SPOTLIGHTED";
export const DEVICE_PERMISSION = "DEVICE-PERMISSION";
export const BREAKOUT_ROOM_UPDATE = "BREAKOUT-ROOM-UPDATE";



export interface IBreakoutRoom {
    name: string
    room_id: string
    users: string[]
}

export interface EventStatus {
    is_started: boolean
    updating_event?: boolean
    is_previous_event: boolean
    breakout_rooms?: IBreakoutRoom[]
}

export interface EventGuest {
    name: string
    title: string
    description: string
    profile: string
}

export interface Event extends EventStatus {
    date: number
    title: string
    event_id: string
    cover_image: string
    description: string
    learn_more: string
    shared_text?: string
    guests: EventGuest[]
}

export interface IUser {
    user_id?: string
    profile?: string
    raised_hand: boolean
    breakout_room_id?: string
    role: "admin" | "participant"
}

export interface IMeeting extends IUser, Partial<Event> {
    room: Room
    error?: string
    loading: boolean
    screen_sharing?: string
    active_participants: string[]
    spotlighted_participants: string[]
    updating_breakout_rooms?: boolean
    device_error?: EventObjectCameraError
    participants: { [key: string]: Participant }
}

export interface DispatchJoinedMeeting {
    type: typeof JOINED_MEETING
    payload: EventObjectJoinedMeeting
}

export interface DispatchParticipantJoined {
    type: typeof PARTICIPANT_JOINED
    payload: Participant
}

export interface DispatchParticipantUpdate {
    type: typeof PARTICIPANT_UPDATE
    payload: Participant
}

export interface DispatchParticipantLeft {
    type: typeof PARTICIPANT_LEFT
    payload: Participant
}

export interface DispatchScreenSharing {
    type: typeof SCREEN_SHARING
    payload?: string
}

export interface DispatchSpeakerChanged {
    type: typeof SPEAKER_CHANGED
    payload: EventObjectSpeakerChanged
}

export interface DispatchDevicePermission {
    type: typeof DEVICE_PERMISSION
    payload: EventObjectCameraError
}

export interface DispatchAdminLowerHand {
    type: typeof ADMIN_LOWER_HAND
    payload: Promise<void>
}

export interface DispatchSpotlightUser {
    type: typeof SPOTLIGHT_USER
    payload: Promise<void>
}

export interface DispatchLowerHand {
    type: typeof LOWER_HAND
    payload: Promise<void>
}

export interface DispatchRaisedHand {
    type: typeof RAISED_HAND
    payload: Promise<void>
}

export interface DispatchUpdateEventStatus {
    type: typeof UPDATE_EVENT_STATUS
    payload: Promise<any>
}

export interface DispatchCreateBreakoutRoom {
    type: typeof CREATE_BREAKOUT_ROOM
    payload: Promise<any>
}

export interface DispatchUpdateBreakoutRoom {
    type: typeof UPDATE_BREAKOUT_ROOM
    payload: Promise<any>
}

export interface DispatchDeleteBreakoutRoom {
    type: typeof DELETE_BREAKOUT_ROOM
    payload: Promise<any>
}

export interface FetchMeetingRoomRejected {
    type: typeof FETCH_MEETING_ROOM_REJECTED
    payload: AxiosError
}

export interface FetchMeetingRoomFulfilled {
    type: typeof FETCH_MEETING_ROOM_FULFILLED
    payload: {
        user: IUser
        event: Event
    }
}

export interface JoinedMeeting {
    type: typeof JOINED_MEETING
    payload: EventObjectJoinedMeeting
}

export interface ParticipantJoined {
    type: typeof PARTICIPANT_JOINED
    payload: Participant
}

export interface ParticipantLeft {
    type: typeof PARTICIPANT_LEFT
    payload: Participant
}

export interface ParticipantUpdate {
    type: typeof PARTICIPANT_UPDATE
    payload: Participant
}

export interface ScreenSharing {
    type: typeof SCREEN_SHARING
    payload?: string
}

export interface SpeakerChanged {
    type: typeof SPEAKER_CHANGED
    payload: EventObjectSpeakerChanged
}

export interface RaisedHandFulfilled {
    type: typeof RAISED_HAND_FULFILLED
    payload: string
}

export interface RaisedHandRejected {
    type: typeof RAISED_HAND_REJECTED
    payload: AxiosError
}

export interface LowerHandFulfilled {
    type: typeof LOWER_HAND_FULFILLED
    payload: string
}

export interface LowerHandRejected {
    type: typeof LOWER_HAND_REJECTED
    payload: AxiosError
}

export interface AdminLoweredHandPending {
    type: typeof ADMIN_LOWER_HAND_PENDING
    payload: string
}

export interface AdminLoweredHandFulfilled {
    type: typeof ADMIN_LOWER_HAND_FULFILLED
    payload: string
}

export interface AdminLoweredHandRejected {
    type: typeof ADMIN_LOWER_HAND_REJECTED
    payload: AxiosError
}

export interface SpotlightUserRejected {
    type: typeof SPOTLIGHT_USER_REJECTED
    payload: AxiosError
}

export interface DevicePermission {
    type: typeof DEVICE_PERMISSION
    payload: EventObjectCameraError
}

export interface UpdateEventStatusPending {
    type: typeof UPDATE_EVENT_STATUS_PENDING
}

export interface UpdateEventStatusRejected {
    type: typeof UPDATE_EVENT_STATUS_REJECTED
    payload: AxiosError
}

export interface UpdateEventStatusFulfilled {
    type: typeof UPDATE_EVENT_STATUS_FULFILLED
}

export interface CreateBreakoutRoomPending {
    type: typeof CREATE_BREAKOUT_ROOM_PENDING
}

export interface CreateBreakoutRoomRejected {
    type: typeof CREATE_BREAKOUT_ROOM_REJECTED
    payload: AxiosError
}

export interface CreateBreakoutRoomFulfilled {
    type: typeof CREATE_BREAKOUT_ROOM_FULFILLED
    payload: IBreakoutRoom
}

export interface UpdateBreakoutRoomRejected {
    type: typeof UPDATE_BREAKOUT_ROOM_REJECTED
    payload: AxiosError
}

export interface UpdateBreakoutRoomFulfilled {
    type: typeof UPDATE_BREAKOUT_ROOM_FULFILLED
    payload: IBreakoutRoom
}

export interface DeleteBreakoutRoomPending {
    type: typeof DELETE_BREAKOUT_ROOM_PENDING
}

export interface DeleteBreakoutRoomRejected {
    type: typeof DELETE_BREAKOUT_ROOM_REJECTED
    payload: AxiosError
}

export interface DeleteBreakoutRoomFulfilled {
    type: typeof DELETE_BREAKOUT_ROOM_FULFILLED
}

export interface LoweredHand {
    type: typeof LOWERED_HAND
    payload: string
}

export interface HandRaised {
    type: typeof HAND_RAISED
    payload: {
        user_id: string,
        name: string
    }
}

export interface UserSpotlighted {
    type: typeof USER_SPOTLIGHTED
    payload: {
        user_id: string
        spotlighted: boolean
    }
}

export interface BreakoutRoomUpdate {
    type: typeof BREAKOUT_ROOM_UPDATE
    payload?: string
}

export interface EventStatusUpdated {
    type: typeof EVENT_UPDATED
    payload: EventStatus
}

export interface NewUserAdded {
    type: typeof NEW_USER_ADDED
    payload: Participant
}

export type DispatchFetchMeetingRoom = FetchMeetingRoomRejected | FetchMeetingRoomFulfilled
export type IMeetingActions = FetchMeetingRoomRejected | FetchMeetingRoomFulfilled | JoinedMeeting | ParticipantUpdate | ParticipantLeft | ParticipantJoined | ScreenSharing | SpeakerChanged | DevicePermission | RaisedHandFulfilled | LowerHandFulfilled | HandRaised | LoweredHand | UserSpotlighted | UpdateEventStatusPending | UpdateEventStatusRejected | UpdateEventStatusFulfilled | CreateBreakoutRoomPending | CreateBreakoutRoomRejected | CreateBreakoutRoomFulfilled | UpdateBreakoutRoomFulfilled | DeleteBreakoutRoomPending | DeleteBreakoutRoomRejected | DeleteBreakoutRoomFulfilled | BreakoutRoomUpdate | EventStatusUpdated | NewUserAdded