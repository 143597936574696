import { FC } from 'react';
import styles from './styles.module.css';
import { Link } from "react-router-dom";
import { domain } from '../../../../../config';
import defaultCoverImage from '../../../../../images/default-coverpage.jpg';
import ImageWithFallback from '../../../../image-with-fallback/ImageWithFallback';

const ConnectionCard: FC = ({ cover_image, title, org_id, event_id, short_description }: any) => {
    return (
        <div className={styles.previous_connection_card}>
            {/* <img className={styles.image} alt={title} aria-label={title} src={`${domain}/${cover_image}`} /> */}
            <ImageWithFallback fallback={defaultCoverImage} src={`${domain}/${cover_image}`} title={title} className={styles.image} ></ImageWithFallback>
            <div className={styles.card_info}>
                <p className={styles.title}>{title}</p>
                <p className={styles.short_description}>{short_description}</p>
                <Link className={styles.learn_more_button} to={`${org_id}/${event_id}`}>Learn more</Link>
            </div>
        </div>
    )
}

export default ConnectionCard;