import { FC, memo, useEffect } from 'react';
import styles from './styles.module.css';
import Participant from '../participant';
import Icon from '../../../../../icon';
import room from '../../../../../../utility/room';
import useParticipantsSelector from '../../../../../../hooks/use-participants-selector';

interface Props {
    total: number
    isScreenSharing?: boolean
}

const GalleryView: FC<Props> = ({ total, isScreenSharing }) => {
    const { setPageLength, ...state } = useParticipantsSelector();

    useEffect(() => setPageLength((total > 3) ? 3 : 5), [total, setPageLength])

    useEffect(() => {
        state.participants.forEach((userId) => {
            room.subscribeVideo(userId, { video: true })
        })
        return () => {
            state.participants.forEach((userId) => {
                room.unsubscribeVideo(userId, { video: false })
            })
        }
    }, [state.participants])

    return (
        <div className={`${styles.gallery_view} ${styles[isScreenSharing ? 'screen_sharing' : `gallery_view_${total}`]}`}>
            {console.log("GalleryView")}
            {(state.total > state.pageLength) &&
                <button className={`${styles.scroll} ${state.pageLength === 3 ? styles.up : styles.left}`} onClick={state.scrollLeft} disabled={state.page === 0}>
                    <Icon className={styles.icon} icon="arrow" />
                </button>}
            {state.participants.map((userId) => <Participant key={userId} userId={userId} />)}
            {(state.total > state.pageLength) &&
                <button className={`${styles.scroll} ${state.pageLength === 3 ? styles.down : styles.right}`} onClick={state.scrollRight} disabled={(total - (state.page + state.pageLength) === 0)}>
                    <Icon className={styles.icon} icon="arrow" />
                </button>
            }
        </div >
    )
}

export default memo(GalleryView);