import { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';

const useValidation = () => {
    const history = useHistory();
    const [errors, setErrors] = useState<any>({});
    const [values, setValues] = useState({ email: "" });
    const [isSubmitting, setSubmitting] = useState(false);

    const handleChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
        setValues((prevState) => ({ ...prevState, [name]: value }));
        if (errors[name]) {
            setErrors((prevState: any) => {
                delete prevState[name];
                return prevState;
            });
        }
    }

    const validate = () => {
        const errors = {} as any
        if (!values.email) errors.email = "Email is required."
        return errors;
    }

    const handleSubmit = () => {
        setErrors({});
        setSubmitting(true);
        const errors = validate();
        if (Object.values(errors).length) {
            setErrors(errors);
            setSubmitting(false);
        } else {
            Auth.forgotPassword(values.email).then((data) => {
                console.log(data);
                history.push({ search: `type=reset` });
            }).catch((err: any) => {
                console.log(JSON.stringify(err))
                setSubmitting(false);
                setErrors({ message: err.message || "Sorry an Error Occurred. Please Try Again." });
            });
        }
    }

    return [values, isSubmitting, errors, handleChange, handleSubmit]
}

export default useValidation;