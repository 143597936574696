import { FC, memo, DragEvent } from "react";
import styles from './styles.module.css';
import { domain } from '../../../../../../../../config';
import Icon from '../../../../../../../icon';

export interface IBreakoutRoomUser {
    type: string
    user_id: string
    profile: string
    last_name: string
    first_name: string
}


interface Props extends React.HTMLAttributes<HTMLDivElement>, IBreakoutRoomUser {
    indx: number
    handleRemove?: (userId: string, from: number) => void
}

const Participant: FC<Props> = ({ indx, user_id, first_name, last_name, profile, handleRemove }) => {

    const handleDragStart = (e: DragEvent<HTMLDivElement>) => {
        e.dataTransfer.setData('text/plain', JSON.stringify({ user_id, indx }));
        e.dataTransfer.dropEffect = "move";
    }

    return (
        <div className={styles.participant} draggable onDragStart={handleDragStart}>
            <img className={styles.profile_image} alt={`${first_name} ${last_name}`} src={`${domain}/${profile}`} />
            <p className={styles.name}>{first_name} {last_name?.charAt(0)}.</p>
            {handleRemove && <button className={styles.remove_button} onClick={() => handleRemove(user_id, indx)}>
                <Icon className={styles.icon} icon="close" />
            </button>}
        </div>
    )
}

export default memo(Participant);