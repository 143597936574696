import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CREATE_ERROR } from '../redux/reducers/error-reducer/types';

type Permission = PermissionStatus["state"]
type VideoTrack = [MediaStreamTrack | undefined, () => void]

const useLocalVideoTrack = (state: Permission, selected?: string): VideoTrack => {
    const dispatch = useDispatch();
    const [track, setTrack] = useState<MediaStreamTrack>();
    const [isMuted, setMute] = useState<boolean>(Boolean(localStorage.getItem("video-muted")));

    useEffect(() => {
        if (!isMuted && state !== "denied") {
            const constrains = { width: 1920, height: 1080, frameRate: 30, deviceId: {} }
            if (selected) constrains.deviceId = { exact: selected }
            navigator.mediaDevices.getUserMedia({ video: constrains }).then((mediaStream) => {
                const track = mediaStream.getTracks()[0]
                if (state === "granted") {
                    setTrack((prevTrack) => {
                        if (prevTrack) prevTrack.stop();
                        return track;
                    });
                } else track.stop();
            }).catch((error) => {
                console.error("useLocalVideoTrack | createTrack", error);
                dispatch({ type: CREATE_ERROR, payload: `Camera ${error.message}` });
                setMute(() => {
                    localStorage.setItem("video-muted", String(true));
                    return true;
                });
            });
            return () => {
                setTrack((prevTrack) => {
                    if (prevTrack) prevTrack.stop();
                    return undefined;
                });
            }
        }
    }, [isMuted, dispatch, state, selected]);

    const handleTrack = () => {
        setMute((isMuted) => {
            if (isMuted) localStorage.removeItem("video-muted");
            else localStorage.setItem("video-muted", String(true));
            return !isMuted;
        });
    }

    return [track, handleTrack]
}

export default useLocalVideoTrack;