import { FC } from 'react';
import styles from './styles.module.css';
import Icon from '../../icon';
import { animated } from 'react-spring';

interface Props extends React.HTMLProps<HTMLDivElement> {
    style: any
    error: string
    remove: () => void
}

const Notification: FC<Props> = ({ style, error, remove }) => {
    return (
        <animated.div style={style} className={styles.notification} onClick={remove}>
            <Icon className={styles.icon} icon="error" />
            <div className={styles.message}>{error}</div>
        </animated.div>
    )
};

export default Notification;