import { FC, useMemo, useEffect, useState, Fragment } from 'react';
import styles from './styles.module.css';
import moment from 'moment';
import 'moment-duration-format';

interface Props extends React.HTMLProps<HTMLDivElement> {
    date: number
}

const Timer: FC<Props> = ({ className, date }) => {
    const eventDate = useMemo(() => moment(date), [date]);
    const [time, setTime] = useState([0, 0, 0, 0]);

    useEffect(() => {
        const stopInterval = setInterval(() => {
            const difference = eventDate.diff(moment(), 'seconds')
            const timeLeft = moment.duration(difference, 'seconds');
            if (timeLeft.asSeconds() <= 0) {
                clearInterval(stopInterval)
            } else {
                /* @ts-ignore */
                setTime(timeLeft.format("dd:hh:mm:ss", { trim: false }).split(':'))
            }
        }, 1000)
        return () => clearInterval(stopInterval);
    }, [eventDate])

    if (time[2] !== 0 && time[3] !== 0) {
        return (
            <Fragment>
                <p className={styles.sub_title}>Live event starts in:</p>
                <div className={`${styles.timer} ${className}`}>
                    {(time[0] > 0) && <div className={styles.count}>
                        <div className={styles.number}>{time[0]}</div>
                        <div className={styles.unit}>{time[0] > 1 ? 'Days' : 'Day'}</div>
                    </div>}
                    <div className={styles.count}>
                        <div className={styles.number}>{time[1]}</div>
                        <div className={styles.unit}>{time[1] > 1 ? 'Hours' : 'Hour'}</div>
                    </div>
                    <div className={styles.count}>
                        <div className={styles.number}>{time[2]}</div>
                        <div className={styles.unit}>{time[2] > 1 ? 'Minutes' : 'Minute'}</div>
                    </div>
                    <div className={styles.count}>
                        <div className={styles.number}>{time[3]}</div>
                        <div className={styles.unit}>{time[3] > 1 ? 'Seconds' : 'Second'}</div>
                    </div>
                </div>
            </Fragment>
        )
    } else {
        return null
    }
}

export default Timer;