import { useCallback, useState } from 'react';
import { Auth } from 'aws-amplify';

const useValidation = (onClose: () => void) => {
    const [errors, setErrors] = useState<any>({});
    const [isSubmitting, setSubmitting] = useState(false);
    const [values, setValues] = useState({ current_password: "", new_password: "", confirm_password: "" });


    const handleChange = useCallback(({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
        setValues((prevState) => ({ ...prevState, [name]: value }));
        if (errors[name]) {
            setErrors((prevState: any) => {
                delete prevState[name];
                return prevState;
            });
        }
    }, [errors])

    const validate = () => {
        const errors = {} as any
        if (!values.current_password) errors.current_password = "Current password is required."
        if (!values.new_password) errors.new_password = "New password is required."
        if (!values.confirm_password) errors.confirm_password = "Confirm New password is required."
        if (values.new_password !== values.confirm_password) errors.confirm_password = "Must match New Password."
        return errors;
    }

    const handleSubmit = () => {
        setErrors({});
        setSubmitting(true);
        const errors = validate();
        if (Object.values(errors).length) {
            setErrors(errors);
            setSubmitting(false);
        } else {
            Auth.currentAuthenticatedUser().then(user => {
                return Auth.changePassword(user, values.current_password, values.new_password);
            }).then((res) => {
                onClose();
                console.log(res);
            }).catch((err: any) => {
                setSubmitting(false);
                console.log({ ...err, message: err.message });
                setErrors({ message: err.message || "Sorry an Error Occurred. Please Try Again." });
            });
        }
    }

    return [values, isSubmitting, errors, handleChange, handleSubmit]
}

export default useValidation;