import { FC, useState, useEffect } from 'react';
import room from '../../../../../utility/room';
import UserInteractionRequired from './user-interaction-required-modal';

const cantPlayAudio = new Map();

const AudioPlay: FC = () => {
    const [needUserInteraction, toggle] = useState(false);

    // create a Audio player components
    useEffect(() => {
        const elements = new Map();
        const createElement = (track: MediaStreamTrack) => {
            const audio = document.createElement("audio");
            audio.volume = 0;
            audio.muted = true;
            elements.set(track, audio);
            audio.srcObject = new MediaStream([track]);
            audio.play().then(() => {
                audio.volume = 1;
                audio.muted = false;
            }).catch(() => {
                toggle(true);
                cantPlayAudio.set(audio, audio);
            });
        }
        const removeElement = (track: MediaStreamTrack) => {
            const audio = elements.get(track);
            audio.pause();
            audio.srcObject = null;
            audio.load();
            audio.remove();
            elements.delete(track);
        }
        room.audio_tracks.forEach(createElement);
        room.on("audio-track-added", createElement);
        room.on("audio-track-removed", removeElement);
        console.log("PlayAudioTracks mounted");
        return () => {
            room.audio_tracks.forEach(removeElement);
            room.off("audio-track-added", createElement);
            room.off("audio-track-removed", removeElement);
            console.log("PlayAudioTracks unmounted");
        }
    }, []);

    const handlePlay = () => {
        cantPlayAudio.forEach((audio) => {
            audio.play().then(() => {
                audio.volume = 1;
                audio.muted = false;
                cantPlayAudio.delete(audio);
            }).catch(() => toggle(true));
        })
        toggle(false)
    }

    if (!needUserInteraction) return null;
    else return <UserInteractionRequired close={handlePlay} />
}

export default AudioPlay;