import { FC, Fragment, useState } from 'react';
import ControlButton from '../control-button';
import SpotlightSidebar from './spotlight-sidebar';

const SpotlightControl: FC = () => {
    const [show, toggle] = useState(false);
    return (
        <Fragment>
            <ControlButton active={show} onClick={() => toggle(!show)} icon="participants" />
            {show && <SpotlightSidebar close={() => toggle(false)} />}
        </Fragment>
    )
}

export default SpotlightControl;