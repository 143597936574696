import { FC, useEffect, useState } from 'react';
import styles from './styles.module.css';
import Icon from '../../../../../icon';
import Participant from '../participant';
import useParticipantsSelector from '../../../../../../hooks/use-participants-selector';
import room from '../../../../../../utility/room';

interface ISize {
    width: string
    height: string
}

const GridView: FC = () => {
    const state = useParticipantsSelector();
    const [size, setState] = useState<ISize>({ width: '0%', height: '0%' });

    useEffect(() => {
        let row = 1, column = 1;
        while (state.participants.length > (column * row)) {
            if (column === row) column++
            else row++
        }
        setState({ width: `${95 / column}%`, height: `${93.5 / row}%` })
    }, [state.participants.length])

    useEffect(() => {
        state.participants.forEach((userId) => {
            room.subscribeVideo(userId, { video: true })
        })
        return () => {
            state.participants.forEach((userId) => {
                room.unsubscribeVideo(userId, { video: false })
            })
        }
    }, [state.participants])

    return (
        <div className={styles.grid_view}>
            {(state.total > state.pageLength) &&
                <button onClick={state.scrollLeft} disabled={state.page === 0} className={`${styles.scroll} ${styles.left}`}>
                    <Icon className={styles.icon} icon="arrow" />
                </button>}
            {state.participants.map((userId) => <Participant key={userId} userId={userId} style={size} />)}
            {(state.total > state.pageLength) &&
                <button onClick={state.scrollRight} className={`${styles.scroll} ${styles.right}`} disabled={(state.total - (state.page + state.pageLength) === 0)}>
                    <Icon className={styles.icon} icon="arrow" />
                </button>}
        </div>
    )
}

export default GridView;