import { FC, memo } from 'react';
import styles from './styles.module.css'

type Props = JSX.IntrinsicElements["textarea"] & {
    label?: string
    subLabel?: string
    value: string
    error?: string
}

const TextAreaInput: FC<Props> = ({ className, error, label, subLabel, ...rest }) => {
    const { value, maxLength } = rest;
    return (
        <div className={`${className} ${styles.input}`}>
            <p className={styles.label}>{label}</p>
            <p className={styles.counter}>{value?.length || 0}/{maxLength}</p>
            <textarea className={`${styles.input_field} ${error && styles.error_input}`} {...rest} />
            {subLabel && <p className={styles.sub_label}>{subLabel}</p>}
            {error && <p className={styles.error}>{error}</p>}
        </div>
    );
}

export default memo(TextAreaInput);