import { FC } from 'react';
import styles from './styles.module.css';
import TextInput from '../../../text-input';
import Button from '../../../button';
import Modal from '../../../modal';
import Icon from '../../../icon';
import useValidation from './useValidation';

interface Props {
    onClose: (form: any) => void
}

const ChangePasswordFrom: FC<Props> = ({ onClose }) => {
    const [values, isSubmitting, errors, handleChange, handleSubmit] = useValidation(onClose);

    return (
        <Modal className={styles.modal}>
            <div className={styles.form}>
                <button className={styles.close_button} onClick={onClose}>
                    <Icon className={styles.close_icon} icon="close" />
                </button>
                <p className={styles.title}>Set a Password</p>
                {errors.message && <div className={styles.error_message}>{errors.message}</div>}
                <TextInput
                    label="New Password"
                    type="password"
                    name="password"
                    value={values.password}
                    error={errors.password}
                    onChange={handleChange}
                />
                <TextInput
                    label="Confirm New Password"
                    type="password"
                    name="confirm_password"
                    value={values.confirm_password}
                    error={errors.confirm_password}
                    onChange={handleChange}
                />
                <Button onClick={handleSubmit} loading={isSubmitting} disabled={errors.password || errors.confirm_password}>
                    Set
                </Button>
            </div>
        </Modal>
    )
}

export default ChangePasswordFrom;