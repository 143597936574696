import { FC, Fragment, useState } from 'react';
import styles from './styles.module.css';
import MenuButton from '../menu-button';
import Button from '../../../../../button';
import EndCallModal from './end-call-modal';
import ControlButton from '../control-button';
import EndEventModal from './end-event-modal';
import { RootState } from '../../../../../../..';
import { useDispatch, useSelector } from 'react-redux';
import { startMeeting } from '../../../../../../redux/action/meeting';
import _ from "lodash";

interface Props {
    type: string | undefined;
    isStarted: boolean | undefined;
}

const EndCall: FC<Props> = ({ type, isStarted }) => {
    const dispatch = useDispatch();
    const [modal, toggleModal] = useState(0);
    const loading = useSelector(({ meeting }: RootState) => meeting.updating_event, _.isEqual);

    if (type === "admin") {
        return (
            <Fragment>
                <MenuButton className={styles.end_call} icon="end-call">
                    {!isStarted && <Button className={`${styles.button} ${styles.green_button}`} onClick={() => dispatch(startMeeting())} loading={loading}>Start the Event</Button>}
                    {isStarted && <Button className={styles.button} onClick={() => toggleModal(1)}>End the Event</Button>}
                    <Button className={styles.button} onClick={() => toggleModal(2)}>Leave the Event</Button>
                </MenuButton>
                {(modal === 1) && <EndEventModal close={() => toggleModal(0)} />}
                {(modal === 2) && <EndCallModal close={() => toggleModal(0)} />}
            </Fragment>
        )
    } else {
        return (
            <Fragment>
                <ControlButton className={styles.end_button} onClick={() => toggleModal(2)} icon="end-call" />
                {(modal === 2) && <EndCallModal close={() => toggleModal(0)} />}
            </Fragment>
        )
    }
}

export default EndCall;