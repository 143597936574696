import { FC } from "react";
import { useHistory } from "react-router-dom";
import styles from './styles.module.css';

interface Props {
    org_id: string
    org_name: string
}

const OrgButton: FC<Props> = ({ org_id, org_name }) => {
    const history = useHistory();

    return (
        <div className={styles.org_button} onClick={() => history.push(`/${org_id}`)}>
            <p className={styles.org_name}>{org_name}</p>
        </div>
    )
}

export default OrgButton;