import { FC, useCallback } from 'react';
import Participant from '../participant';
import Sidebar from '../../sidebar-layout';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../../../index';
import { adminLowerUserHand, spotlightUser } from '../../../../../../../redux/action/meeting';
import _ from "lodash";

interface Props {
    close: () => void
}

const SpotlightSidebar: FC<Props> = ({ close }) => {
    const dispatch = useDispatch();
    const { participants, total } = useSelector(({ meeting }: RootState) => {
        const participants = Object.values(meeting.participants)
        return {
            total: participants.length,
            participants: participants.sort((a, b) => {
                if (a.first_name < b.first_name) return -1
                return 0
            }).sort((a) => {
                if (a.type === "guest") return -1
                return 0
            }).sort((a) => {
                if (a.raised_hand) return -1
                return 0
            }).sort((a) => {
                if (a.type === "moderator") return -1
                return 0
            }),
        }
    }, _.isEqual);

    const handleSpotlight = useCallback((userId: string): void => {
        dispatch(spotlightUser(userId))
    }, [dispatch])

    const handleLowerHand = useCallback((userId: string): void => {
        dispatch(adminLowerUserHand(userId))
    }, [dispatch])

    return (
        <Sidebar onClose={close} title={`Meet participants ${total}`}>
            {participants.map((p) => <Participant key={p.user_id} {...p} spotlight={handleSpotlight} lowerHand={handleLowerHand} />)}
        </Sidebar>
    )
}

export default SpotlightSidebar;