import { FC } from 'react';
import styles from './styles.module.css';
import TextInput from '../../../text-input';
import Button from '../../../button';
import Modal from '../../../modal';
import Icon from '../../../icon';
import useValidation from './useValidation';

interface Props {
    onClose: () => void
}

const ChangePasswordFrom: FC<Props> = ({ onClose }) => {
    const [values, isSubmitting, errors, handleChange, handleSubmit, resendCode] = useValidation(onClose);

    return (
        <Modal className={styles.modal}>
            <div className={styles.form}>
                <button className={styles.close_button} onClick={onClose}>
                    <Icon className={styles.close_icon} icon="close" />
                </button>
                <p className={styles.title}>Verify Account</p>
                {errors.message && <div className={styles.error_message}>{errors.message}</div>}
                <TextInput
                    name="code"
                    type="number"
                    label="Enter verification code"
                    value={values.code}
                    error={errors.code}
                    onChange={handleChange}
                />
                <button className={styles.forgot_password_button} onClick={resendCode}>Resend Code</button>
                <p>We have sent a verification link to your email.</p>
                <p>If you have not received the verification email, please check your “Spam” or “Bulk Email” folder. You can click the resend button below to have another email sent.</p>
                <Button onClick={handleSubmit} loading={isSubmitting} disabled={errors.code}>
                    Verify
                </Button>
            </div>
        </Modal>
    )
}

export default ChangePasswordFrom;